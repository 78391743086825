import React, { useEffect, useContext } from "react";
import {
  LiveRoom,
  TimeContext,
  useTimeRemaining,
  usePhotoRequest,
  firebase,
} from "components";
import { GridColumn, GridRow, ButtonPrimary, Icon, Label, P } from "notes";
import styled from "styled-components";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const InCallMobile = ({
  handleNext,
  handleEnd,
  handleUnmute,
  liveRoom,
  live,
  room,
}) => {
  const {
    handleRequest,
    pendingPhotoRequests,
    pendingCapture,
  } = usePhotoRequest({ roomId: liveRoom });
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${liveRoom}`)
  );
  const { time } = useContext(TimeContext);

  const { timeRemaining, endTime, sessionDuration } = useTimeRemaining({
    room,
  });

  useEffect(() => {
    if (endTime && endTime < time) {
      handleNext();
    }
  }, [endTime < time]);

  const addTime = () => {
    room.ref.update({ sessionDuration: sessionDuration + 15 });
  };

  const location = room?.data()?.userLocation;
  const bio = room?.data()?.userBio;

  return (
    <Container>
      <StyledLiveRoom live={live} roomId={liveRoom} artist />
      <Float>
        <TopTile>
          {!!timeRemaining && !timeRemaining.includes("undefined") && (
            <TimeContainer>{timeRemaining}</TimeContainer>
          )}
          <Details>
            <Label>{displayname?.value}</Label>
            <Divider />
            <P>
              {location}
              {!!location && !!bio && " • "}
              {bio}
            </P>
          </Details>
        </TopTile>
        <BottomTile>
          <RequestButton
            onClick={handleRequest}
            disabled={!live || pendingCapture || !pendingPhotoRequests?.empty}
          >
            <Icon tertiary name="Camera" />
          </RequestButton>
          <Actions>
            <WhiteButton onClick={addTime}>+15sec</WhiteButton>
            <NextCall onClick={live ? handleNext : handleUnmute} live={live}>
              <Icon form name="NextCall" />
            </NextCall>
            <EndCall onClick={handleEnd}>
              <Icon form name="EndCall" />
            </EndCall>
          </Actions>
        </BottomTile>
      </Float>
    </Container>
  );
};

const Details = styled(GridColumn)`
  background-color: rgba(0, 0, 0, 0.72);
  height: 90px;
  padding: 8px 24px;
  ${Label}, ${P} {
    color: #ffffff;
  }
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.primary};
  width: 100%;
  height: 2px;
  margin: 8px 0;
`;

const Float = styled(GridColumn)`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

const Container = styled(GridColumn)`
  background: #000000;
  height: ${(props) => props.height}px;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  position: relative;
`;

const TopTile = styled(GridColumn)`
  height: 50%;
  justify-content: flex-end;
  position: relative;
`;

const BottomTile = styled(TopTile)``;

const StyledLiveRoom = styled(LiveRoom)`
  z-index: 1;
`;

const TimeContainer = styled(GridRow)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 20px;
  color: #ffffff;
  height: 40px;
  width: 70px;
  position: absolute;
  top: 24px;
  left: 24px;
`;

const Actions = styled(GridRow)`
  width: 100%;
  margin-bottom: 40px;
  justify-content: center;
`;

const NextCall = styled(ButtonPrimary)`
  background-color: ${(props) =>
    props.live ? "#ffffff" : props.theme.palette.green.primary};
  color: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
  margin-left: 24px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  svg {
    path {
      fill: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => (props.live ? "#ffffff" : "#48a545")};
    color: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
    svg {
      path {
        fill: ${(props) =>
          props.live ? props.theme.palette.black : "#ffffff"};
      }
    }
  }
`;

const EndCall = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.red.primary};
  color: #ffffff;
  margin-left: 24px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  svg {
    path {
      fill: #ffffff;
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.red.darker};
    color: #ffffff;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

const RequestButton = styled(ButtonPrimary)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  position: absolute;
  top: 24px;
  left: 24px;
  svg {
    path {
      fill: #ffffff;
    }
  }
  &:disabled {
    svg {
      path {
        fill: ${(props) => props.theme.palette.gray.lighter};
      }
    }
  }
`;

const WhiteButton = styled(ButtonPrimary)`
  background-color: #ffffff;
  color: ${(props) => props.theme.palette.black};
  min-width: 0;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  svg {
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
  &:hover,
  &:focus {
    background-color: #ffffff;
    color: ${(props) => props.theme.palette.black};
    svg {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
`;
