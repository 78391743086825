import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GridRow, H4, Icon } from "notes";
import {
  ConnectionIndicator,
  firebase,
  ManagerModalContext,
  EventContext,
  ChatContext,
} from "components";
import { MoreDropdown } from "./MoreDropdown";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { sortableHandle, SortableElement } from "react-sortable-hoc";
import { ReactComponent as MicrophoneMutedOrig } from "Images/MicrophoneMuted.svg";
import { ReactComponent as MicrophoneOrig } from "Images/MicrophoneTertiary.svg";
import activeShadow from "Images/UserActionShadow.png";

const MicrophoneMuted = styled(MicrophoneMutedOrig)`
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;

const Microphone = styled(MicrophoneOrig)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const DragHandle = sortableHandle(() => <DragAction indicator name="Drag" />);

export const UserActions = ({
  isNext,
  checkedIn,
  name = "",
  video,
  audio,
  duration,
  connectionQuality,
  hasBio,
  id,
  handleSort,
  phone,
  noText,
  status,
  idx,
  isChat,
  online,
  ...props
}) => {
  const { event } = useContext(EventContext);
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${id}`)
  );
  const [privateData] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}/rooms/${id}/private/data`)
  );
  const { setUser, user } = useContext(ChatContext);
  const { setManagerModal } = useContext(ManagerModalContext);
  const theme = useContext(ThemeContext);
  const [unreads] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/${id}/chats`)
      .where("read", "==", false)
  );
  const handleSMS = () => {
    setManagerModal({
      sms: {
        phone: privateData?.phone,
        id,
        name: displayname?.value,
      },
    });
  };

  const onSelect = (action) => {
    switch (action) {
      case "queue":
        handleSort({ oldIndex: idx, newIndex: 0 });
        break;
      case "duration":
        setManagerModal({
          callDuration: id,
        });
        break;
      case "bio":
        setManagerModal({
          userBio: { id: id, name: displayname?.value },
        });
        break;
      case "remove":
        setManagerModal({
          confirmRemove: { id: id, name: displayname?.value },
        });
        break;
      case "requeue":
        firebase.firestore().doc(`meet_greet/${event.id}/rooms/${id}`).update({
          status: "pending",
        });
        break;
      default:
        break;
    }
  };
  const handleChat = () => {
    setUser(id);
  };
  return (
    <Container
      active={!isChat && user === id}
      isChat={isChat}
      isNext={isNext}
      {...props}
    >
      {isChat && (
        <BlackIcon
          style={{ cursor: "pointer", alignSelf: "center" }}
          onClick={() => setUser(null)}
          name="Left"
        />
      )}
      <ClickArea
        yCenter
        onClick={!!theme.isArtist ? undefined : handleChat}
        isChat={isChat || !!theme.isArtist}
        isNext={isNext}
      >
        {checkedIn && !isChat && <DragHandle />}
        {checkedIn && !isChat ? (
          online ? (
            <ConnectionIndicator strength={connectionQuality || 0} />
          ) : (
            <DisabledIcon indicator name="CloudFailure" />
          )
        ) : null}
        <Name isNext={isNext} checkedIn={checkedIn} isChat={isChat}>
          {displayname?.value}
        </Name>
        {!isNext &&
          checkedIn &&
          !isChat &&
          (video ? (
            <BlackIcon form name="Video" />
          ) : (
            <DisabledIcon form name="VideoOff" />
          ))}
        {!isNext &&
          checkedIn &&
          !isChat &&
          (audio ? (
            <Microphone style={{ paddingLeft: "8px" }} />
          ) : (
            <MicrophoneMuted style={{ paddingLeft: "8px" }} />
          ))}
      </ClickArea>
      <Actions yCenter xEnd stretch={isChat} isNext={isNext}>
        {isNext && (
          <NextInfo stretch yCenter>
            {video ? (
              <>
                <BlackIcon form name="Video" />
                <H4>ON</H4>
              </>
            ) : (
              <>
                <DisabledIcon form name="VideoOff" />
                <H4>OFF</H4>
              </>
            )}
            {audio ? (
              <>
                <Microphone style={{ paddingLeft: "8px" }} />
                <H4>ON</H4>
              </>
            ) : (
              <>
                <MicrophoneMuted style={{ paddingLeft: "8px" }} />
                <H4>OFF</H4>
              </>
            )}
            <ClockIcon form name="Clock" />
            <H4>{duration}</H4>
          </NextInfo>
        )}
        {!theme.isArtist && (
          <>
            {!isChat &&
              ["pending", "ready", "complete", "canceled"].includes(status) && (
                <MessageIndicator
                  active={unreads?.docs.length > 0}
                  yCenter
                  onClick={handleChat}
                >
                  <ChatIcon form name="InstantMessage" />
                  <H4>{unreads?.docs.length}</H4>
                </MessageIndicator>
              )}
            {isChat && (
              <>
                {checkedIn && (
                  <ConnectionIndicator
                    style={{ marginRight: "16px" }}
                    strength={connectionQuality || 0}
                  />
                )}
                {video ? (
                  <BlackIcon form name="Video" />
                ) : (
                  <DisabledIcon form name="VideoOff" />
                )}
              </>
            )}
            <Placeholder>
              {privateData?.phone && !noText && (
                <PhoneIcon form name="Phone" onClick={handleSMS} />
              )}
            </Placeholder>
            <MoreDropdown
              isNext={isNext}
              onSelect={onSelect}
              options={menuOptions(isNext, duration, hasBio, checkedIn, status)}
            />
          </>
        )}
      </Actions>

      {!isChat && user === id && <ActiveShadowImage src={activeShadow} />}
    </Container>
  );
};

export const SortableUserActions = SortableElement(UserActions);

const Container = styled(GridRow)`
  background-color: ${(props) =>
    props.active ? props.theme.palette.gray.lightest : "#ffffff"};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: ${(props) =>
    props.isNext ? "4px 20px 16px 12px" : "0 20px 0 12px"};
  position: relative;
  flex-direction: ${(props) => (props.isNext ? "column" : "row")};
  ${(props) => props.isChat && "height: 57px;"};
  &:hover {
    background-color: ${(props) =>
      !props.isChat
        ? props.active
          ? props.theme.palette.gray.lightest
          : "#f5f5f5"
        : "#ffffff"};
  }
`;

const ClickArea = styled(GridRow)`
  cursor: ${(props) => (props.isChat ? "default" : "pointer")};
  ${(props) => !props.isChat && "flex-grow: 1;"};
  ${(props) => !props.isNext && "padding: 16px 0;"};
`;

const ActiveShadowImage = styled.img`
  width: 24px;
  height: 87px;
  align-self: center;
  position: absolute;
  right: -24px;
  z-index: 2;
`;

const ClockIcon = styled(Icon)`
  margin-left: 24px;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const Actions = styled(GridRow)`
  flex-grow: 1;
  margin-top: ${(props) => (props.isNext ? "20px" : 0)};
`;

const NextInfo = styled(GridRow)`
  margin-left: 28px;
  ${H4} {
    margin-left: 8px;
  }
`;

export const menuOptions = (
  isNext,
  duration,
  bio,
  checkedIn,
  status,
  phone
) => {
  const menuOptions = [
    {
      key: "duration",
      icon: <MenuIcon tertiary name="Clock" />,
      label: `Call Duration (${duration})`,
    },
    {
      key: "bio",
      icon: <MenuIcon tertiary name="ContactCard" />,
      label: bio ? "Edit Fan Bio" : "Add Fan Bio",
    },
  ];

  if (["pending", "ready"].includes(status)) {
    menuOptions.unshift({
      key: "remove",
      icon: <RemoveIcon form name="RemoveCircle" />,
      label: "Remove From Queue",
    });
  }

  if (["canceled", "complete"].includes(status)) {
    menuOptions.unshift({
      key: "requeue",
      icon: <MenuIcon tertiary name="MoveUp" />,
      label: "Return to Queue",
    });
  }

  if (!isNext && checkedIn) {
    menuOptions.unshift({
      key: "queue",
      icon: <MenuIcon tertiary name="MoveUp" />,
      label: "Place Fan Next In Queue",
    });
  }
  return menuOptions;
};

const Name = styled(H4)`
  color: ${(props) =>
    props.checkedIn
      ? props.theme.palette.black
      : props.theme.palette.gray.primary};
  font-size: ${(props) => (props.isNext || props.isChat ? "17px" : "15px")};
  line-height: 24px;
  font-weight: ${(props) => (props.checkedIn ? 600 : 400)};
  margin-left: ${(props) => (props.checkedIn ? "12px" : "28px")};
  margin-right: 12px;

  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => (props.isNext ? "25px" : "17px")};
    line-height: ${(props) => (props.isNext ? "32px" : "24px")};
  }
`;

const DragAction = styled(Icon)`
  margin-right: 9px;
  cursor: grab;
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;

const DisabledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;

const BlackIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.black};
  }
`;

const ChatIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MessageIndicator = styled(GridRow)`
  background-color: ${(props) =>
    props.active ? props.theme.palette.purple.primary : "transparent"};
  border-radius: 12px;
  justify-content: space-between;
  height: 24px;
  margin-right: 16px;
  min-width: 46px;
  padding: 0 8px;

  ${(props) =>
    props.active &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${props.theme.palette.purple.darker}
    }
  `}

  ${H4} {
    color: ${(props) =>
      props.active ? "#ffffff" : props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: ${(props) => (props.active ? 600 : 300)};
    margin-left: 4px;
  }

  ${ChatIcon} {
    path {
      fill: ${(props) =>
        props.active ? "#ffffff" : props.theme.palette.gray.primary};
    }
  }
`;

const Placeholder = styled(GridRow)`
  flex: 0 0 16px;
  svg + & {
    margin-left: 16px;
  }
`;

const PhoneIcon = styled(Icon)`
  cursor: pointer;
  flex-shrink: 0;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const MenuIcon = styled(Icon)`
  margin-right: 6px;
`;

const RemoveIcon = styled(MenuIcon)`
  && > path {
    fill: ${(props) => props.theme.palette.red.primary};
  }
`;
