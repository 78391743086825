import React from "react";
import styled from "styled-components";
import { P, Icon, GridRow, Small } from "notes";
import { ProgressRingSmall } from "./ProgressRingSmall";

export const StepBullets = ({ steps, vertical = false, ...props }) => {
  return (
    <Container vertical={vertical} {...props}>
      {steps.map(({ status, disabled, label, content, progress }, index) => {
        const active = status === "active";
        const complete = status === "complete";
        return (
          <>
            <Step
              key={index}
              active={active}
              completed={complete}
              disabled={disabled}
              vertical={vertical}
            >
              <AnimationContainer>
                {complete ? (
                  <Green badge name="FacebookVerified" />
                ) : active ? (
                  !!progress ? (
                    <ProgressRingSmall progress={progress} />
                  ) : (
                    <Green indicator name="ProgressActive" />
                  )
                ) : (
                  <Disabled indicator name="ProgressInactive" />
                )}
                {active && <Pulse />}
              </AnimationContainer>
              <StepLabel>{label}</StepLabel>
              {!complete && active && !!progress && (
                <Percentage>
                  {progress}
                  <Sign>%</Sign>
                </Percentage>
              )}
            </Step>
            <Description
              vertical={vertical}
              active={active}
              disabled={disabled}
            >
              {index !== steps.length - 1 &&
                (vertical ? (
                  <VLine active={complete} />
                ) : (
                  <HLine active={complete} />
                ))}
              {!!content && <Small>{content}</Small>}
            </Description>
          </>
        );
      })}
    </Container>
  );
};

const Percentage = styled(GridRow)`
  color: ${(props) => props.theme.palette.gray.lighter};
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
  font-weight: 600;
`;

const Sign = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: 8px;
  font-weight: 600;
`;

const Description = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "row" : "column")};
  justify-content: flex-start;
  position: relative;
  height: 52px;
  ${Small} {
    color: ${(props) =>
      !props.disabled
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    padding: 5px 0 0 8px;
    position: absolute;
    top: 0;
    left: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  justify-content: center;
`;

const VLine = styled.div`
  background: ${(props) =>
    props.active
      ? props.theme.palette.purple.primary
      : props.theme.palette.gray.lightest};
  display: block;
  margin: 8px 0 8px 7px;
  width: 2px;
  height: 36px;
`;

const HLine = styled(VLine)`
  margin: 7px 8px 0 8px;
  width: 120px;
  height: 2px;
`;

const StepLabel = styled(P)``;

const Step = styled(GridRow)`
  flex-direction: ${(props) => (props.vertical ? "row" : "column")};
  ${(props) => !props.vertical && `width: 16px;`};
  align-items: center;
  ${StepLabel} {
    color: ${(props) =>
      !props.disabled
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin: ${(props) => (!props.vertical ? `8px 0 0 0` : "0 0 0 8px")};
    white-space: nowrap;
  }
`;

const Green = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.purple.primary};
  }
`;

const Disabled = styled(Icon)`
  path {
    fill: ${(props) => props.theme.colors.disabledIcon};
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 24px;
  width: 24px;
  animation: pulse-purple 4s infinite;
  z-index: 1;

  @keyframes pulse-purple {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 1);
    }
    20% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(124, 96, 217, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 0);
    }
  }
`;
