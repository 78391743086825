import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { GridRow, GridColumn, P, Icon, Link, withDefaultMedia } from "notes";
import { TextUpdateForm } from "./Form";
import { useDocument } from "react-firebase-hooks/firestore";
import parsePhoneNumber from "libphonenumber-js";
import {
  EventContext,
  firebase,
  UserContext,
  MessageContext,
} from "components";

export const TextUpdates = withDefaultMedia(
  ({ matches, onTextAlert, onMobileEdit, mobileEdit, onSuccess }) => {
    const {
      user: { uid: id },
    } = useContext(UserContext);
    const { displayMessage } = useContext(MessageContext);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState();
    const { event } = useContext(EventContext);
    const privateRef = firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${id}/private/data`);
    const ref = firebase.firestore().doc(`meet_greet/${event.id}/rooms/${id}`);
    const [room, load] = useDocument(ref);
    const [privateData, loadp] = useDocument(privateRef);
    const [loading, setLoading] = useState(false);

    if (load || loadp) {
      return null;
    }

    const phone = privateData?.data()?.phone ?? "";

    const noText = room?.data()?.noText;
    const setNoText = (noText) => {
      ref.update({ noText });
    };

    const handlePhone = (number) => {
      setLoading(true);
      firebase
        .functions()
        .httpsCallable("sms-verifyNumber")({ phoneNumber: number })
        .then(({ data: { valid, phoneNumber } }) => {
          if (valid) {
            ref.update({ phone: phoneNumber, noText: false });
            setEdit(false);
            !!onSuccess && onSuccess();
            displayMessage({
              color: "green",
              children: <P>Phone number added successfully!</P>,
              timeout: 5000,
            });
          } else {
            setError("Phone number not valid");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (mobileEdit) {
      return (
        <TextUpdateForm
          label="Phone number"
          defaultValue={phone}
          onSubmit={handlePhone}
          error={error}
          style={{ padding: "24px" }}
        />
      );
    }

    const phoneNumber = parsePhoneNumber(phone);

    return (
      <>
        {!!phone && !edit ? (
          <>
            <GridRow yCenter>
              <MessageIcon form name="Message" />
              <Contact>
                {noText ? (
                  <>
                    You will no longer receive queue updates to{" "}
                    <Bold>
                      {phoneNumber.country === "US"
                        ? phoneNumber?.formatNational()
                        : phoneNumber?.formatInternational()}
                    </Bold>
                  </>
                ) : (
                  <>
                    We’ll text updates on your position in the queue to{" "}
                    <Bold>
                      {phoneNumber.country === "US"
                        ? phoneNumber?.formatNational()
                        : phoneNumber?.formatInternational()}
                    </Bold>
                  </>
                )}
              </Contact>
            </GridRow>
            <Actions noText={noText} yCenter>
              {noText ? (
                <SmallLink
                  onClick={() =>
                    matches.large
                      ? setEdit(true)
                      : !!onTextAlert && onTextAlert(true)
                  }
                  style={{ marginLeft: matches.large ? "32px" : 0 }}
                >
                  Get Text Alerts
                </SmallLink>
              ) : (
                <>
                  <SmallLink onClick={() => setNoText(true)}>
                    Don't Text Me
                  </SmallLink>
                  <SmallLink
                    onClick={() =>
                      matches.large
                        ? setEdit(true)
                        : !!onMobileEdit && onMobileEdit(true)
                    }
                  >
                    Edit Phone Number
                  </SmallLink>
                </>
              )}
            </Actions>
          </>
        ) : matches.large ? (
          <TextUpdateForm
            label="Get queue updates by text"
            defaultValue={phone}
            onSubmit={handlePhone}
            error={error}
            loading={loading}
          />
        ) : (
          <GridColumn xStart>
            <P>
              Want us to text you with updates on your position in the queue?
            </P>
            <SmallLink
              onClick={() => !!onTextAlert && onTextAlert()}
              style={{ marginTop: "12px" }}
            >
              Get Text Alerts
            </SmallLink>
          </GridColumn>
        )}
      </>
    );
  }
);

const Actions = styled(GridRow)`
  justify-content: flex-start;
  margin-top: 12px;
  @media only screen and ${(props) => props.theme.media.large} {
    justify-content: ${(props) =>
      props.noText ? "flex-start" : "space-around"};
    margin-top: 20px;
  }
`;

const SmallLink = styled(Link)`
  font-size: 12px;
  line-height: 18px;
  & + & {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    & + & {
      margin-left: 0;
    }
  }
`;

export const MessageIcon = styled(Icon)`
  display: none;
  flex-shrink: 0;
  margin-right: 16px;
  width: 16px;
  height: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    display: flex;
  }
`;

export const Bold = styled.span`
  display: inline;
  font-weight: 600;
`;

export const Contact = styled.div`
  display: block;
  font-size: 15px;
  line-height: 19px;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
    line-height: 22px;
  }
`;
