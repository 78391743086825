import React from "react";
import styled from "styled-components";
import { TertiaryMenu, Icon } from "notes";

export const MoreDropdown = ({ onSelect, options }) => {
  const handleSelect = (key) => {
    onSelect && onSelect(key);
  };

  return (
    <TertiaryMenu
      placement="top-end"
      offset="6,-32"
      trigger={(props) => (
        <Wrapper {...props}>
          <MoreIcon platform name="More" />
        </Wrapper>
      )}
      items={options}
      onSelect={handleSelect}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: 16px;
`;

const MoreIcon = styled(Icon)`
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;
