import React, { useState, useEffect, useContext } from "react";
import {
  useParticipants,
  Spinner,
  EventContext,
  firebase,
  usePhotoRequest,
  PhotoRequestDialogs,
  useSounds,
  useWindowHeight,
} from "components";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import Participant from "components/Participant/Participant";
import styled, { ThemeProvider } from "styled-components";
import { GridColumn, H4, withDefaultMedia, ButtonPrimary, Icon } from "notes";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { theme } from "theme";

export const SmallVideo = styled.div`
  position: relative;
  height: 50%;
  max-width: 50vh;
  min-width: 50vh;
  flex-grow: 1;
  @supports (-webkit-touch-callout: none) {
    max-width: ${(props) => props.height / 2}px;
    min-width: ${(props) => props.height / 2}px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: calc(100vh - 76px);
    min-width: 0;
    width: 50%;
    justify-content: center;
    height: initial;
    ${(props) => props.isFan && `order: 2;`};
    ${(props) =>
      props.manager &&
      `
      max-width: 50%;
      min-width: 50%;
      max-height: 100%;
      width: auto;
      height: 100%;
    `}
  }
  ${(props) =>
    props.manager &&
    `
    max-width: 50%;
    min-width: 50%;
    max-height: 100%;
    width: auto;
    height: 100%;
    & video {
      object-fit: contain !important;
    }
    `}
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.manager ? "row" : "column-reverse")};
  justify-content: flex-start;
  flex-wrap: no-wrap;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;

  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    max-height: calc(100vh - 76px);
    min-height: 0;
    justify-content: center;
    ${(props) => props.manager && `justify-content: center;`};
  }
`;

const LiveRoomInternal = ({
  roomId,
  isFan,
  status,
  matches,
  manager = false,
  artist = false,
  managerRoom,
  ...props
}) => {
  const VideoContext = useVideoContext();
  const localParticipant = VideoContext?.room?.localParticipant;
  const {
    handleRequest,
    pendingCapture,
    pendingPhotoRequests,
  } = usePhotoRequest({
    roomId,
    manager,
    fan: isFan,
  });
  const { event } = useContext(EventContext);
  const [eventSettings] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const [state, setState] = useState(0);

  const height = useWindowHeight();

  const participants = useParticipants();

  useEffect(() => {
    const pushUpdate = () => {
      setState((s) => s + 1);
    };
    const res = participants.map((p) => {
      p.on("trackPublished", pushUpdate);
      p.on("trackUnpublished", pushUpdate);
      return () => {
        p.off("trackPublished", pushUpdate);
        p.off("trackUnpublished", pushUpdate);
      };
    });
    return () => {
      res.map((r) => r());
    };
  }, [participants]);

  const overlay =
    matches.small || matches.medium
      ? event?.assets?.watermarkPortrait?.path
      : event?.assets?.watermarkLandscape?.path;

  const imageUrl = event?.assets?.preshowBanner?.path;

  let videoLength = participants.filter((participant) =>
    Array.from(participant.tracks.values()).find((t) => t.kind === "video")
  );

  let artistJoined = !!videoLength.length;
  const showLocalVideo = !manager || (manager && !!managerRoom);

  let showOverlay = overlay && participants.length > 0 && !artist && !manager;

  const artistLoading = status === "live" && !artistJoined;
  const artistLoadingForManager = manager && videoLength?.length < 2;

  const playRing = artistLoading && isFan;
  const playConnect = artistJoined && isFan;

  const {
    playRinging,
    stopRinging,
    isPlayingRinging,
    playConnected,
    hasPlayedConnected,
  } = useSounds();

  useEffect(() => {
    if (playRing && !isPlayingRinging && !hasPlayedConnected) {
      playRinging();
      const timer = setInterval(() => {
        playRinging();
      }, 2000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [playRing, isPlayingRinging]);

  if (playConnect && !hasPlayedConnected) {
    stopRinging();
    playConnected();
  }

  return localParticipant ? (
    <ScrollContainer {...props} manager={manager} className={"call-videos"}>
      <PhotoRequestDialogs roomId={roomId} fan={isFan} manager={manager} />
      {showOverlay && <Overlay src={overlay} />}
      {showLocalVideo &&
        Array.from(localParticipant.tracks.values()).find(
          (t) => t.kind === "video"
        ) && (
          <SmallVideo
            fullWidth={status === "ready"}
            isFan={isFan}
            height={height}
            manager={manager}
          >
            <Participant isLocal={true} participant={localParticipant} />
          </SmallVideo>
        )}
      {participants.map((participant) => {
        if (
          Array.from(participant.tracks.values()).find(
            (t) => t.kind === "video"
          )
        ) {
          return (
            <SmallVideo manager={manager} key={participant.sid} height={height}>
              <Participant
                isLocal={isFan ? eventSettings?.mirrorArtistForFan : true}
                key={participant.sid}
                participant={participant}
              />
            </SmallVideo>
          );
        }
      })}
      {isFan && artistLoading && (
        <Wrapper>
          <LoadingContainer>
            <EventAvatar background={imageUrl} />
            <StyledSpinner dark size="large" />
          </LoadingContainer>
          <H4>{event?.artist} is joining the call...</H4>
        </Wrapper>
      )}
      {artistLoadingForManager && (
        <ArtistJoiningManager manager>
          <LoadingContainer
            style={{
              padding: "24px",
              width: "104px",
              height: "104px",
            }}
          >
            <EventAvatar background={imageUrl} />
            <StyledSpinner dark size="large" />
          </LoadingContainer>
          <H4>{event?.artist} is joining the call...</H4>
        </ArtistJoiningManager>
      )}
    </ScrollContainer>
  ) : null;
};

export const LiveRoom = withDefaultMedia(LiveRoomInternal);

const Overlay = styled.img`
  position: absolute;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: calc((100vh - 76px) * 2);
    max-height: 50vw;
  }
  z-index: 2;
`;

const Wrapper = styled(SmallVideo)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50vh;
  width: 100%;
  ${H4} {
    color: #ffffff;
    margin-top: 40px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    height: 100%;
    width: 50%;
    min-width: 0;
  }
`;

const ArtistJoiningManager = styled(SmallVideo)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  width: 50%;

  ${H4} {
    color: #ffffff;
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    height: initial;
    padding: 24px;
    ${H4} {
      display: flex;
      margin-top: 40px;
    }
  }
`;

const EventAvatar = styled(GridColumn)`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: 1px solid #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const LoadingContainer = styled(GridColumn)`
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  width: 104px;
  height: 104px;
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 40px;
    width: 184px;
    height: 184px;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const ButtonPadding = styled(GridColumn)`
  padding: 0 24px;
  position: absolute;
  bottom: 40px;
  z-index: 500;
  width: 100%;
  max-width: 440px;
`;

const RequestButton = styled(ButtonPrimary)`
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  svg {
    path {
      fill: #ffffff;
    }
  }
`;
