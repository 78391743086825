import { useState } from "react";
import useSound2 from "use-sound";
import marimba_chord_prompt from "sounds/marimba_chord_prompt.mp3";

export const useMessageSounds = (unreadLength) => {
  const [played, setPlayed] = useState(0);
  const sound = marimba_chord_prompt;
  const [play] = useSound2(sound);

  if (unreadLength > 0 && unreadLength !== played) {
    play();
    if (played === 0 && unreadLength > 0) {
      setPlayed(unreadLength);
    } else {
      setPlayed(played + 1);
    }
  }
};
