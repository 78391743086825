import React, { useContext } from "react";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  Icon,
  ButtonPrimary,
  ButtonSecondary,
} from "notes";
import {
  EventContext,
  UserContext,
  ManagerModalContext,
  usePhotoRequest,
} from "components";
import { firebase } from "components";

export const CallControl = ({
  spectator,
  handleNext,
  handleEnd,
  room,
  manager,
  managerRoom,
  ...props
}) => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);
  const { setManagerModal } = useContext(ManagerModalContext);
  const roomId = room?.id;
  const {
    handleRequest,
    pendingPhotoRequests,
    pendingCapture,
  } = usePhotoRequest({ roomId, manager: true });
  const handleTakeover = () => {
    setManagerModal({
      confirmStepIn: () =>
        firebase
          .firestore()
          .doc(`meet_greet/${event.id}`)
          .update({ [`managerRooms.${user.uid}`]: room?.id, liveRoom: null }),
    });
  };
  const handleEndTakeover = () => {
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${managerRoom}`)
      .update({ status: "complete" });
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}`)
      .update({ [`managerRooms.${user.uid}`]: null });
  };

  return (
    <Container {...props}>
      <GridRow>
        <IconButton>
          <Icon name="Description" />
        </IconButton>
        {!managerRoom ? (
          <>
            <Button
              disabled={!pendingPhotoRequests?.empty || pendingCapture}
              onClick={handleRequest}
              iconLeft={<Icon tertiary name="Camera" />}
            >
              Take Photo
            </Button>
            {manager && (
              <Button
                onClick={handleTakeover}
                iconLeft={<Icon tertiary name="User" />}
              >
                Step In
              </Button>
            )}
          </>
        ) : (
          <Button
            onClick={handleEndTakeover}
            iconLeft={<Icon tertiary name="Exit" />}
          >
            Step Out
          </Button>
        )}
      </GridRow>
      {!managerRoom && (
        <>
          <NextCall
            onClick={handleNext}
            iconLeft={<Icon form name="NextCall" />}
          >
            Next Call
          </NextCall>
          <EndCall onClick={handleEnd} iconLeft={<Icon form name="EndCall" />}>
            End Call
          </EndCall>
        </>
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  margin-bottom: 24px;
`;

const Button = styled(ButtonPrimary)`
  min-width: inherit;
  width: 100%;
  margin-left: 8px;
  white-space: nowrap;
  svg {
    path {
      fill: #ffffff;
    }
  }

  &:disabled {
    svg {
      path {
        fill: ${(props) => props.theme.palette.gray.lighter};
      }
    }
  }
`;

const IconButton = styled(ButtonPrimary)`
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  flex: 0 0 40px;
  height: 40px;
  min-width: inherit;
  padding: 0;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: ${(props) => props.theme.palette.gray.primary};
    svg {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
`;

const CallAction = styled(ButtonSecondary)`
  border-color: #ffffff;
  color: #ffffff;
  margin-top: 8px;

  svg {
    path {
      fill: #ffffff;
    }
  }

  &:disabled {
    svg {
      path {
        fill: ${(props) => props.theme.palette.gray.lighter};
      }
    }
  }
`;

const NextCall = styled(CallAction)`
  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: ${(props) => props.theme.palette.black};
    svg {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
`;

const EndCall = styled(CallAction)`
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.red.primary};
    border-color: ${(props) => props.theme.palette.red.primary};
    color: #ffffff;
  }
`;
