import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const ConnectionIndicator = ({ strength, ...props }) => {
  return (
    <Container yEnd {...props}>
      <Bar1 signal={strength} />
      <Bar2 signal={strength} />
      <Bar3 signal={strength} />
      <Bar4 signal={strength} />
    </Container>
  );
};

const Container = styled(GridRow)`
  padding: 4px 3px;
  width: 24px;
  height: 24px;
  & > div + div {
    margin-left: 2px;
  }
`;

const Bar1 = styled.div`
  background-color: ${(props) =>
    props.signal >= 50
      ? props.theme.palette.green.primary
      : props.signal > 24
      ? props.theme.palette.yellow.primary
      : props.theme.palette.red.primary};
  ${(props) =>
    props.signal === 0 &&
    `background-color: ${props.theme.palette.gray.lighter};`}
  width: 3px;
  height: 4px;
`;

const Bar2 = styled.div`
  background-color: ${(props) =>
    props.signal >= 50
      ? props.theme.palette.green.primary
      : props.signal > 24
      ? props.theme.palette.yellow.primary
      : props.theme.palette.gray.lighter};
  width: 3px;
  height: 8px;
`;

const Bar3 = styled.div`
  background-color: ${(props) =>
    props.signal >= 75
      ? props.theme.palette.green.primary
      : props.signal >= 50
      ? props.theme.palette.yellow.primary
      : props.theme.palette.gray.lighter};
  width: 3px;
  height: 12px;
`;

const Bar4 = styled.div`
  background-color: ${(props) =>
    props.signal >= 90
      ? props.theme.palette.green.primary
      : props.theme.palette.gray.lighter};
  width: 3px;
  height: 16px;
`;
