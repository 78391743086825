import React from "react";
import styled from "styled-components";
import { GridColumn, Icon } from "notes";
import { MediaSection } from "./MediaSection";

export const MediaGallery = ({ videos, photos, ...props }) => {
  const photoArray =
    photos?.map((photo) => ({
      landscape: {
        source: photo?.source?.landscape,
        download: photo?.downloads?.landscape,
      },
      portrait: {
        source: photo?.source?.portrait,
        download: photo?.downloads?.portrait,
      },
    })) ?? [];

  const videoArray = videos?.map((video, index) => ({
    landscape: {
      source: video?.source?.landscape,
      download: video?.downloads?.landscape,
      thumbnail:
        photoArray[index]?.landscape?.source ??
        photoArray[0]?.landscape?.source,
    },
    portrait: {
      source: video?.source?.portrait,
      download: video?.downloads?.portrait,
      thumbnail:
        photoArray[index]?.portrait?.source ?? photoArray[0]?.portrait?.source,
    },
  }));

  return (
    <Container>
      {!!videos?.length && (
        <MediaSection
          icon={<SectionIcon form name="VideoChat" />}
          title="Videos"
          subtitle="View and download your meet &amp; greet videos below"
          photos={videoArray}
          video
        />
      )}
      {!!photos?.length && (
        <MediaSection
          icon={<SectionIcon selector name="Picture" />}
          title="Photos"
          subtitle="View and download your meet &amp; greet photos below"
          photos={photoArray}
        />
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-top: 56px;
  }
`;

const SectionIcon = styled(Icon)`
  margin-right: 16px;
  height: 24px;
  width: 24px;
  path {
    fill: ${(props) => props.theme.colors.disabledIcon};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }
`;
