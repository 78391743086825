import { useContext, useEffect, useState } from "react";
import {
  firebase,
  UserContext,
  TimeContext,
  useVideoContext,
  EventContext,
} from "components";
import { useCollection } from "react-firebase-hooks/firestore";
import { DateTime, Duration } from "luxon";

export const usePhotoRequest = ({ roomId, manager = false, fan = false }) => {
  const { user } = useContext(UserContext);
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);

  const [localCapture, setLocalCapture] = useState({});
  const {
    localTracks: [audio, video],
  } = useVideoContext();
  const photosRef = firebase
    .firestore()
    .collection(`/meet_greet/${event.id}/rooms/${roomId}/photos`);
  const [pendingPhotoRequests] = useCollection(
    photosRef.where("status", "==", "pending")
  );
  const pendingApproval = pendingPhotoRequests?.docs.find((p) =>
    !fan ? p.data().requestedBy === roomId : p.data().requestedBy !== user.uid
  );
  const [approvedPhotoRequests] = useCollection(
    photosRef.where("status", "==", "approved")
  );
  const pendingCapture = approvedPhotoRequests?.docs.find(
    (p) => !p.data().uploads[manager ? roomId : user.uid]
  );
  const captureTime = pendingCapture?.data().countdownBegin
    ? DateTime.fromJSDate(pendingCapture.data().countdownBegin?.toDate()).plus({
        seconds: 3,
      })
    : null;

  const recentPhoto = approvedPhotoRequests?.docs.find(
    (p) =>
      p.data().countdownBegin &&
      DateTime.fromJSDate(p.data().countdownBegin.toDate()) - time > -15000
  );

  const handleRequest = () => {
    photosRef.add({
      created: firebase.firestore.FieldValue.serverTimestamp(),
      status: fan ? "pending" : "approved",
      requestedBy: user.uid,
      countdownBegin: fan
        ? undefined
        : firebase.firestore.FieldValue.serverTimestamp(),
      uploads: {},
    });
  };
  const handleApprove = () => {
    photosRef.doc(pendingApproval.id).update({
      status: "approved",
      countdownBegin: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };
  const handleCancel = () => {
    photosRef
      .doc(pendingPhotoRequests?.docs[pendingPhotoRequests?.docs.length - 1].id)
      .update({
        status: "canceled",
      });
  };
  useEffect(() => {
    if (
      !manager &&
      pendingCapture &&
      captureTime &&
      captureTime < time &&
      video
    ) {
      const storageRef = firebase.storage().ref();
      const filename = `${user.uid}.jpeg`;
      const imageRef = storageRef.child(
        `${process.env.REACT_APP_STORAGE_PATH}/${pendingCapture.id}/${filename}`
      );
      let update = {
        [`uploads.${user.uid}`]: "pending",
      };
      const videos = document.querySelectorAll(".call-videos video");
      if (videos?.length) {
        [...videos].map((video, index) => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          canvas.toBlob((blob) => {
            setLocalCapture((s) => ({
              ...s,
              [video.parentNode.id]: window.URL.createObjectURL(blob),
            }));
          });
        });
      }
      photosRef.doc(pendingCapture.id).update(update);
    }
  }, [pendingCapture?.id, captureTime < time]);

  useEffect(() => {
    if (!manager) {
      setLocalCapture({});
    }
  }, [recentPhoto?.id]);

  const countdown =
    time < captureTime &&
    Duration.fromMillis(captureTime - time).shiftTo("seconds", "milliseconds");
  return {
    handleRequest,
    handleApprove,
    handleCancel,
    pendingPhotoRequests,
    pendingCapture,
    pendingApproval,
    recentPhoto,
    localCapture,
    countdown,
  };
};
