import React, { useContext } from "react";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  Subheader,
  Footnote,
  P,
} from "notes";
import {
  Tabs,
  UserActions,
  SortableUserActions,
  firebase,
  ManagerModalContext,
  EventContext,
} from "components";
import { SortableContainer } from "react-sortable-hoc";
import { ReactComponent as BandGray } from "Images/BandGrey.svg";
import { useDocumentData } from "react-firebase-hooks/firestore";

const SortableList = SortableContainer(({ users, handleSort }) => {
  return (
    <div>
      {users.map((user, index) => (
        <SortableUserActions
          key={`item-${user.id}`}
          index={index}
          idx={index}
          {...user}
          isNext={index === 0}
          handleSort={handleSort}
        />
      ))}
    </div>
  );
});

export const UserTabs = ({ users, handleNext, ...props }) => {
  const { setManagerModal } = useContext(ManagerModalContext);
  const { event } = useContext(EventContext);

  const [meet_greet, loading, error] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event?.id}`)
  );

  const changePause = () => {
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}`)
      .update({ paused: !meet_greet?.paused, started: true });
  };

  let checkedIn = users.filter(
    ({ checkedIn, status }) =>
      checkedIn && ["pending", "ready"].includes(status)
  );
  const checkedOut = users.filter(
    ({ checkedIn, status }) =>
      !checkedIn && ["pending", "ready"].includes(status)
  );
  let online = checkedIn.filter(({ video }) => video).slice(0, 4);
  checkedIn = checkedIn.filter((o) => online.indexOf(o) < 0);
  checkedIn = [...online, ...checkedIn];
  const completed = users.filter(({ status }) =>
    ["complete", "canceled"].includes(status)
  );

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const aboveDoc = checkedIn[newIndex + (oldIndex > newIndex ? -1 : 0)];
    const belowDoc = checkedIn[newIndex + (oldIndex < newIndex ? 1 : 0)];
    const above = aboveDoc ? aboveDoc.priority : 0;
    const below = belowDoc ? belowDoc.priority : Number.MAX_VALUE;
    const newOrder = above / 2 + below / 2;
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/`)
      .doc(checkedIn[oldIndex].id)
      .update({ priority: newOrder });
    return;
  };

  return (
    <LeftContainer {...props}>
      <Tabs>
        <GridColumn style={{ overflow: "hidden" }} label="Queue" stretch>
          <OverflowContainer>
            <Actions yCenter>
              {meet_greet?.paused ? (
                <Primary onClick={changePause} style={{ height: "100%" }}>
                  {meet_greet?.started ? "Resume" : "Start"} Event
                </Primary>
              ) : (
                <div>
                  <Primary
                    iconLeft={<Icon form name="VideoChat" />}
                    onClick={handleNext}
                  >
                    Start Next Call
                  </Primary>
                  <Secondary onClick={changePause} style={{ marginTop: "4px" }}>
                    Pause Event
                  </Secondary>
                </div>
              )}
              <div>
                <P style={{ margin: "8px", textAlign: "center" }}>
                  Event is {meet_greet?.paused ? "Paused" : "LIVE"}
                </P>
                <Secondary
                  onClick={() => setManagerModal({ globalMessage: true })}
                  iconLeft={<Icon form name="InstantMessage" />}
                  hideForArtist
                >
                  Message Queue
                </Secondary>
              </div>
            </Actions>
          </OverflowContainer>

          <ScrollContainer>
            <ListLabel>Up Next...</ListLabel>
            <SortableList
              users={checkedIn}
              onSortEnd={onSortEnd}
              handleSort={onSortEnd}
              useDragHandle
              lockToContainerEdges
              lockAxis={"y"}
            />
            {checkedOut && (
              <>
                <DividerLabel>Have Not Checked In</DividerLabel>
                {checkedOut.map((user) => (
                  <UserActions {...user} />
                ))}
              </>
            )}
          </ScrollContainer>
        </GridColumn>
        <GridColumn
          label="Completed Calls"
          stretch
          style={{ overflowY: "scroll" }}
        >
          {completed.length > 0 ? (
            completed.map((user) => <UserActions {...user} />)
          ) : (
            <NoCompleteCalls xCenter yCenter>
              <BandGray />
              <Footnote>
                No meet &amp; greet calls have been completed...
              </Footnote>
            </NoCompleteCalls>
          )}
        </GridColumn>
      </Tabs>
    </LeftContainer>
  );
};

const ScrollContainer = styled(GridColumn)`
  height: calc(100vh - 148px);
  overflow-y: scroll;
`;

const NoCompleteCalls = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  margin-top: ${(96 / 752) * 100}vh;
  max-width: 184px;
  svg {
    max-width: 184px;
  }
  ${Footnote} {
    text-align: center;
  }
`;

const DividerLabel = styled(Subheader)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding-top: 24px;
  padding-bottom: 8px;
  padding-left: 40px;
`;

const LeftContainer = styled(GridColumn)`
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 456px;
  }
  width: 100%;
`;

const Actions = styled(GridRow)`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  margin-bottom: 12px;
  flex-shrink: 0;
  padding: 6px 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 16px;
  }
`;

const OverflowContainer = styled(GridColumn)`
  width: 100%;
`;

const Primary = styled(ButtonPrimary)`
  margin-right: ${(props) => (props.theme.isArtist ? 0 : "24px")};
  ${(props) => props.theme.isArtist && "width: 100%;"};
  svg path {
    fill: #ffffff;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: ${(props) => (props.theme.isArtist ? 0 : "16px")};
    min-width: initial;
    width: 100%;
    white-space: nowrap;
  }
`;

const Secondary = styled(ButtonSecondary)`
  ${(props) => props.theme.isArtist && props.hideForArtist && "display: none;"};
  svg path {
    fill: ${(props) => props.theme.colors.action};
  }
  &:hover {
    svg path {
      fill: #ffffff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    min-width: initial;
    width: 100%;
    white-space: nowrap;
  }
`;

const ListLabel = styled(Subheader)`
  margin-left: 40px;
`;
