import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import { GridColumn, GridRow, withDefaultMedia } from "notes";
import {
  useCollection,
  useDocumentData,
  useDocument,
} from "react-firebase-hooks/firestore";
import {
  EventContext,
  TimeContext,
  firebase,
  Header,
  VideoContext,
  ChatProvider,
  ManagerModalProvider,
  UserContext,
  CallControls,
  ChatContext,
  LiveRoom,
  ParticipantStrip,
  ArtistTrackPublished,
} from "components";
import { UserTabs, ChatPanel, MobileNav, ManagerPreview } from "./Components";
import { DateTime } from "luxon";
import { theme } from "theme";
import { InCall } from "../Artist/Components";
import useLocalVideoToggle from "hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "hooks/useLocalAudioToggle/useLocalAudioToggle";

export const Manager = (props) => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    localStorage.setItem(`${event.id}|${user.uid}|ready`, ready);
    if (ready) {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}`)
        .update({
          [`managerStatus.${props.artist ? "artist" : user.uid}`]: true,
        });
    }
  }, [ready]);

  const updatedTheme = {
    ...theme,
    isArtist: !!props.artist,
    largeControls: event?.enableLargeControls ?? false,
  };
  return (
    <ChatProvider>
      <ArtistTrackPublished artist={!!props.artist} />
      <ThemeProvider theme={updatedTheme}>
        <ManagerModalProvider>
          {ready ? (
            <ManagerInternal {...props} />
          ) : (
            <ManagerPreview
              setReady={setReady}
              artist={props.artist}
              eventId={event?.id}
            />
          )}
        </ManagerModalProvider>
      </ThemeProvider>
    </ChatProvider>
  );
};

const ManagerInternal = withDefaultMedia(({ artist = false, matches }) => {
  const { user } = useContext(UserContext);
  const { user: chatUser, teamChat } = useContext(ChatContext);
  const { event } = useContext(EventContext);
  const { joinRoom, room } = useContext(VideoContext);
  const { time } = useContext(TimeContext);
  const theme = useContext(ThemeContext);
  const [eventSettings, l, e] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const sessionDuration = event?.sessionDuration || 90;
  const [view, setView] = useState("init");
  const isMobile = matches.small || matches.medium;
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const [userRooms, load, err] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms`)
      .where("status", "==", "ready")
      .orderBy("priority")
  );

  const [pendingUserRooms, pload, perr] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms`)
      .where("status", "in", ["pending", "complete", "canceled"])
  );

  const liveRoom = eventSettings?.liveRoom;

  const managerRoom = eventSettings?.managerRooms?.[user?.uid];

  const [liveRoomDoc, liveLoad] = useDocument(
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${managerRoom || liveRoom}`)
  );

  useEffect(() => {
    if (!l) {
      let expectedRoom =
        event.id + "|" + (managerRoom || liveRoom || `${event.id}/mods`);
      if (room?.name !== expectedRoom) {
        if (room?.name) {
          room.disconnect();
        }
        joinRoom(`${expectedRoom}`, !(!!managerRoom || !liveRoom));
      }
    }
  }, [liveRoom, managerRoom, l]);

  useEffect(() => {
    if (managerRoom) {
      toggleVideoEnabled(true);
      toggleAudioEnabled(true);
    }
  }, [managerRoom]);

  useEffect(() => {
    if (view === "init") {
      setView("video");
    } else {
      if ((chatUser && chatUser !== "eventmods") || (chatUser && isMobile)) {
        setView("chat");
      } else {
        setView("list");
      }
    }
  }, [chatUser]);

  const getPriority = async () => {
    return firebase.firestore().runTransaction((transaction) => {
      const ref = firebase.firestore().doc(`meet_greet/${event.id}`);
      return transaction.get(ref).then((doc) => {
        if (doc.exists) {
          const lockTimestamp = doc.get("lockTimestamp");
          if (
            lockTimestamp &&
            DateTime.fromJSDate(lockTimestamp.toDate()).plus({
              seconds: 6,
            }) > time
          ) {
            return;
          }
          transaction.update(ref, {
            lockTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
          return true;
        }
      });
    });
  };

  const handleNext = async () => {
    const prio = await getPriority();
    if (!prio) {
      return;
    }
    setView("video");
    if (liveRoom) {
      await firebase
        .firestore()
        .doc(`meet_greet/${event.id}/rooms/${liveRoom}`)
        .update({ status: "complete" });
    }
    const nextRoom = userRooms?.docs.find(
      (room) =>
        room.data().status === "ready" &&
        DateTime.fromJSDate(room.data().video?.toDate()).plus({
          seconds: 60,
        }) > time
    );
    if (nextRoom) {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}`)
        .update({ liveRoom: nextRoom.id });
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}/rooms/${nextRoom.id}`)
        .update({
          status: "live",
          startTime: null,
        });
    } else {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}`)
        .update({ liveRoom: null });
      setView("list");
    }
  };

  const handleEnd = async () => {
    const prio = await getPriority();
    if (!prio) {
      return;
    }
    if (liveRoom) {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}/rooms/${liveRoom}`)
        .update({ status: "complete" });
    }
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}`)
      .update({ liveRoom: null });
    setView("list");
  };

  const users = userRooms?.docs
    .concat(pendingUserRooms?.docs || [])
    .map((doc, index) => {
      const data = doc.data();
      const checkedIn = ["ready", "live"].includes(data.status);
      return {
        name: "",
        messages: 0,
        signalStrength: 85,
        hasBio: false,
        duration: data.sessionDuration || sessionDuration,
        checkedIn,
        id: doc.id,
        ...data,
        online: data.online
          ? DateTime.fromJSDate(data.online.toDate()).plus({
              seconds: 60,
            }) > time
          : false,
        video: data.video
          ? DateTime.fromJSDate(data.video.toDate()).plus({
              seconds: 60,
            }) > time
          : false,
        audio: data.audio
          ? DateTime.fromJSDate(data.audio.toDate()).plus({
              seconds: 60,
            }) > time
          : false,
      };
    });

  const isCallLive = liveRoom === liveRoomDoc?.id;

  let isModalOpen = chatUser && chatUser !== "eventmods";

  const chatStyle = {
    borderTop: `1px solid ${theme.palette.gray.primary}`,
    marginTop: !isMobile && isCallLive ? "66px" : "8px",
  };

  if (artist && isCallLive && !!liveRoom && !liveLoad) {
    const controls = {
      handleEnd,
      handleNext,
      room: liveRoomDoc && isCallLive && liveRoomDoc,
      liveRoom,
    };
    return <InCall {...controls} />;
  }

  return (
    <PageWrapper stretch modalOpen={isModalOpen}>
      {(!isMobile || view === "list") && <Header artist={artist} />}
      <ContentWrapper>
        {(!isMobile || view === "list") && users && (
          <UserTabs users={users} handleNext={handleNext} />
        )}
        <ChatTransition open={view === "chat"}>
          {(!isMobile || view === "chat") && (
            <ChatPanel team={chatUser === "eventmods"} />
          )}
        </ChatTransition>
        {(!isMobile || view === "video") && (
          <RightSideContainer xCenter yCenter={!!chatUser}>
            {isCallLive ? (
              <LiveRoom
                manager={!artist}
                artist={artist}
                roomId={liveRoom}
                managerRoom={managerRoom}
              />
            ) : (
              <ParticipantStrip isArtist={artist} />
            )}
            <StyledCallControls
              artist={artist}
              room={liveRoomDoc && isCallLive && liveRoomDoc}
              handleNext={handleNext}
              handleEnd={handleEnd}
              managerRoom={managerRoom}
              chatStyle={chatStyle}
              inCall={isCallLive || managerRoom}
              isMobile={isMobile}
              float={
                (isMobile && !isCallLive) ||
                (!isCallLive && !managerRoom && !!chatUser)
              }
            />
          </RightSideContainer>
        )}
        {!isMobile && !isCallLive && (
          <ChatProvider userId={`eventmods`} team>
            <ChatTransition open={teamChat}>
              <ChatPanel team />
            </ChatTransition>
          </ChatProvider>
        )}
      </ContentWrapper>
      {isMobile && !isModalOpen && <MobileNav setView={setView} view={view} />}
    </PageWrapper>
  );
});

const ContentWrapper = styled(GridRow)`
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
`;

const ChatTransition = styled(GridRow)`
  flex: ${(props) => (props.open ? "1 1 100%" : "0 0 0")};
  @media only screen and ${(props) => props.theme.media.large} {
    transition: flex 0.2s ease;
    flex: 0 0 ${(props) => (props.open ? "392px" : 0)};
    overflow: hidden;
  }
`;

const PageWrapper = styled(GridColumn)`
  overflow: hidden;
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    margin-bottom: 0;
  }
`;

const RightSideContainer = styled(GridColumn)`
  background-color: #000000;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    width: initial;
    overflow-y: scroll;
  }
`;

const StyledCallControls = styled(CallControls)`
  ${(props) =>
    props.float &&
    `height: 40px;
      position: absolute;
      bottom: 40px;
      z-index: 200;
    `};
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
    flex-direction: ${(props) => (props.inCall ? "row" : "column")};
    margin-top: 24px;
    ${(props) =>
      props.float &&
      `height: 40px;
      position: absolute;
      bottom: 16px;
      z-index: 200;
    `};
  }
`;
