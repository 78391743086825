import React from "react";
import { useParticipants } from "components";
import ParticipantTracks from "components/ParticipantTracks/ParticipantTracks";

export const AudioOutput = () => {
  const participants = useParticipants();
  return (
    <>
      {participants.map((p) => (
        <ParticipantTracks key={p.sid} audioOnly participant={p} />
      ))}
    </>
  );
};
