import styled from "styled-components";
import AnimationLargeBlack from "Images/AnimationLargeBlack.png";
import AnimationSmallBlack from "Images/AnimationSmallBlack.png";
import AnimationLargeWhite from "Images/AnimationLargeWhite.png";
import AnimationSmallWhite from "Images/AnimationSmallWhite.png";

export const Spinner = styled.img.attrs((props) => ({
  src:
    props.size === "large"
      ? props.dark
        ? AnimationLargeWhite
        : props.light
        ? AnimationLargeBlack
        : props.theme.dark
        ? AnimationLargeWhite
        : AnimationLargeBlack
      : props.light
      ? AnimationSmallBlack
      : props.dark
      ? AnimationSmallWhite
      : props.theme.dark
      ? AnimationSmallWhite
      : AnimationSmallBlack,
}))`
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 10px;
  margin: -6px;
  width: 24px;
  height: 24px;
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
  @keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
