import React, { useState, useContext } from "react";
import { firebase, EventContext, ManagerModalContext } from "components";
import { TextArea, P } from "notes";
import { DisabledLabel, Actions, SubmitButton, CancelButton } from "./UserBio";

export const MessageFan = ({ onComplete }) => {
  const {
    managerModal: { sms },
  } = useContext(ManagerModalContext);
  const id = sms?.id;
  const phone = sms?.phone;
  const name = sms?.name;
  const { event } = useContext(EventContext);
  const [value, setValue] = useState("");
  const handleConfirm = () => {
    firebase.functions().httpsCallable("sms-sendMessage")({
      message: value,
      phoneNumber: phone,
      source: {
        type: "meet_greet",
        eventId: event.id,
        uid: id,
      },
    });
    onComplete();
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <DisabledLabel style={{ flexDirection: "row" }}>
        To:
        <P
          style={{
            fontSize: "15px",
            fontWeight: "600",
            marginLeft: "8px",
            color: "#222222",
          }}
        >
          {name}
        </P>
      </DisabledLabel>

      <TextArea
        placeholder="Send a text message..."
        value={value}
        onChange={handleChange}
      />
      <Actions>
        <CancelButton onClick={onComplete}>Cancel</CancelButton>
        <SubmitButton onClick={handleConfirm}>Send</SubmitButton>
      </Actions>
    </>
  );
};
