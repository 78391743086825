import React, { useContext, useEffect, useState, useRef } from "react";
import {
  VideoContext,
  LocalAvatar,
  DeviceSelector,
  EventContext,
  UserContext,
  firebase,
} from "components";
import styled from "styled-components";
import { GridColumn, Link, withDefaultMedia, GridRow, P } from "notes";
import { LobbyMediaActions } from "./LobbyMediaActions";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";

export const Preview = withDefaultMedia(
  ({
    matches,
    isFan,
    onTroubleshoot,
    noDeviceSelect,
    hideControls,
    ...props
  }) => {
    const { event } = useContext(EventContext);
    const { user } = useContext(UserContext);
    const { localTracks } = useContext(VideoContext);
    const [video, setVideo] = useState();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

    const vidRef = useRef();

    const roomRef = firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${user.uid}`);

    useEffect(() => {
      setVideo(localTracks.find((t) => t.kind === "video"));
    }, [localTracks]);

    useEffect(() => {
      if (video && vidRef.current?.replaceChildren) {
        vidRef.current.replaceChildren(video.attach());
      }
    }, [video]);

    return (
      <Container {...props}>
        {!isVideoEnabled && (
          <NoVideo>
            <NoVideoInner xCenter yCenter>
              {isFan ? (
                <>
                  <P
                    style={{
                      padding: "0 24px",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Your camera and microphone will need to be on for the meet
                    &amp; greet.{" "}
                    <StyledLink
                      onClick={toggleVideoEnabled}
                      style={{ display: "inline-flex" }}
                    >
                      Turn On Now
                    </StyledLink>
                  </P>
                </>
              ) : (
                <StyledLocalAvatar />
              )}
            </NoVideoInner>
          </NoVideo>
        )}
        <Video
          ref={vidRef}
          style={!isVideoEnabled ? { display: "none" } : {}}
        />

        {!video && isVideoEnabled && (
          <NoVideo>
            <NoVideoInner xCenter yCenter>
              Unable to load video player.
              <StyledLink onClick={onTroubleshoot}>Troubleshoot</StyledLink>
            </NoVideoInner>
          </NoVideo>
        )}
        {!hideControls && (
          <MediaContainer>
            <LobbyMediaActions
              videoMuted={!isVideoEnabled}
              audioMuted={!isAudioEnabled}
              toggleVideo={() =>
                isFan
                  ? toggleVideoEnabled(true)
                  : toggleVideoEnabled(!isVideoEnabled)
              }
              toggleAudio={() =>
                isFan
                  ? toggleAudioEnabled(true)
                  : toggleAudioEnabled(!isAudioEnabled)
              }
              isFan={isFan}
            />
          </MediaContainer>
        )}
        {matches.large && !noDeviceSelect && <DeviceSelector />}
      </Container>
    );
  }
);

export const StyledLocalAvatar = styled(LocalAvatar)`
  && {
    width: 64px;
    height: 64px;
    font-size: 24px;
    line-height: 48px;
  }
`;

const Video = styled.div`
  transform: rotateY(180deg);
`;

const MediaContainer = styled(GridRow)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0 0 8px 8px;
  width: 100%;
  height: 80px;
  position: relative;
  margin-top: -80px;
  z-index: 5;
  @media only screen and ${(props) => props.theme.media.large} {
    border-radius: 0;
  }
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: #ffffff;
  }
`;

const Container = styled.div`
  video {
    border-radius: 8px;
    width: 100%;
  }
  max-height: 100vh;

  @media only screen and ${(props) => props.theme.media.large} {
    video {
      border-radius: 0;
    }
  }
`;

export const NoVideo = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.black};
  border-radius: 8px;
  padding-top: 100%;
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    border-radius: 0;
  }
`;

export const NoVideoInner = styled(GridColumn)`
  color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
