import React from "react";
import styled from "styled-components";
import { GridRow, H4, Icon } from "notes";
import { SiteLogo } from "components";

export const PageHeader = ({ onHelp, ...props }) => {
  return (
    <Header yCenter {...props}>
      <SiteLogo />
      <Button yCenter onClick={() => !!onHelp && onHelp()}>
        <StyledIcon indicator name="Questions" />
        <HelpText>Help</HelpText>
      </Button>
    </Header>
  );
};

const Header = styled(GridRow)`
  background-color: ${(props) => (props.theme.dark ? "#000000" : "#ffffff")};
  justify-content: space-between;
  padding: 0 40px 0 24px;
  height: 56px;
`;

const Button = styled(GridRow)`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  path {
    fill: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
  }
`;

const HelpText = styled(H4)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
`;
