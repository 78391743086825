import React, { useContext } from "react";
import styled from "styled-components";
import { firebase, EventContext, ManagerModalContext } from "components";
import { Subheader, GridRow, H3, P, withDefaultMedia, Link } from "notes";
import { Actions, CancelButton, SubmitButton } from "./UserBio";

export const ConfirmRemove = withDefaultMedia(({ matches, onComplete }) => {
  const { event } = useContext(EventContext);
  const {
    managerModal: {
      confirmRemove: { id, name },
    },
  } = useContext(ManagerModalContext);
  const handleConfirm = async () => {
    const res = await firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${id}`)
      .update({ status: "complete" });
    onComplete();
  };
  return (
    <>
      <StyledH3>Remove From Queue</StyledH3>
      <Subheader>{name}</Subheader>
      <Description>
        Are you sure you want to remove this fan from the Meet &amp; Greet
        queue? To undo removal, find them under "Completed Calls".
      </Description>
      {matches.large ? (
        <Actions>
          <CancelButton onClick={onComplete}>Cancel</CancelButton>
          <SubmitButton onClick={handleConfirm}>Remove</SubmitButton>
        </Actions>
      ) : (
        <GridRow xEnd style={{ marginTop: "24px" }}>
          <Link onClick={onComplete} style={{ marginRight: "32px" }}>
            Cancel
          </Link>
          <Link onClick={handleConfirm}>Remove</Link>
        </GridRow>
      )}
    </>
  );
});

const StyledH3 = styled(H3)`
  @media only screen and ${(props) => props.theme.media.large} {
    &&& {
      text-align: center;
    }
  }
`;

const Description = styled(P)`
  text-align: left;
  @media only screen and ${(props) => props.theme.media.large} {
    text-align: center;
  }
`;
