import { useContext } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { firebase, EventContext, UserContext } from "components";

export const usePhotos = () => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);

  const [photos, load] = useDocument(
    firebase
      .firestore()
      .collection(`meet_greet/${event?.id}/rooms/${user?.uid}/photos`)
  );

  if (load || !event) {
    return [];
  }

  const photosArray = photos?.docs
    .map((photo) => photo.data())
    .filter(({ downloads }) => !!downloads);

  return photosArray;
};
