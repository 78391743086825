import React, { useState, useEffect, useContext } from "react";
import { firebase, EventContext, ManagerModalContext } from "components";
import {
  ButtonSecondary,
  Input,
  ButtonPrimary,
  GridRow,
  Label,
  withDefaultMedia,
  Link,
} from "notes";

export const CallDuration = withDefaultMedia(({ matches, onComplete }) => {
  const { event } = useContext(EventContext);
  const {
    managerModal: { callDuration },
  } = useContext(ManagerModalContext);
  const [value, setValue] = useState("");
  const handleConfirm = async () => {
    let newDuration = Number.parseInt(value);
    newDuration = Number.isNaN(newDuration) ? null : newDuration;
    const res = await firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${callDuration}`)
      .update({ sessionDuration: newDuration });
    onComplete();
  };
  useEffect(() => {
    setValue("");
  }, [callDuration]);
  return (
    <>
      <Label>Set New Call Duration (Seconds)</Label>
      <Input value={value} onChange={setValue} />
      {matches.large ? (
        <GridRow xEnd style={{ marginTop: "56px" }}>
          <ButtonSecondary onClick={onComplete}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={handleConfirm} style={{ marginLeft: "24px" }}>
            Apply
          </ButtonPrimary>
        </GridRow>
      ) : (
        <GridRow xEnd style={{ marginTop: "24px" }}>
          <Link onClick={onComplete} style={{ marginRight: "32px" }}>
            Cancel
          </Link>
          <Link onClick={handleConfirm}>Done</Link>
        </GridRow>
      )}
    </>
  );
});
