import React, { createContext, useState, useContext } from "react";
import { firebase, EventContext, UserContext } from "components";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

export const ChatContext = createContext();
export const ChatConsumer = ChatContext.Consumer;
export const ChatProvider = ({ children, userId, isFan = false }) => {
  const { user: currentUser } = useContext(UserContext);
  const [user, setUserId] = useState(userId);
  const [teamChat, setTeamChatVisible] = useState(false);
  const { event } = useContext(EventContext);
  const [uniqueChats] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/${user}/chats`)
  );

  const [unreadFromFan, l, e] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/${user}/chats`)
      .where("read", "==", false)
  );

  const [unreadFanChats, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/${user}/chats`)
      .where("isFanRead", "==", false)
  );

  const [globalChats] = useCollection(
    firebase.firestore().collection(`meet_greet/${event.id}/chats`)
  );

  const eventChats = user === "eventmods" ? null : globalChats;

  const chats = [
    ...(uniqueChats?.docs || []),
    ...(eventChats?.docs || []),
  ].sort((a, b) => b.data().sent - a.data().sent);

  const [meetGreet] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );

  const sendMessage = (message, uid = user) => {
    let chatObject = {
      sender: currentUser.uid,
      message,
      sent: firebase.firestore.FieldValue.serverTimestamp(),
      read: !isFan,
      isFanRead: false,
    };

    if (uid === "eventmods") {
      let managerIds = meetGreet?.managerStatus ?? {};
      delete managerIds["artist"];
      delete managerIds[currentUser?.uid];
      if (!!Object.keys(managerIds)?.length) {
        chatObject.unread = managerIds;
      }
    }

    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/${uid}/chats`)
      .add(chatObject);
  };

  const sendGlobalMessage = (message) => {
    firebase.firestore().collection(`meet_greet/${event.id}/chats`).add({
      sender: currentUser.uid,
      message,
      sent: firebase.firestore.FieldValue.serverTimestamp(),
      read: true,
    });
  };

  if (!isFan && user && unreadFromFan?.docs?.length) {
    unreadFromFan?.forEach((message) => {
      message.ref.update({ read: true });
    });
  }

  const [unreadTeamChats] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms/eventmods/chats`)
      .where(`unread.${currentUser?.uid}`, "==", true)
  );

  if (!isFan && teamChat && unreadTeamChats?.docs?.length) {
    unreadTeamChats?.forEach((message) => {
      message.ref.update({
        [`unread.${currentUser?.uid}`]: firebase.firestore.FieldValue.delete(),
      });
    });
  }

  const hasUnreadTeamChats = !!unreadTeamChats?.docs?.length;

  const setUser = (val) => {
    setUserId(val);
    if (val) {
      setTeamChatVisible(false);
    }
  };

  const setTeamChat = (bool) => {
    setTeamChatVisible(bool);
    if (bool) {
      setUserId(null);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        user,
        setUser,
        chats,
        sendMessage,
        sendGlobalMessage,
        setTeamChat,
        teamChat,
        unreadFanChats,
        hasUnreadTeamChats,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
