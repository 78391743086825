import React, { useState } from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const Tabs = ({ children, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const selectTab = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <>
      <TabList role="tablist" {...props}>
        {!!children &&
          children.map(({ props: { label } }, index) => (
            <TabButton
              xCenter
              yCenter
              role="tab"
              selected={selectedTab === index}
              aria-selected={selectedTab === index ? "true" : "false"}
              onClick={() => selectTab(index)}
            >
              {label}
            </TabButton>
          ))}
      </TabList>

      {!!children &&
        children.map((comp, index) =>
          selectedTab === index ? comp : undefined
        )}
    </>
  );
};

const TabButton = styled(GridRow)`
  border-bottom: 4px solid
    ${(props) =>
      props.selected
        ? props.theme.palette.purple.lighter
        : props.theme.palette.purple.darker};
  cursor: pointer;
  color: ${(props) =>
    props.selected ? "#ffffff" : props.theme.palette.purple.lighter};
  flex-grow: 1;
  font-weight: 400;
  height: 40px;
  outline: none;
  padding: 0 24px;
  transition: border-color 0.2s ease-in;

  @media only screen and ${(props) => props.theme.media.large} {
    border-bottom: 4px solid
      ${(props) =>
        props.selected ? props.theme.palette.purple.primary : "#fff"};
    color: ${(props) =>
      props.selected
        ? props.theme.palette.purple.primary
        : props.theme.palette.black};
    min-width: 104px;
    flex-grow: 0;
  }
`;

const TabList = styled(GridRow)`
  width: auto;
  background-color: ${(props) => props.theme.palette.purple.darker};
  padding: 0 24px;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.large} {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.lighter};
    background-color: #ffffff;
  }
`;
