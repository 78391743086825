import React, { useContext, useEffect, useState } from "react";
import {
  UserContext,
  VideoContext,
  firebase,
  ChatProvider,
  EventContext,
  Preview,
  LiveRoom,
  EventBanner,
  NavBarWrapper,
  NetworkQualityUploader,
  Troubleshoot,
  TimeContext,
} from "components";
import { withDefaultMedia, GridColumn, Footnote } from "notes";
import styled, { ThemeProvider } from "styled-components";
import {
  useDocument,
  useCollection,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  WaitingTime,
  MobileNav,
  Connection,
  MobileHeader,
  WaitingTimeMobile,
  InCall,
  TextUpdates,
  CheckIn,
  PostCall,
  PageHeader,
  ChatBox,
} from "./Components";
import { DateTime } from "luxon";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { theme } from "theme";

const informationalMessage = `Welcome to the Meet & Greet! As long as the spinner is animated you’re
      connected and there is nothing else you need to do before your call.`;

export const Fan = withDefaultMedia(({ matches }) => {
  const {
    name,
    user: { uid: id },
  } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const [eventSettings] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const {
    joinRoom,
    room: videoRoom,
    isAcquiringLocalTracks,
    leaveRoom,
  } = useContext(VideoContext);
  const [localReady, setLocalReady] = useState(
    localStorage.getItem(`${event.id}|${id}|ready`) === "true" || false
  );
  const [room, load] = useDocument(
    firebase.firestore().doc(`meet_greet/${event.id}/rooms/${id}`)
  );
  const status = room?.data()?.status;
  const [view, setView] = useState("video");
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isAudioEnabled] = useLocalAudioToggle();
  const isMobile = matches.small || matches.medium;
  const [troubleshootPosition, setTroubleshootPosition] = useState(0);
  useEffect(() => {
    if (!load && status === "ready" && !localReady) {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}/rooms/${id}`)
        .update({ status: "pending" });
    }
    localStorage.setItem(`${event.id}|${id}|ready`, localReady);
  }, [localReady, status]);

  const [list] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms`)
      .where("status", "in", ["ready"])
      .orderBy("priority")
  );

  let adjustedList =
    list?.docs
      .filter((doc) => {
        if (doc.id === id) {
          return isVideoEnabled;
        }
        return doc.data().video
          ? DateTime.fromJSDate(doc.data().video.toDate()).plus({
              seconds: 60,
            }) > time
          : false;
      })
      .slice(0, 4) || [];

  let combinedList = [
    ...adjustedList,
    ...(list?.docs.filter(
      (doc) => !adjustedList.find((d) => d.id === doc.id)
    ) || []),
  ];

  const position = combinedList?.findIndex((doc) => doc?.id === id);

  let minTime = Math.floor(
    (((position + 1) * event?.sessionDuration + 5) * 0.9) / 60
  );
  let maxTime = Math.ceil(
    (((position + 1) * event?.sessionDuration + 15) * 1.15) / 60
  );

  let waitTime =
    maxTime < 4
      ? Math.max(minTime, 1)
      : `${Math.max(maxTime - 8, minTime, 1)} - ${maxTime}`;

  useEffect(() => {
    if (
      !isAcquiringLocalTracks &&
      position < 1 &&
      !videoRoom?.name &&
      ["pending", "ready", "live"].includes(status)
    ) {
      joinRoom(`${event.id}|${id}`);
    }
  }, [position, isAcquiringLocalTracks, status]);

  useEffect(() => {
    if (["complete", "cancelled"].includes(status) && videoRoom?.name) {
      videoRoom.disconnect();
    }
  }, [status]);

  useEffect(() => {
    room?.ref.update({
      online: firebase.firestore.FieldValue.serverTimestamp(),
      video: isVideoEnabled
        ? firebase.firestore.FieldValue.serverTimestamp()
        : null,
      audio: isAudioEnabled
        ? firebase.firestore.FieldValue.serverTimestamp()
        : null,
    });
    const inter = setInterval(() => {
      room?.ref.update({
        online: firebase.firestore.FieldValue.serverTimestamp(),
        video: isVideoEnabled
          ? firebase.firestore.FieldValue.serverTimestamp()
          : null,
        audio: isAudioEnabled
          ? firebase.firestore.FieldValue.serverTimestamp()
          : null,
      });
    }, 15000);
    return () => {
      clearInterval(inter);
    };
  }, [!!room, isVideoEnabled]);

  const setReady = () => {
    setLocalReady(true);
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${id}`)
      .update({ status: "ready" });
  };

  const artistName = event?.artist;

  const liveAndReady =
    ["ready", "live"].includes(status) &&
    position < 3 &&
    localReady &&
    !eventSettings?.paused;

  const updatedTheme = {
    ...theme,
    dark: liveAndReady,
  };

  const waitData = {
    artistName: artistName,
    isDark: liveAndReady,
    time: waitTime,
    userName: name,
    isPaused: !!eventSettings?.paused,
  };

  if (status === "complete") {
    return (
      <ChatProvider userId={id} isFan>
        <PostCall />
      </ChatProvider>
    );
  }

  if ((position < 1 && liveAndReady) || status === "live") {
    let isReady = !load && status === "live";
    return (
      <InCall position={position} isReady={isReady} room={room}>
        <NetworkQualityUploader />
        {videoRoom?.name ? (
          <LiveRoom isFan status={status} roomId={id} />
        ) : (
          <Preview hideControls noDeviceSelect isFan />
        )}
      </InCall>
    );
  }

  let isModalOpen = [
    "chat",
    "troubleshoot",
    "phone-add",
    "phone-edit",
  ].includes(view);

  const scrollToTroubleshoot = () =>
    troubleshootPosition?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  return (
    <ThemeProvider theme={updatedTheme}>
      <NetworkQualityUploader />
      <ChatProvider userId={id} isFan>
        {!isMobile && <StyledHeader onHelp={() => scrollToTroubleshoot()} />}
        <ScrollContainer
          mobileHeight={window.innerHeight}
          modalOpen={isModalOpen}
        >
          {(view === "chat" || !isMobile) && (
            <ChatContainer>
              {!isMobile && <EventBanner />}
              {isMobile ? (
                <NavBarWrapper
                  title="Chat"
                  onBack={() => setView("video")}
                  content={<StyledChat />}
                />
              ) : (
                <StyledChat />
              )}
            </ChatContainer>
          )}
          {(view === "video" || !isMobile) && (
            <Wrapper>
              <MediaContainer>
                {!isMobile && (
                  <Connection strength={room?.data()?.connectionQuality} />
                )}

                <div style={{ position: "relative" }}>
                  {isMobile && (
                    <>
                      <MobileHeader onHelp={() => setView("troubleshoot")} />
                      {status === "ready" ? (
                        <MobileContainer>
                          <Footnote>{informationalMessage}</Footnote>
                          <WaitingTimeMobile
                            data={waitData}
                            onTextAlert={() => setView("phone-add")}
                            onEdit={() => setView("phone-edit")}
                          />
                        </MobileContainer>
                      ) : (
                        <CheckIn
                          handleHelp={() => setView("troubleshoot")}
                          onReady={setReady}
                          isMobile
                        />
                      )}
                    </>
                  )}
                  {((isMobile && status === "ready") || !isMobile) && (
                    <VideoPadding pending={status === "pending"}>
                      <Preview
                        isFan
                        onTroubleshoot={
                          !isMobile
                            ? () => scrollToTroubleshoot()
                            : () => setView("troubleshoot")
                        }
                        noDeviceSelect
                      />
                    </VideoPadding>
                  )}
                </div>
              </MediaContainer>

              {!isMobile && (
                <WaitingContainer>
                  {status === "ready" ? (
                    <>
                      <Footnote>{informationalMessage}</Footnote>
                      <WaitingTime data={waitData} />
                    </>
                  ) : (
                    <CheckIn
                      handleHelp={scrollToTroubleshoot}
                      onReady={setReady}
                    />
                  )}
                </WaitingContainer>
              )}
            </Wrapper>
          )}
          {(view === "troubleshoot" || !isMobile) && (
            <>
              {isMobile ? (
                <NavBarWrapper
                  title="Troubleshoot"
                  onBack={() => setView("video")}
                  content={
                    <>
                      {status !== "pending" && (
                        <Connection
                          strength={room?.data()?.connectionQuality}
                          style={{ height: "48px" }}
                        />
                      )}
                      <Troubleshoot />
                    </>
                  }
                />
              ) : (
                <Troubleshoot onRef={setTroubleshootPosition} />
              )}
            </>
          )}
          {(view === "phone-add" || view === "phone-edit") && isMobile && (
            <NavBarWrapper
              title={
                view === "phone-add" ? "Get Text Alerts" : "Edit Phone Number"
              }
              onBack={() => setView("video")}
              content={
                <TextUpdates
                  mobileEdit
                  onSuccess={() => setView("video")}
                  style={{ padding: "24px" }}
                />
              }
            />
          )}
          {isMobile && status !== "live" && view === "video" && (
            <MobileNav view={view} setView={setView} />
          )}
        </ScrollContainer>
      </ChatProvider>
    </ThemeProvider>
  );
});

const StyledHeader = styled(PageHeader)`
  @media only screen and ${(props) => props.theme.media.large} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 501;
  }
`;

const VideoPadding = styled(GridColumn)`
  padding: ${(props) => (props.pending ? "16px" : "54px")} 24px 16px 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 0;
  }
`;

const MobileContainer = styled(GridColumn)`
  background-color: ${(props) =>
    props.theme.dark
      ? props.theme.palette.black
      : props.theme.palette.gray.lightest};
  padding: 8px 24px 0 24px;
  transition: background-color 2.5s ease;
  & > ${Footnote} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    transition: color 2.5s ease;
  }
`;

const ScrollContainer = styled(GridColumn)`
  background-color: ${(props) => (props.theme.dark ? "#000000" : "#FFFFFF")};
  transition: background-color 2.5s ease;
  height: ${(props) => (props.modalOpen ? `${props.mobileHeight}px` : "100%")};
  @media only screen and ${(props) => props.theme.media.large} {
    height: calc(100vh - 56px);
    overflow-y: scroll;
    padding-left: 392px;
    margin-top: 56px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 44px;
    flex: 1 0 auto;
  }
`;

const MediaContainer = styled(GridColumn)`
  margin: 0 auto;
  padding-bottom: 40px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 1 1 392px;
    min-width: 0;
    max-width: 65vh;
    padding-bottom: 0;
    margin: 0 auto;
    height: auto;
  }
`;

const StyledChat = styled(ChatBox)`
  border-right: 1px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  border-left: 1px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
`;

const ChatContainer = styled(GridColumn)`
  background-color: ${(props) =>
    props.theme.dark ? props.theme.palette.black : "#F5F5F5"};
  flex: 1 1 100%;
  transition: background-color 2.5s ease;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 1 0 100%;
    height: calc(100vh - 56px);
    width: 392px;
    position: fixed;
    top: 56px;
    left: 0;
  }
`;

const WaitingContainer = styled(GridColumn)`
  flex: 0 0 392px;
  margin-left: 44px;
  margin-right: 44px;
  & > ${Footnote} {
    color: ${(props) => props.theme.palette.black};
    padding: 8px 16px;
    background-color: ${(props) => props.theme.palette.yellow.lighter};
    border-radius: 8px 8px 0 0;
    display: none;
    @media only screen and ${(props) => props.theme.media.large} {
      display: flex;
    }
  }
`;
