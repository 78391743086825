import React from "react";
import styled from "styled-components";
import { GridColumn, withDefaultMedia } from "notes";
import { StepCards } from "./StepCards";
import { StepBullets } from "./StepBullets";
import { useCompositions } from "components";

export const CloudTracker = withDefaultMedia(({ matches }) => {
  const { status, progress } = useCompositions();

  const steps = [
    {
      status: status === "enqueued" ? "active" : "complete",
      label: "In Queue",
      content: "Your video is uploaded and in line to be transcoded.",
      disabled:
        status === "composition-available" || status === "composition-progress"
          ? true
          : false,
    },
    {
      status:
        status === "composition-available" || progress > 99
          ? "complete"
          : status === "composition-progress"
          ? "active"
          : "",
      label: "Transcoding",
      content: "Your video is currently being formatted for quality.",
      progress: progress ? Math.round(progress) : 0,
      disabled: status === "composition-available",
    },
    {
      status:
        status === "composition-available" || progress > 99 ? "active" : "",
      label: "Finalizing",
      content: "Your transcoded video and photo(s) are being uploaded.",
    },
  ];

  if (status === "composition-available") {
    return "";
  }

  return (
    <Container>
      {matches.large ? (
        <StepCards steps={steps} />
      ) : (
        <StepBullets vertical steps={steps} />
      )}
    </Container>
  );
});

const Container = styled(GridColumn)`
  margin: 8px 0 16px 0;
  @media only screen and ${(props) => props.theme.media.large} {
    margin: 40px 0 56px 0;
  }
`;
