import React, { useContext } from "react";
import { Link, GridRow, Icon } from "notes";
import styled from "styled-components";
import { ChatContext, useMessageSounds } from "components";

export const MobileNav = ({ setView, postCall }) => {
  const { unreadFanChats } = useContext(ChatContext);
  const unreadChatsLength = unreadFanChats?.docs?.length ?? 0;
  useMessageSounds(unreadChatsLength);
  return (
    <Container>
      <Option active={!!unreadChatsLength > 0} onClick={() => setView("chat")}>
        <Icon tertiary name="Message" /> Chat{" "}
        {unreadChatsLength > 0 ? (
          <>
            <Notification>
              ({unreadChatsLength})<Pulse />
            </Notification>
          </>
        ) : (
          ""
        )}
      </Option>
      {/* {!postCall && (
        <Option onClick={() => setView("troubleshoot")}>
          <Icon tertiary name="Gear" /> Troubleshoot
        </Option>
      )} */}
    </Container>
  );
};

const Notification = styled(GridRow)`
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: relative;
  color: ${(props) => props.theme.palette.orange.primary};
  margin-left: 6px;
`;

const Container = styled(GridRow)`
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 1000;
`;

const Option = styled(Link)`
  background-color: ${(props) => props.theme.palette.black};
  color: #ffffff;
  flex: 1 0 50%;
  height: 40px;
  justify-content: center;
  &:hover,
  &:focus {
    color: #ffffff;
  }
  & + & {
    border-left: 1px solid ${(props) => props.theme.palette.gray.primary};
  }

  svg {
    margin-right: 9px;
    path {
      fill: #ffffff;
    }
  }
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 40px;
  width: 40px;
  animation: pulse-orange 2s infinite;
  z-index: 1;

  @keyframes pulse-orange {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(255, 140, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
`;
