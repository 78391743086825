import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, Subheader, H3, P, GridRow, Icon } from "notes";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase, ManagerModalContext } from "components";

export const CallDetails = ({ userInfo, roomId, artist = false, ...props }) => {
  const { setManagerModal } = useContext(ManagerModalContext);
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${roomId}`)
  );
  return (
    <Container {...props}>
      <OnCall>
        <Icon form name="VideoChat" /> <Subheader>On call</Subheader>
      </OnCall>
      <H3>{displayname?.value}</H3>
      {!artist && (
        <UserInfo yCenter>
          <P>
            {userInfo?.userLocation}
            {!!userInfo?.userLocation && userInfo?.userBio && ` • `}
            {userInfo?.userBio}
          </P>
          <Icon
            name="Pencil"
            onClick={() =>
              setManagerModal({
                userBio: { id: roomId, name: displayname?.value },
              })
            }
          />
        </UserInfo>
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  ${H3}, ${P} {
    color: #ffffff;
  }
  ${H3} {
    margin-bottom: ${(props) => (props.theme.isArtist ? 0 : "8px")};
  }
`;

const OnCall = styled(GridRow)`
  margin-bottom: ${(props) => (props.theme.isArtist ? "4px" : "8px")};
  svg {
    path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
  ${Subheader} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-weight: 700;
    margin-left: 9px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 4px;
  }
`;

const UserInfo = styled(GridRow)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.primary};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
  margin-bottom: 12px;
  padding: 8px 0;
  ${P} {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    margin: 0 11px;
    &:hover,
    &:focus {
      path {
        fill: #ffffff;
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) => !props.theme.isArtist && "min-height: 62px;"};
    ${P} {
      white-space: initial;
      overflow: visible;
    }
  }
`;
