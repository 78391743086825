import React from "react";
import styled from "styled-components";

export const ProgressRingSmall = ({ progress = 0 }) => {
  const stroke = 2;
  const radius = 12;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Wrapper>
      <Container>
        <svg height={radius * 2} width={radius * 2}>
          <SolidCircle
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <Circle
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            circumference={circumference}
            offset={strokeDashoffset}
          />
        </svg>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  width: 16px;
  height: 16px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: -4px;
  position: relative;
`;

const Circle = styled.circle`
  stroke-dasharray: ${(props) =>
    `${props.circumference} ${props.circumference}`};
  stroke-dashoffset: ${(props) => props.offset};
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: unset;
  stroke: ${(props) => props.theme.palette.purple.primary};
`;

const SolidCircle = styled.circle`
  stroke: ${(props) => props.theme.palette.purple.lightest};
  transform-origin: center;
`;
