import React, { useState, useContext } from "react";
import { ChatContext } from "components";
import { TextArea, Label } from "notes";
import { Actions, CancelButton, SubmitButton } from "./UserBio";

export const GlobalMessage = ({ onComplete }) => {
  const { sendGlobalMessage } = useContext(ChatContext);
  const [value, setValue] = useState("");
  const handleConfirm = () => {
    sendGlobalMessage(value);
    onComplete();
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <Label>Message</Label>
      <TextArea
        placeholder="Send a message to all fans in queue..."
        value={value}
        onChange={handleChange}
      />
      <Actions>
        <CancelButton onClick={onComplete}>Cancel</CancelButton>
        <SubmitButton onClick={handleConfirm}>Send</SubmitButton>
      </Actions>
    </>
  );
};
