import styled from "styled-components";
import { ButtonPrimary } from "notes";
export const ButtonWhite = styled(ButtonPrimary)`
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  color: ${(props) => props.theme.palette.black};
  svg {
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
  &:hover,
  &:focus {
    background-color: #ffffff;
  }
`;
