import React from "react";
import { H3, Logo } from "notes";
import styled from "styled-components";

export const Duplicate = () => {
  return (
    <Container>
      <Logo
        name="SetLiveDefault"
        style={{ display: "block", marginBottom: "40px" }}
      />
      <H3>
        We've detected you've activated your session elsewhere, you may close
        this tab/window now.
      </H3>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px;
  flex-direction: column;
`;
