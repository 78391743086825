import React from "react";
import { ThemeProvider } from "styled-components";
import {
  TimeProvider,
  EventProvider,
  UserProvider,
  VideoProvider,
  MessageProvider,
  useConnectionOptions,
  AudioOutput,
  DuplicateLoginHandler,
} from "components";
import { Routes } from "./Routes";
import { theme } from "./theme";
import AppStateProvider from "./state";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { UserProvider as UserAuthProvider } from "auth";

function App() {
  const connectionOptions = useConnectionOptions();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <UserAuthProvider>
          <AppStateProvider>
            <VideoProvider options={connectionOptions} onError={console.error}>
              <UserProvider>
                <TimeProvider>
                  <EventProvider>
                    <DuplicateLoginHandler />
                    <MessageProvider>
                      <AudioOutput />
                      <Route component={Routes} path={"/:eventId"} />
                    </MessageProvider>
                  </EventProvider>
                </TimeProvider>
              </UserProvider>
            </VideoProvider>
          </AppStateProvider>
        </UserAuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
