import { useContext } from "react";
import { firebase, EventContext, TimeContext } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { DateTime, Duration } from "luxon";

export const useTimeRemaining = ({ room }) => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const [mgEvent, load, err] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event?.id}`)
  );

  if (!room) {
    return { timeRemaining: "" };
  }

  const sessionDuration =
    room.data().sessionDuration || event?.sessionDuration || 90;
  const endTime = room.data().startTime
    ? DateTime.fromJSDate(room.data().startTime.toDate()).plus({
        seconds: sessionDuration,
      })
    : null;

  const { minutes, seconds } =
    time < endTime &&
    Duration.fromMillis(endTime - time).shiftTo(
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timeString = `${minutes < 10 ? "0" : ""}${minutes || 0}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  return { timeRemaining: timeString, endTime, sessionDuration };
};
