import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  ButtonPrimary,
  GridRow,
  GridColumn,
  Link,
  Footnote,
  Icon,
} from "notes";
import { ReactComponent as Microphone } from "Images/MicrophoneTertiary.svg";
import { AudioIndicator } from "./AudioIndicator";
import { VideoContext } from "components";

export const AudioTester = ({
  onComplete,
  onCancel,
  state: parentState = "initial",
  setState: setParentState,
}) => {
  const [state, setState] = useState(parentState);
  const [audioRecorder, setAudioRecorder] = useState();
  const [audioElement, setAudioElement] = useState();
  const [played, setPlayed] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState(0);
  const [ready, setReady] = useState(false);
  const { localTracks } = useContext(VideoContext);

  const handleStart = () => {
    audioRecorder.start();
    setTimeout(() => {
      if (audioRecorder.state !== "inactive") {
        audioRecorder?.stop();
      }
    }, 5000);
  };

  const handleStop = () => {
    audioRecorder?.stop();
  };

  useEffect(() => {
    const track = localTracks.find((t) => t.kind === "audio");
    const stream = new MediaStream();
    stream.addTrack(track.mediaStreamTrack);
    setAudioRecorder(new MediaRecorder(stream));
    return () => {
      if (typeof audioElement?.stop === "function") {
        audioElement?.stop();
      }
      setAudioElement(undefined);
    };
  }, []);

  useEffect(() => {
    let cancelled = false;
    if (audioRecorder) {
      if (cancelled) {
        return;
      }
      if (state === "initial") {
        handleStart();
      }
      audioRecorder.onstart = () => {
        if (cancelled) {
          return;
        }
        setState("recording");
        if (played === true) {
          setAudioElement();
          setPlayed(false);
        }
      };
      audioRecorder.onstop = () => {
        if (cancelled) {
          return;
        }
        if (state === "recording") {
          setState("recorded");
          onComplete(true);
        }
      };
      audioRecorder.ondataavailable = (e) => {
        if (cancelled) {
          return;
        }
        let chunks = [];
        chunks.push(e.data);
        var blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        var audioURL = URL.createObjectURL(blob);
        var audioElement = new Audio(audioURL);
        setAudioElement(audioElement);
      };
    }
    return () => {
      cancelled = true;
    };
  }, [audioRecorder, state]);

  useEffect(() => {
    let cancelled = false;
    if (audioElement) {
      // audio playback ended
      audioElement.onended = () => {
        if (cancelled) {
          return;
        }
        setDisabled(false);
      };
      // audio metadata loaded
      audioElement.onloadeddata = () => {
        if (cancelled) {
          return;
        }
        setReady(true);
      };
      audioElement.ontimeupdate = () => {
        if (cancelled) {
          return;
        }
        setProgress(
          Math.round((audioElement.currentTime / audioElement.duration) * 100)
        );
      };
    }
    return () => {
      if (audioElement) {
        audioElement.src = "";
        audioElement?.remove();
      }
      cancelled = true;
    };
  }, [audioElement]);

  useEffect(() => {
    if (!played && ready && audioElement) {
      // play audio
      audioElement?.play();
      setDisabled(true);
      setPlayed(true);
    }
  }, [ready, played, audioElement]);

  return (
    <Wrapper xCenter yCenter>
      {state === "recording" && (
        <>
          <AudioIndicator />
          <RecordButtonContainer style={{ marginTop: "-16px" }} xCenter yCenter>
            <RecordButton name="stop" onClick={handleStop}>
              <Square />
            </RecordButton>
          </RecordButtonContainer>
        </>
      )}
      {state === "recorded" && (
        <ProgressContainer yCenter>
          <ReplayButton
            alt="replay"
            disabled={disabled}
            name="Refresh"
            onClick={() => (!disabled ? setPlayed(false) : {})}
          />
          <ProgressBackground>
            <ProgressPercentage percentage={progress} />
          </ProgressBackground>
        </ProgressContainer>
      )}
      {state !== "recorded" && (
        <CancelLink
          onClick={() => {
            onCancel();
            setState("initial");
          }}
        >
          Cancel
        </CancelLink>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  width: 100%;
  position: relative;
  margin-top: 16px;
`;

const RecordButtonContainer = styled(GridRow)`
  width: 120px;
  height: 120px;
`;

const RecordButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.red.primary};
  border-radius: 50%;
  min-width: 40px;
  width: 40px;
  padding: 12px 0;
  position: relative;
  z-index: 2;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.red.primary};
  }
`;

const CancelLink = styled(Link)`
  padding: 9px 12px;
  margin-top: 8px;
`;

const Square = styled.div`
  border-radius: 2px;
  background-color: #ffffff;
  width: 14px;
  height: 14px;
`;

const ProgressContainer = styled(GridRow)`
  height: 40px;
  width: 100%;
  max-width: 278px;
`;

const ReplayButton = styled(Icon)`
  cursor: pointer;
  margin-right: 10px;
  flex-shrink: 0;
  path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.palette.gray.lighter
        : props.theme.palette.gray.primary};
  }
`;

const ProgressBackground = styled.div`
  display: flex;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.red.lighter};
  position: relative;
  z-index: 0;
`;

const ProgressPercentage = styled.div`
  height: 4px;
  width: ${(props) => props.percentage}%;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.red.primary};
  position: relative;
  margin: -1px 0;
  transition: ${(props) =>
    props.percentage > 0 ? "all 500ms ease-out" : "none"};
  z-index: 0;
`;
