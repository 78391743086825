import { useEffect, useState } from "react";
import { useVideoContext } from "components";

export const useParticipants = () => {
  const { room } = useVideoContext();

  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (!room) return;
    if (!!room && room.participants) {
      setParticipants(Array.from(room.participants.values()));
    }
    const participantConnected = (participant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );

    const updateParticipants = () => setParticipants((prev) => [...prev]);

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on("trackPublished", () => updateParticipants);
    room.on("trackUnpublished", () => updateParticipants);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
      room.off("trackPublished", () => updateParticipants);
      room.off("trackUnpublished", () => updateParticipants);
    };
  }, [room]);

  return participants;
};
