import React, { createContext, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase } from "components";
import { useRouteMatch } from "react-router";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;
export const EventProvider = ({ children }) => {
  const res = useRouteMatch(`/:eventId`);
  const eventId = res?.params?.eventId;
  const [e, l, er] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${eventId}`)
  );

  const [event, loading, error] = useDocumentData(
    firebase.firestore().doc(`meet_greet_events/${e?.eventId}`)
  );

  useEffect(() => {
    localStorage.setItem("eventId", eventId);
  }, [eventId]);

  if (loading) {
    return null; //TODO: loading strategy
  }

  const fullEvent = {
    id: eventId,
    parentId: e?.eventId,
    started: e?.started,
    ...event,
    start: DateTime.fromJSDate(event?.startDate.toDate()),
    end: DateTime.fromJSDate(event?.endDate.toDate()),
  };
  return (
    <EventContext.Provider value={{ event: fullEvent, loading, error }}>
      {children}
    </EventContext.Provider>
  );
};
