import React, { useContext } from "react";
import { ManagerModalContext } from "components";
import { ButtonSecondary, ButtonPrimary, GridRow, H3, P } from "notes";

export const ConfirmStepIn = ({ onComplete }) => {
  const {
    managerModal: { confirmStepIn },
  } = useContext(ManagerModalContext);
  const handleConfirm = () => {
    confirmStepIn();
    onComplete();
  };
  return (
    <>
      <H3 style={{ textAlign: "center" }}>Step In</H3>
      <P style={{ textAlign: "center", marginTop: "16px" }}>
        Are you sure you want to temporarily step in for the artist?
      </P>
      <GridRow xCenter style={{ marginTop: "56px" }}>
        <ButtonSecondary onClick={onComplete}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={handleConfirm} style={{ marginLeft: "24px" }}>
          Confirm
        </ButtonPrimary>
      </GridRow>
    </>
  );
};
