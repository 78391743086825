import { LocalAudioTrack } from "twilio-video";
import { useCallback, useRef } from "react";
import useIsTrackEnabled from "../useIsTrackEnabled/useIsTrackEnabled";
import useVideoContext from "../useVideoContext/useVideoContext";

export default function useLocalAudioToggle() {
  const {
    room,
    localTracks,
    removeLocalAudioTrack,
    getLocalAudioTrack,
    onError,
  } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const audioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;
  const publishing = useRef(false);

  const toggleAudioEnabled = useCallback(
    (enable?) => {
      if (!publishing.current) {
        if (enable !== undefined ? !enable : audioTrack) {
          const localTrackPublication = localParticipant?.unpublishTrack(
            audioTrack
          );
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit("trackUnpublished", localTrackPublication);
          removeLocalAudioTrack();
        } else {
          if (audioTrack) {
            localParticipant?.publishTrack(audioTrack, { priority: "high" });
            publishing.current = false;
            return;
          }
          publishing.current = true;
          getLocalAudioTrack()
            .then((track: LocalAudioTrack) =>
              localParticipant?.publishTrack(track, { priority: "high" })
            )
            .catch(onError)
            .finally(() => {
              publishing.current = false;
            });
        }
      }
    },
    [
      audioTrack,
      localParticipant,
      getLocalAudioTrack,
      onError,
      removeLocalAudioTrack,
    ]
  );

  return [!!audioTrack, toggleAudioEnabled] as const;
}
