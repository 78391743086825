import React, { useContext } from "react";
import { ButtonPrimary } from "notes";
import styled from "styled-components";
import { VideoContext, EventContext, firebase } from "components";

export const ArtistUnmute = ({ liveRoom }) => {
  const { unMute, room } = useContext(VideoContext);
  const { event } = useContext(EventContext);
  const handleUnmute = () => {
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${liveRoom}`)
      .update({
        startTime: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };
  const handleLeave = (complete) => {
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${liveRoom}`)
      .update({ status: complete ? "complete" : "ready" });
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}`)
      .update({ liveRoom: null });
  };
  const tracks = room?.localParticipant?.videoTracks;
  let live = false;
  if (tracks) {
    tracks.forEach((t) => {
      if (t?.track?.isEnabled) {
        live = true;
      }
    });
  }

  if (!live) {
    return (
      <Container>
        <ButtonPrimary onClick={handleUnmute}>Join Call</ButtonPrimary>
        <ButtonPrimary onClick={() => handleLeave(false)}>
          Back to Lobby
        </ButtonPrimary>
        <ButtonPrimary onClick={() => handleLeave(true)}>
          Leave Call
        </ButtonPrimary>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 200px;
  z-index: 10;
`;
