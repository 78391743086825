import React, { useContext, useState, useRef, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import useResizeObserver from "@react-hook/resize-observer";
import Participant from "components/Participant/Participant";
import {
  useVideoContext,
  useParticipants,
  firebase,
  NoVideoInner,
  LocalAvatar,
  ParticipantAvatar,
  ChatContext,
} from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { P, Subheader, GridColumn } from "notes";

export const SmallVideo = styled.div`
  position: relative;
  display: flex;
`;

export const ParticipantStrip = ({ isArtist }) => {
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const [size, setSize] = useState();
  const { user } = useContext(ChatContext);
  const target = useRef();

  useEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target, localParticipant]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  const participants = useParticipants();

  let videoParticipants = [];
  let otherParticipants = [];

  participants.map((p) => {
    if (Array.from(p.tracks.values()).find((t) => t.kind === "video")) {
      videoParticipants.push(p);
    } else {
      otherParticipants.push(p);
    }
  });

  const tiles =
    1 + videoParticipants.length + (otherParticipants.length > 0 ? 1 : 0);

  if (!localParticipant) {
    return null;
  }

  let width = 0;
  let height = 0;

  if (size?.width < size?.height) {
    let tilesPerRow = Math.ceil(tiles / Math.floor(size?.height / size?.width));
    width = size?.width / tilesPerRow;
    height = width;
  } else {
    let tilesPerColumn = Math.ceil(
      tiles / Math.floor(size?.width / size?.height)
    );
    height = size?.height / tilesPerColumn;
    width = height;
  }

  return (
    <Wrapper ref={target} cardwidth={`${width}px`} cardheight={`${height}px`}>
      {Array.from(localParticipant.tracks.values()).find(
        (t) => t.kind === "video"
      ) ? (
        <SmallVideo key={localParticipant.sid}>
          <Participant isLocal={true} participant={localParticipant} />
        </SmallVideo>
      ) : (
        <SmallVideo key={localParticipant.sid}>
          <NoVideo>
            <NoVideoInner xCenter yCenter>
              <StyledLocalAvatar isArtist={isArtist} />
            </NoVideoInner>
          </NoVideo>
        </SmallVideo>
      )}
      {videoParticipants.map((participant) => (
        <SmallVideo key={participant.sid}>
          <Participant key={participant.sid} participant={participant} />
        </SmallVideo>
      ))}
      {!!otherParticipants.length && (
        <SmallVideo key={"otherpartic"}>
          <ParticipantCard participants={otherParticipants} />
        </SmallVideo>
      )}
    </Wrapper>
  );
};

const ParticipantCard = ({ participants }) => {
  return (
    <Card>
      <Subheader>Also On This Call</Subheader>
      {participants.map((p) => (
        <>
          <ParticipantDetail participant={p} />
        </>
      ))}
    </Card>
  );
};

const ParticipantDetail = ({ participant }) => {
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${participant?.identity}`)
  );
  return (
    <ParticipantName style={{ color: "#fff" }}>
      {displayname?.value}
    </ParticipantName>
  );
};

export const StyledParticipantAvatar = styled(ParticipantAvatar)`
  && {
    width: 84px;
    height: 84px;
    font-size: 32px;
    line-height: 62px;
  }
`;

export const StyledLocalAvatar = styled(LocalAvatar)`
  && {
    width: 84px;
    height: 84px;
    font-size: 32px;
    line-height: 62px;
  }
`;

const ParticipantName = styled(P)`
  color: #ffffff;
  font-size: 15px;
  padding: 8px 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
`;

const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #222;
  padding: 20px;
  ${Subheader} {
    margin-bottom: 8px;
  }
`;

const NoVideo = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.black};
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    padding-top: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${SmallVideo} {
    ${(props) => props.cardwidth && `width: ${props.cardwidth};`}
    ${(props) => props.cardheight && `height: ${props.cardheight};`}
  }
`;
