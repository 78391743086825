import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, Spinner } from "components";
import { GridColumn, GridRow, H2, H3, P } from "notes";
import { TextUpdates } from "./TextUpdates";

export const WaitingTime = ({ data, ...props }) => {
  const { isDark, time, artistName, userName, isPaused } = data;
  const { event } = useContext(EventContext);

  return (
    <Container {...props}>
      <Content xCenter>
        {isPaused ? (
          <LoadingLabel>
            <StyledSpinner />
            The event will {event?.started ? "resume" : "start"} soon...
          </LoadingLabel>
        ) : (
          <>
            <Time xCenter yEnd>
              <H2>{time}</H2> <P>min</P>
            </Time>
            <LoadingLabel>
              <StyledSpinner /> Until your turn...
            </LoadingLabel>
          </>
        )}
        <Divider />
        <H3>{userName}</H3>
        {isDark ? (
          <ReadyMessage>
            Get ready! It’s almost your turn to join the Meet &amp; Greet with{" "}
            {artistName}...
            <br />
            <br />
            Make sure your microphone and camera are turned on and you’re ready
            for the call. If your camera or microphone are off when we pull you
            into the call, we won’t be able to connect and you will get slotted
            lower in the queue.
          </ReadyMessage>
        ) : (
          <ShadowCard style={{ marginTop: "24px" }}>
            <TextUpdates />
          </ShadowCard>
        )}
      </Content>
    </Container>
  );
};

const StyledSpinner = styled(Spinner)`
  margin-right: 12px;
`;

const Container = styled(GridColumn)`
  background-color: ${(props) =>
    props.theme.dark ? props.theme.palette.black : "#F5F5F5"};
  border-radius: 0 0 8px 8px;
  transition: background-color 2.5s ease;
`;

const Content = styled(GridColumn)`
  padding: 24px;
  width: 100%;
  ${H3} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
  }
  transition: background-color 2.5s ease;
`;

const LoadingLabel = styled(P)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  font-weight: 600;
  transition: background-color 2.5s ease;
`;

const Time = styled(GridRow)`
  margin-bottom: 8px;
  transition: background-color 2.5s ease;
  ${H2} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    font-size: 56px;
    line-height: 50px;
    margin-right: 12px;
  }
  ${P} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    font-size: 25px;
    line-height: 29px;
  }
`;

const Divider = styled.div`
  background-color: ${(props) =>
    props.theme.dark
      ? props.theme.palette.gray.primary
      : props.theme.palette.gray.lightest};
  display: block;
  margin: 24px 0;
  width: 100%;
  height: 2px;
  transition: background-color fade 2.5s;
`;

export const ShadowCard = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 12px 16px 20px 16px;
`;

const ReadyMessage = styled(P)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  margin-top: 24px;
`;
