import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useVideoContext } from "components/useVideoContext";
import { useMediaStreamTrack } from "components/useMediaStreamTrack";
import { DEFAULT_VIDEO_CONSTRAINTS } from "components/const";
import { ReactComponent as Microphone } from "Images/Microphone.svg";
import { GridColumn, GridRow, Select, Icon } from "notes";

export const useDevices = () => {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => setDevices(devices));
    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return devices;
};

export const DeviceSelector = ({ videoOnly, audioOnly }) => {
  const devices = useDevices();
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks.find((track) => track.kind === "video");
  const videoMediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = videoMediaStreamTrack?.getSettings().deviceId;

  const localAudioTrack = localTracks.find((track) => track.kind === "audio");
  const audioMediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = audioMediaStreamTrack?.getSettings().deviceId;

  const replaceVideoTrack = async (newDeviceId) => {
    localVideoTrack?.restart({
      ...DEFAULT_VIDEO_CONSTRAINTS,
      deviceId: { exact: newDeviceId },
    });
  };

  function replaceAudioTrack(newDeviceId) {
    localAudioTrack?.restart({
      ...DEFAULT_VIDEO_CONSTRAINTS,
      deviceId: { exact: newDeviceId },
    });
  }

  const audioIn = devices.filter((device) => device.kind === "audioinput");
  const videoIn = devices.filter((device) => device.kind === "videoinput");

  const audioOptions = audioIn.map((inp) => ({
    text: inp.label,
    id: inp.deviceId,
  }));

  const videoOptions = videoIn.map((inp) => ({
    text: inp.label,
    id: inp.deviceId,
  }));

  if (videoOnly) {
    return (
      <Container>
        <SelectVideo
          icon={
            <Label yCenter>
              <Icon form name="Video" /> Video:
            </Label>
          }
          options={videoOptions}
          selected={videoOptions.find((v) => v.id === localVideoInputDeviceId)}
          onChange={(o) => replaceVideoTrack(o.id)}
        />
      </Container>
    );
  }

  if (audioOnly) {
    return (
      <Container>
        <SelectMicrophone
          icon={
            <Label yCenter>
              <Microphone /> Microphone:
            </Label>
          }
          options={audioOptions}
          selected={audioOptions.find((v) => v.id === localAudioInputDeviceId)}
          onChange={(o) => replaceAudioTrack(o.id)}
        />
      </Container>
    );
  }

  return (
    <Container>
      <SelectVideo
        icon={
          <Label yCenter>
            <Icon form name="Video" /> Video:
          </Label>
        }
        options={videoOptions}
        selected={videoOptions.find((v) => v.id === localVideoInputDeviceId)}
        onChange={(o) => replaceVideoTrack(o.id)}
      />
      <Margin>
        <SelectMicrophone
          icon={
            <Label yCenter>
              <Microphone /> Microphone:
            </Label>
          }
          options={audioOptions}
          selected={audioOptions.find((v) => v.id === localAudioInputDeviceId)}
          onChange={(o) => replaceAudioTrack(o.id)}
        />
      </Margin>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 16px;
`;

const Margin = styled(GridColumn)`
  margin-top: 8px;
`;

const SelectVideo = styled(Select)`
  cursor: default;
  padding-left: 94px;
`;

const SelectMicrophone = styled(Select)`
  cursor: default;
  padding-left: 140px;
`;

const Label = styled(GridRow)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    margin-right: 12px;
  }
`;
