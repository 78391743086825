import { firebase } from "components";

export const useApi = () => {
  const { functions } = firebase;

  const getRoomToken = (identity, room) =>
    functions().httpsCallable("meetgreet-token")({ identity, room });

  const createRoom = (eventId, fan) =>
    functions().httpsCallable("meetgreet-room")({ eventId, fan });

  return {
    getRoomToken,
    createRoom,
  };
};
