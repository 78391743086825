import React from "react";
import styled from "styled-components";
import { GridRow, Link, Icon, GridColumn } from "notes";

export const NavBarWrapper = ({ content, onBack, title, ...props }) => {
  return (
    <Container stretch {...props}>
      <MobileChatHeader>
        <BackButton onClick={onBack}>
          <Icon name="Left" /> {title}
        </BackButton>
      </MobileChatHeader>
      <Content stretch>{content}</Content>
    </Container>
  );
};

const Content = styled(GridColumn)`
  margin-top: 48px;
`;

const Container = styled(GridColumn)`
  background-color: ${(props) =>
    props.theme.dark ? props.theme.palette.black : "#ffffff"};
  transition: background-color 2.5s ease;
`;

const BackButton = styled(Link)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  transition: color 2.5s ease;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    text-decoration: none;
  }

  svg {
    margin-right: 12px;
    path {
      fill: ${(props) =>
        props.theme.dark ? "#ffffff" : props.theme.palette.gray.primary};
    }
  }
`;

const MobileChatHeader = styled(GridRow)`
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.theme.dark ? props.theme.palette.black : "#ffffff"};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  padding: 0 24px 0 12px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 48px;
`;
