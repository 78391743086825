import firebase from "components/Firebase";
import { UserContext, EventContext } from "components";
import { useContext, useEffect, useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";

export const DuplicateLoginHandler = () => {
  const { user } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const history = useHistory();
  const isInit = useRef(false);
  const ref = firebase
    .firestore()
    .doc(`meet_greet_active_users/${event?.id}#${user?.uid}`);
  const [activeSession, l, e] = useDocumentData(ref);
  useEffect(() => {
    if (isInit.current === "pending") {
      return;
    }
    if (!isInit.current && user?.refreshToken) {
      isInit.current = "pending";
      const rand = Math.random();
      ref
        .set(
          {
            activeSession: `${user.refreshToken}#${rand}`,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          isInit.current = `${user?.refreshToken}#${rand}`;
        });
    } else {
      if (
        activeSession?.activeSession &&
        user?.refreshToken &&
        activeSession?.activeSession !== isInit.current
      ) {
        history.push(`/duplicate`);
      }
    }
  }, [activeSession?.activeSession, user?.refreshToken]);
  return null;
};
