import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, P, Icon, GridRow } from "notes";
import { ChatContext } from "components";
import { ReactComponent as InstantMessage } from "Images/InstantMessage.svg";
import { ReactComponent as InstantMessageTertiary } from "Images/InstantMessageTertiary.svg";
import { ReactComponent as VideoChatForm } from "Images/VideoChatForm.svg";

export const MobileNav = ({ setView, view }) => {
  const { user, setUser, hasUnreadTeamChats } = useContext(ChatContext);
  const handleChat = () => {
    if (!user) {
      setUser("eventmods");
    }
    setView("chat");
  };
  return (
    <Container>
      <Option active={view === "list"} onClick={() => setView("list")}>
        {view === "list" ? (
          <Icon tertiary name="Audience" />
        ) : (
          <Icon form name="Audience" />
        )}
        <P>Queue</P>
      </Option>
      <Option active={view === "video"} onClick={() => setView("video")}>
        {view === "video" ? <Icon form name="VideoChat" /> : <VideoChatForm />}
        <P>Video Calls</P>
      </Option>
      <Option
        active={view === "chat" && user === "eventmods"}
        onClick={handleChat}
      >
        {hasUnreadTeamChats && <ChatIndicator />}
        {view === "chat" ? <InstantMessageTertiary /> : <InstantMessage />}
        <P>Team Chat</P>
      </Option>
    </Container>
  );
};

const ChatIndicator = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.red.primary};
  border-radius: 5px;
  box-shadow: 0 0 4px 0 ${(props) => props.theme.palette.red.primary};
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  right: 38%;
`;

const Container = styled.div`
  flex-shrink: 0;
  height: 56px;
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: row;
  justify-content: space-around;
  background-color: #f5f5f5;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.24);
`;

const Option = styled(GridColumn)`
  cursor: pointer;
  position: relative;
  flex: 1 0 33%;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 3px;
    width: 24px;
    height: 24px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.palette.black
          : props.theme.palette.gray.lighter};
    }
  }
  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
  }
`;
