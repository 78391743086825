import React, { createContext, useState, useContext } from "react";
import {
  GlobalMessage,
  MessageFan,
  UserBio,
  ManagerModal,
  CallDuration,
  ConfirmRemove,
  ConfirmStepIn,
} from "./Components";

export const ManagerModalContext = createContext();
export const ManagerModalConsumer = ManagerModalContext.Consumer;
export const ManagerModalProvider = ({ children }) => {
  const [managerModal, setManagerModal] = useState({});

  return (
    <ManagerModalContext.Provider value={{ managerModal, setManagerModal }}>
      <CallDurationModal />
      <UserBioModal />
      <GlobalMessageModal />
      <SMSModal />
      <ConfirmRemoveModal />
      <ConfirmStepInModal />
      {children}
    </ManagerModalContext.Provider>
  );
};

//TODO: The way i built these is wrong, they should have their state inside the Modal so they can be remounted each opening.

const CallDurationModal = () => {
  const {
    managerModal: { callDuration },
    setManagerModal,
  } = useContext(ManagerModalContext);
  return (
    <ManagerModal
      open={!!callDuration}
      title="Call Duration"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    >
      <CallDuration onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};

const UserBioModal = () => {
  const {
    managerModal: { userBio },
    setManagerModal,
  } = useContext(ManagerModalContext);
  return (
    <ManagerModal
      mobileFull
      open={!!userBio}
      title="Add Fan Bio"
      onBack={() => setManagerModal({})}
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    >
      <UserBio onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};

const GlobalMessageModal = () => {
  const {
    managerModal: { globalMessage },
    setManagerModal,
  } = useContext(ManagerModalContext);

  return (
    <ManagerModal
      mobileFull
      onBack={() => setManagerModal({})}
      open={!!globalMessage}
      title="Message All Fans"
      description="Your message will be sent to all fans and show up in each individual fan's chat."
    >
      <GlobalMessage onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};

const SMSModal = () => {
  const {
    managerModal: { sms },
    setManagerModal,
  } = useContext(ManagerModalContext);
  const id = sms?.id;
  return (
    <ManagerModal
      mobileFull
      onBack={() => setManagerModal({})}
      open={!!id}
      title="Sent a Text To Fan"
      description="Your message will be delivered to the fan's mobile number. Any messages you send will also show up in the chat history."
    >
      <MessageFan onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};

const ConfirmRemoveModal = () => {
  const {
    managerModal: { confirmRemove },
    setManagerModal,
  } = useContext(ManagerModalContext);
  return (
    <ManagerModal maxMobile open={!!confirmRemove} size="small">
      <ConfirmRemove onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};

const ConfirmStepInModal = () => {
  const {
    managerModal: { confirmStepIn },
    setManagerModal,
  } = useContext(ManagerModalContext);
  return (
    <ManagerModal open={!!confirmStepIn} size="small">
      <ConfirmStepIn onComplete={() => setManagerModal({})} />
    </ManagerModal>
  );
};
