import React from "react";
import styled from "styled-components";
import { P } from "notes";
import { firebase, ConnectionIndicator } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import ParticipantTracks from "components/ParticipantTracks/ParticipantTracks";

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  isLocal = false,
  muted = false,
}) {
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${participant?.identity}`)
  );
  return (
    <ParticipantContainer id={participant.sid}>
      <ParticipantTracks
        isLocalParticipant={isLocal}
        muted={muted}
        participant={participant}
        disableAudio={disableAudio}
        enableScreenShare={enableScreenShare}
      />
      <ConnectionContainer>
        <P>{displayname?.value}</P>
        <ConnectionIndicator strength={participant.networkQualityLevel * 20}>
          {participant.networkQualityLevel}
        </ConnectionIndicator>
      </ConnectionContainer>
    </ParticipantContainer>
  );
}

const ParticipantContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ConnectionContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(34, 34, 34, 0.6);
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  transition: opacity 250ms ease;
  opacity: 0;
  ${ParticipantContainer}:hover & {
    opacity: 1;
  }
  ${P} {
    color: #fff;
  }
`;
