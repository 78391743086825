import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Icon, P, withDefaultMedia, Toggle } from "notes";
import { ReactComponent as Video } from "Images/Video.svg";
import { ReactComponent as MicrophoneMuted } from "Images/MicrophoneMuted.svg";
import { ReactComponent as Microphone } from "Images/MicrophoneTertiary.svg";

import { useLocalAudioLevel } from "components";

export const LobbyMediaActions = withDefaultMedia(
  ({
    matches,
    hideMute,
    videoMuted,
    audioMuted,
    toggleVideo,
    toggleAudio,
    isFan,
    ...props
  }) => {
    const audioLevel = useLocalAudioLevel();

    return (
      <Container xCenter yCenter {...props}>
        {matches.large ? (
          <GridRow>
            {!hideMute && (
              <>
                {!(isFan && !videoMuted) && (
                  <Action active={!videoMuted}>
                    {!videoMuted ? <Video /> : <Icon form name="VideoOff" />}
                    <Toggle checked={!videoMuted} onChange={toggleVideo} />
                  </Action>
                )}
                {!(isFan && !audioMuted) && (
                  <Action active={!audioMuted}>
                    {!audioMuted ? (
                      <>
                        <DynamicMic level={Math.abs(audioLevel - 10)} />
                        <Microphone />
                      </>
                    ) : (
                      <MicrophoneMuted />
                    )}
                    <Toggle checked={!audioMuted} onChange={toggleAudio} />
                  </Action>
                )}
              </>
            )}
          </GridRow>
        ) : (
          !hideMute && (
            <>
              {!(isFan && !videoMuted) && (
                <MobileButton
                  xCenter
                  yCenter
                  active={!videoMuted}
                  onClick={toggleVideo}
                >
                  {!videoMuted ? <Video /> : <Icon form name="VideoOff" />}
                </MobileButton>
              )}
              {!(isFan && !audioMuted) && (
                <MobileButton
                  xCenter
                  yCenter
                  active={!audioMuted}
                  onClick={toggleAudio}
                >
                  {!audioMuted ? (
                    <>
                      <DynamicMic level={Math.abs(audioLevel - 10)} />
                      <Microphone />
                    </>
                  ) : (
                    <MicrophoneMuted />
                  )}
                </MobileButton>
              )}
            </>
          )
        )}
      </Container>
    );
  }
);

const DynamicMic = styled(Microphone)`
  && {
    clip-path: inset(${(props) => props.level}0% 0 0 0);
    position: absolute;
    path {
      fill: ${(props) => props.theme.palette.green.primary};
    }
  }
`;

const Container = styled(GridRow)`
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
`;

const Action = styled(GridRow)`
  align-items: center;
  position: relative;
  height: 30px;
  & + & {
    margin-left: 40px;
  }

  & > svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    path {
      fill: #ffffff;
    }
  }

  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.palette.gray.lighter
        : props.theme.palette.gray.primary};
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
  }
`;

const MobileButton = styled(GridColumn)`
  background-color: ${(props) =>
    props.active ? "#ffffff" : props.theme.palette.red.primary};
  border-radius: 20px;
  box-shadow: 0 6px 22px 0 #000000;
  width: 40px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }

  svg {
    path {
      fill: ${(props) =>
        props.active ? props.theme.palette.black : "#ffffff"};
    }
  }
`;
