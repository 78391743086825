import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  EventContext,
  UserContext,
  DeviceSelector,
  useVideoContext,
  useMediaStreamTrack,
  useDevices,
  Preview,
} from "components";
import {
  GridColumn,
  H3,
  H4,
  Label,
  Input,
  ButtonPrimary,
  Link,
  Footnote,
  GridRow,
} from "notes";
import { AudioIndicator } from "./AudioIndicator";
import { AudioTester } from "./AudioTester";
import iOS from "is-ios";

export const CheckIn = ({ onReady, handleHelp, isMobile, ...props }) => {
  const { updateName, name: userName } = useContext(UserContext);
  const [name, setName] = useState(userName ?? "");
  const [editName, setEditName] = useState(false);
  const [editVideo, setEditVideo] = useState(false);
  const [editAudio, setEditAudio] = useState(false);
  const { event } = useContext(EventContext);

  const checkInStep = localStorage?.getItem("checkInStep") ?? 1;
  const [currentStep, setCurrentStep] = useState(parseInt(checkInStep));
  const [recordStep, setRecordStep] = useState(1);
  const [recordTestState, setRecordTestState] = useState("initial");

  let localVideoInputDeviceId;
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks?.find((track) => track.kind === "video");
  const videoMediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  if (currentStep > 2) {
    localVideoInputDeviceId = videoMediaStreamTrack?.getSettings().deviceId;
  }

  let localAudioInputDeviceId;
  const localAudioTrack = localTracks.find((track) => track.kind === "audio");
  const audioMediaStreamTrack = useMediaStreamTrack(localAudioTrack);

  if (currentStep > 3) {
    localAudioInputDeviceId = audioMediaStreamTrack?.getSettings().deviceId;
  }

  const devices = useDevices();

  const audioIn = devices?.filter((device) => device.kind === "audioinput");
  const videoIn = devices?.filter((device) => device.kind === "videoinput");

  useEffect(() => {
    setName(userName);
  }, [userName]);

  const handleReady = () => {
    updateName(name);
    !!onReady && onReady();
  };

  const handleStep = (step) => {
    setCurrentStep(step);
    localStorage.setItem("checkInStep", step);
  };
  return (
    <Container {...props}>
      <H3>Let’s Get You Connected…</H3>
      <H4>
        We want to make sure that {event?.artist} can see and hear you during
        your call.
      </H4>
      <TopDivider />
      <StepsContainer>
        <Step active={currentStep === 1 || editName}>
          {currentStep === 1 || editName ? (
            <>
              <Title>
                <span>1.</span>Please confirm your name
              </Title>
              <Input
                value={name}
                onChange={setName}
                placeholder="Please enter your name..."
                style={{ marginTop: "12px" }}
                name="Name"
              />
              <ButtonPrimary
                style={{ marginTop: "24px" }}
                onClick={() => {
                  setEditName(false);
                  updateName(name);
                  if (currentStep < 2) {
                    handleStep(2);
                  }
                }}
              >
                Confirm
              </ButtonPrimary>
              <StepDivider />
            </>
          ) : (
            <>
              <Title>
                <span>1.</span>Name
              </Title>
              <H4>
                {name}{" "}
                <ChangeLink onClick={() => setEditName(true)}>
                  (edit)
                </ChangeLink>
              </H4>
            </>
          )}
        </Step>
        <Step active={currentStep === 2 || editVideo}>
          {currentStep < 2 ? (
            <Title>
              <span>2.</span>Video
            </Title>
          ) : currentStep === 2 || editVideo ? (
            <>
              <Title>
                <span>2.</span>Please confirm that your camera is working so
                that {event?.artist} can see you
              </Title>
              {!isMobile ? (
                <DeviceSelector videoOnly />
              ) : (
                <Preview
                  style={{ marginTop: "16px" }}
                  isFan
                  onTroubleshoot={handleHelp}
                  noDeviceSelect
                />
              )}
              <Actions>
                <Link onClick={handleHelp}>Troubleshoot</Link>
                <ButtonPrimary
                  onClick={() => {
                    setEditVideo(false);
                    if (currentStep < 3) {
                      handleStep(3);
                    }
                  }}
                >
                  Confirm
                </ButtonPrimary>
              </Actions>
              <StepDivider style={{ marginBottom: "12px" }} />
            </>
          ) : (
            <>
              <Title>
                <span>2.</span>Video
              </Title>
              <H4>
                {localVideoInputDeviceId &&
                  videoIn?.find((v) => v.deviceId === localVideoInputDeviceId)
                    ?.label}{" "}
                {!isMobile ? (
                  <ChangeLink
                    onClick={() => {
                      setEditVideo(true);
                    }}
                  >
                    (change)
                  </ChangeLink>
                ) : (
                  <PurpleText>(confirmed)</PurpleText>
                )}
              </H4>
            </>
          )}
        </Step>
        <Step active={currentStep === 3 || editAudio}>
          {currentStep < 3 ? (
            <Title style={{ marginTop: "12px" }}>
              <span>3.</span>Microphone
            </Title>
          ) : currentStep === 3 || editAudio ? (
            iOS ? (
              <>
                <Title>
                  <span>3.</span>Please confirm that you are seeing feedback
                  from the audio indicator below. Try using the following
                  phrase:
                </Title>
                <H3>I'm excited to meet {event?.artist}</H3>
                <AudioIndicator style={{ marginLeft: "16px" }} />
                <Actions>
                  <ButtonPrimary
                    onClick={() => {
                      setEditAudio(false);
                      setRecordStep(0);
                      setRecordTestState("initial");
                      if (currentStep < 4) {
                        handleStep(4);
                      }
                    }}
                  >
                    Confirm
                  </ButtonPrimary>
                </Actions>
              </>
            ) : (
              <>
                <Title>
                  <span>3.</span>{" "}
                  {recordStep === 3
                    ? "Did everything sound okay? Replay the message or try recording again. Otherwise, click confirm."
                    : "Please confirm that you are seeing feedback from the audio indicator below. When ready, click record and repeat the following phrase:"}
                </Title>

                <H3>I'm excited to meet {event?.artist}</H3>
                <Footnote>
                  Don’t worry, we won’t store or share this recording with
                  anyone.
                </Footnote>
                {recordStep >= 2 ? (
                  <>
                    <AudioTester
                      setState={setRecordTestState}
                      state={recordTestState}
                      onComplete={() => setRecordStep(3)}
                      onCancel={() => setRecordStep(1)}
                    />

                    {recordStep === 3 && (
                      <Actions>
                        <Link
                          onClick={() => {
                            setRecordTestState("initial");
                            setRecordStep(1);
                          }}
                        >
                          Record Again
                        </Link>
                        <ButtonPrimary
                          onClick={() => {
                            setEditAudio(false);
                            setRecordStep(0);
                            setRecordTestState("initial");
                            if (currentStep < 4) {
                              handleStep(4);
                            }
                          }}
                        >
                          Confirm
                        </ButtonPrimary>
                      </Actions>
                    )}
                  </>
                ) : (
                  <>
                    {!isMobile && <DeviceSelector audioOnly />}
                    <AudioIndicator
                      style={{ marginTop: isMobile ? "24px" : "8px" }}
                    />
                    <Actions style={{ marginTop: isMobile ? "40px" : "24px" }}>
                      <Link onClick={handleHelp}>Troubleshoot</Link>
                      <ButtonPrimary
                        onClick={() => {
                          setRecordStep(2);
                        }}
                      >
                        {isMobile && "Tap to "}Record
                      </ButtonPrimary>
                    </Actions>
                  </>
                )}
              </>
            )
          ) : (
            <>
              <Title>
                <span>3.</span>Microphone
              </Title>
              <H4>
                {localAudioInputDeviceId &&
                  audioIn?.find((v) => v.deviceId === localAudioInputDeviceId)
                    ?.label}{" "}
                {!isMobile ? (
                  <ChangeLink
                    onClick={() => {
                      setEditAudio(true);
                    }}
                  >
                    (change)
                  </ChangeLink>
                ) : (
                  <PurpleText>(confirmed)</PurpleText>
                )}
              </H4>
            </>
          )}
        </Step>
      </StepsContainer>
      {currentStep === 4 && !editAudio && !editVideo && !editName && (
        <>
          <StepDivider style={{ marginTop: "24px" }} />
          <ButtonPrimary onClick={handleReady}>Join Call</ButtonPrimary>
        </>
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 24px;
  & > ${H3} {
    font-size: 17px;
    line-height: 22px;
  }
  ${H4} {
    font-size: 15px;
    line-height: 19px;
  }

  @media only screen and ${(props) => props.theme.media.large} {
    background-color: ${(props) => props.theme.palette.yellow.lightest};
    border: 1px solid ${(props) => props.theme.palette.yellow.lighter};
    border-radius: 8px;
    & > ${H3} {
      font-size: 25px;
      line-height: 32px;
    }
    ${H4} {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;

const ChangeLink = styled(Link)`
  font-size: 15px;
  line-height: 19px;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
    line-height: 22px;
  }
`;

const PurpleText = styled(Link).attrs({ as: "p" })`
  display: inline-flex;
  color: ${(props) => props.theme.palette.purple.primary};
`;

const Title = styled(Label)`
  font-weight: 300;
  flex-flow: row nowrap;
  span {
    margin-right: 10px;
    width: 12px;
  }
  & + & {
    margin-top: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
  }
`;

const TopDivider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 2px;
  @media only screen and ${(props) => props.theme.media.large} {
    background-color: ${(props) => props.theme.palette.yellow.lighter};
  }
`;

const StepDivider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  display: block;
  margin-top: 40px;
  margin-bottom: 24px;
  width: 100%;
  height: 2px;
  @media only screen and ${(props) => props.theme.media.large} {
    background-color: ${(props) => props.theme.palette.yellow.lighter};
  }
`;

const StepsContainer = styled(GridColumn)``;

const Step = styled(GridColumn)`
  ${Title} {
    color: ${(props) =>
      props.active
        ? props.theme.palette.black
        : props.theme.palette.gray.primary};
  }
  & > ${H4} {
    font-weight: 600;
    margin-left: 22px;
    margin-bottom: 16px;
  }
  & > ${H3} {
    font-size: 20px;
    font-style: italic;
    margin: 12px 0;
    text-align: center;
  }
  & > ${Footnote} {
    text-align: center;
    margin-bottom: 8px;
    b {
      color: ${(props) => props.theme.palette.purple.primary};
      font-weight: 600;
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 344px;
  }
`;

const Actions = styled(GridColumn)`
  margin-top: 24px;
  a {
    box-sizing: border-box;
    justify-content: center;
    margin: 8px 0 0 0;
    order: 2;
    padding: 9px 12px;
    width: 100%;
  }
  button {
    order: 1;
    min-width: 0;
    width: 100%;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    justify-content: space-between;
    a {
      order: 1;
      margin: 0 12px 0 0;
    }
    button {
      order: 2;
    }
  }
`;
