import { useContext } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { firebase, EventContext } from "components";

export const useRooms = (userId) => {
  const { event } = useContext(EventContext);

  const [room, load] = useDocument(
    firebase.firestore().doc(`meet_greet/${event?.id}/rooms/${userId}`)
  );

  if (load || !event) {
    return null;
  }

  return room;
};
