import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H3, H4, Icon, Logo } from "notes";
import { EventBanner } from "components";

export const MobileHeader = ({ onHelp }) => {
  return (
    <EventBanner>
      <Header>
        <StyledLogo name="SetLiveReversed" />
        <StyledIcon
          onClick={() => !!onHelp && onHelp()}
          indicator
          name="Questions"
        />
      </Header>
    </EventBanner>
  );
};

const Header = styled(GridRow)`
  justify-content: space-between;
  padding: 12px 24px 0 24px;
  flex-grow: 1;
`;

const StyledLogo = styled(Logo)`
  width: 76px;
  height: 16px;
`;

const StyledIcon = styled(Icon)`
  path {
    fill: #ffffff;
  }
`;
