import React, { useContext } from "react";
import styled from "styled-components";
import { GridRow } from "notes";
import { ChatContext, useRooms, UserActions } from "components";

export const ChatHeader = ({ ...props }) => {
  const { user } = useContext(ChatContext);
  const room = useRooms(user);
  const roomData = room?.data();
  const handleSort = () => {
    console.log("sort");
  };
  return (
    <Container yCenter {...props}>
      <StyledUserActions
        checkedIn
        isChat
        {...roomData}
        video={roomData?.hasVideo}
        id={user}
        handleSort={handleSort}
      />
    </Container>
  );
};

const StyledUserActions = styled(UserActions)`
  border-bottom: none;
  flex-grow: 1;
`;

const Container = styled(GridRow)`
  background-color: #ffffff;
`;
