import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  Subheader,
  H2,
  ButtonSecondary,
  Footnote,
} from "notes";
import { TimeContext, EventContext, firebase } from "components";
import { DateTime, Duration } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const Time = ({ room, handleNext, artist = false, ...props }) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const [eventSettings, loading] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const sessionDuration =
    room?.data().sessionDuration || event?.sessionDuration || 90;
  const endTime = room.data().startTime
    ? DateTime.fromJSDate(room.data().startTime.toDate()).plus({
        seconds: sessionDuration,
      })
    : null;

  const addTime = () => {
    room.ref.update({ sessionDuration: sessionDuration + 15 });
  };

  const { minutes, seconds } =
    time < endTime &&
    Duration.fromMillis(endTime - time).shiftTo(
      "minutes",
      "seconds",
      "milliseconds"
    );

  useEffect(() => {
    if (!loading && endTime && endTime < time && artist) {
      handleNext();
    }
  }, [endTime < time]);
  const timeReady = seconds !== undefined && minutes !== undefined;
  const timeString = !timeReady
    ? ""
    : `${minutes || 0}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;

  if (artist && timeReady) {
    return (
      <>
        <AddTime onClick={addTime}>+15 sec.</AddTime>
        <TimeStyle>{timeString}</TimeStyle>
      </>
    );
  }

  if (!artist) {
    return (
      <Container {...props}>
        <Remaining>
          <LeftTitle>Time Remaining</LeftTitle>
          {timeReady ? (
            <GridRow xCenter>
              <TimeStyle>{timeString}</TimeStyle>
              <AddTime onClick={addTime}>+15 sec.</AddTime>
            </GridRow>
          ) : (
            <Footnote>Waiting for artist to join call...</Footnote>
          )}
        </Remaining>
        <Info>
          <InfoRow>
            <Title>Times In Call</Title>
            <Value>1</Value>
          </InfoRow>
          <InfoRow>
            <Title>Cumulative Time</Title>
            <Value>00:00</Value>
          </InfoRow>
        </Info>
      </Container>
    );
  }

  return "";
};

const Container = styled(GridColumn)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
  margin-bottom: 12px;
  padding-bottom: 12px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
  }
`;

const Remaining = styled(GridColumn)`
  flex: 0 1 auto;
  padding: 0 24px 16px 24px;
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.lightest};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 0 0 224px;
    margin-right: 8px;
    padding: 0 24px 0 8px;
  }
`;

const TimeStyle = styled(H2)`
  color: #ffffff;
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  width: 100px;
  padding: 0 16px;
  box-sizing: border-box;
`;

const LeftTitle = styled(Subheader)`
  font-weight: 600;
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
`;

const AddTime = styled(ButtonSecondary)`
  border-color: #ffffff;
  color: #ffffff;
  min-width: 90px;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: ${(props) => props.theme.palette.black};
  }
  &:disabled {
    background-color: #e6e9eb;
    border-color: #e6e9eb;
    color: ${(props) => props.theme.palette.gray.lighter};
  }
`;

const Title = styled(Subheader)`
  font-weight: 600;
  flex: 0 0 auto;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 0 0 120px;
    margin-right: 0;
  }
`;

const Value = styled(Subheader)`
  color: #ffffff;
  font-weight: 600;
  width: 48px;
  text-align: left;
`;

const Info = styled(GridRow)`
  /* flex: 0 1 100%; */
  display: none;
  @media only screen and ${(props) => props.theme.media.large} {
    display: flex;
    flex-direction: column;
    flex: 0 0 176px;
  }
`;

const InfoRow = styled(GridRow)`
  width: 50%;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
    & + & {
      margin-top: 16px;
    }
  }
`;
