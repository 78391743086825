import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  EventContext,
  UserContext,
  useRooms,
  usePhotos,
  EventBanner,
  NavBarWrapper,
  firebase,
  CloudTracker,
  useCompositions,
  useSounds,
} from "components";
import { MobileHeader } from "./MobileHeader";
import { MobileNav } from "./MobileNav";
import { PageHeader } from "./PageHeader";
import { ShadowCard } from "./WaitingTime";
import { MediaGallery } from "./MediaGallery";
import { ChatBox } from "./ChatBox";
import { MessageIcon, Contact, Bold } from "./TextUpdates";
import {
  GridColumn,
  GridRow,
  H1,
  H4,
  H3,
  P,
  Link,
  withDefaultMedia,
  Subheader,
} from "notes";
import { TextUpdateForm } from "./TextUpdates/Form";
import parsePhoneNumber from "libphonenumber-js";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const PostCall = withDefaultMedia(({ matches }) => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);
  const { playDisconnected } = useSounds();
  const photos = usePhotos();
  const roomData = useRooms(user?.uid);
  const room = roomData?.data();
  const [view, setView] = useState("preview");
  const [edit, setEdit] = useState(false);

  const [error, setError] = useState();

  const ref = firebase
    .firestore()
    .doc(`meet_greet/${event.id}/rooms/${user?.uid}`);

  const privateDataRef = firebase
    .firestore()
    .doc(`meet_greet/${event.id}/rooms/${user?.uid}/private/data`);

  const [privateData] = useDocumentData(privateDataRef);

  const handlePhone = (number) => {
    firebase
      .functions()
      .httpsCallable("sms-verifyNumber")({ phoneNumber: number })
      .then(({ data: { valid, phoneNumber } }) => {
        if (valid) {
          ref.update({ noText: false });
          privateDataRef.update({ phone: phoneNumber });
          setEdit(false);
        } else {
          setError("Phone number not valid");
        }
      });
  };

  const setNoText = (noText) => {
    ref.update({ noText });
  };

  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const isMobile = matches.small || matches.medium;

  const phoneNumber = privateData?.phone
    ? parsePhoneNumber(privateData?.phone)
    : null;

  const { sessions, hasCompositions } = useCompositions();

  const videos = !!sessions?.length ? sessions : [];

  if (!!room) {
    playDisconnected();
  }

  return (
    <Wrapper>
      <StyledPageHeader />
      {isMobile && view !== "chat" && <MobileHeader />}
      <Section>
        {(!isMobile || view === "chat") && (
          <ChatContainer mobileHeight={window.innerHeight}>
            {isMobile ? (
              <NavBarWrapper
                title="Chat"
                onBack={() => setView("preview")}
                content={<StyledChat />}
              />
            ) : (
              <>
                <EventBanner />
                <StyledChat />
              </>
            )}
          </ChatContainer>
        )}
        {(!isMobile || view === "preview") && (
          <Container>
            <Content>
              <Title>Thanks for Joining!</Title>
              {hasCompositions && (
                <>
                  <Subtitle>
                    {!!videos.length && !!photos.length
                      ? `Video and photo(s) of your call with ${event?.artist} are ready below!`
                      : `Video and photo(s) of your call with ${event?.artist} will be
                available soon!`}
                  </Subtitle>
                  <CloudTracker />
                  {!!videos.length ? (
                    <MediaGallery videos={videos} photos={photos} />
                  ) : (
                    <Card>
                      <P>
                        Once ready, we’ll email the files to{" "}
                        <SemiBold>{user?.email}</SemiBold> or you can sign in to{" "}
                        <StyledLink
                          onClick={() => openLink("https://set.live")}
                        >
                          Set.Live
                        </StyledLink>{" "}
                        and view them from the{" "}
                        <StyledLink
                          onClick={() => openLink("https://set.live/rewards")}
                        >
                          My Rewards
                        </StyledLink>{" "}
                        page.
                        {phoneNumber && !room?.noText && (
                          <>
                            {" "}
                            We’ll send you text updates on their individual
                            statuses to{" "}
                            <SemiBold>
                              {phoneNumber?.country === "US"
                                ? phoneNumber?.formatNational()
                                : phoneNumber?.formatInternational()}
                            </SemiBold>
                            .
                          </>
                        )}
                      </P>
                      {edit || !phoneNumber ? (
                        <FormCard>
                          <TextUpdateForm
                            label="Get text updates on your photos/video"
                            defaultValue={privateData?.phone}
                            error={error}
                            onSubmit={handlePhone}
                            buttonLabel="Get Text Updates"
                            flagOnly={isMobile}
                          />
                        </FormCard>
                      ) : room?.noText ? (
                        <StyledShadowCard>
                          <GridRow yCenter>
                            <MessageIcon form name="Message" />
                            <Contact>
                              You will no longer receive photo/video updates to{" "}
                              <Bold>
                                {phoneNumber?.country === "US"
                                  ? phoneNumber?.formatNational()
                                  : phoneNumber?.formatInternational()}
                              </Bold>
                            </Contact>
                          </GridRow>
                          <SmallLink onClick={() => setEdit(true)}>
                            Get Text Alerts
                          </SmallLink>
                        </StyledShadowCard>
                      ) : (
                        <Actions>
                          <SmallLink onClick={() => setNoText(true)}>
                            Don't Text Me About This
                          </SmallLink>
                          <SmallLink onClick={() => setEdit(true)}>
                            Update Number
                          </SmallLink>
                        </Actions>
                      )}
                    </Card>
                  )}
                </>
              )}
            </Content>
            {isMobile && <MobileNav postCall setView={setView} />}
          </Container>
        )}
      </Section>
    </Wrapper>
  );
});

const Card = styled(GridColumn)`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  ${H4} {
    font-weight: 700;
    margin-bottom: 8px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 24px;
    ${H4} {
      font-size: 25px;
      font-weight: 600;
      line-height: 32px;
    }
  }
`;

const StyledPageHeader = styled(PageHeader)`
  display: none;
  @media only screen and ${(props) => props.theme.media.large} {
    display: flex;
  }
`;

const Wrapper = styled(GridColumn)`
  ${H1} {
    margin-bottom: 24px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${H1} {
      margin-bottom: 40px;
    }
  }
`;

const StyledChat = styled(ChatBox)`
  border-right: 1px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 1px solid ${(props) => props.theme.palette.gray.lightest};
`;

const ChatContainer = styled(GridColumn)`
  background-color: #f5f5f5;
  flex: 1 0 auto;
  transition: background-color 2.5s ease;
  height: ${(props) => props.mobileHeight}px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 0 0 392px;
    height: initial;
  }
`;

const Section = styled(GridColumn)`
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    height: calc(100vh - 56px);
  }
`;

const Container = styled(GridColumn)`
  overflow-y: scroll;
  width: 100%;
`;

const Content = styled(GridColumn)`
  flex-grow: 1;
  padding: 16px 24px 80px 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 1 0 auto;
    padding: 0 44px;
    margin-bottom: 56px;
    max-width: 1050px;
  }
`;

const SemiBold = styled.span`
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  display: inline-flex;
`;

const Actions = styled(GridRow)`
  justify-content: space-around;
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    justify-content: flex-start;
    margin-top: 24px;
  }
`;

const SmallLink = styled(Link)`
  font-size: 12px;
  line-height: 18px;
  @media only screen and ${(props) => props.theme.media.large} {
    & + & {
      margin-left: 16px;
    }
  }
`;

const StyledShadowCard = styled(ShadowCard)`
  margin-top: 16px;
  ${SmallLink} {
    margin-top: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${SmallLink} {
      margin-top: 20px;
      margin-left: 32px;
    }
  }
`;

const FormCard = styled(ShadowCard)`
  align-items: flex-start;
  padding: 12px;
  margin-top: 16px;
`;

const Title = styled(H3)`
  ${(props) => props.theme.fonts.header};
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-bottom: 4px;
  }
`;

const Subtitle = styled(P)`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 0;
  }
`;

const SectionLabel = styled(Title)`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 0;
  ${GridRow} + & {
    margin-top: 40px;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    margin-bottom: 0;
    ${GridRow} + & {
      margin-top: 52px;
    }
  }
`;

const SubLabel = styled(Subheader)`
  margin-top: 8px;
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;
