import React from "react";
import styled from "styled-components";
import { H4, GridColumn, GridRow, Small, Subheader } from "notes";
import { MediaDownload } from "./MediaDownload";

export const MediaSection = ({
  title,
  subtitle,
  icon,
  photos,
  video,
  ...props
}) => {
  return (
    <SectionContainer {...props}>
      <Row yCenter>
        {icon}
        <H4>{title}</H4>
      </Row>
      <Content>
        <Subtitle>{subtitle}</Subtitle>
        <Row>
          <Column>
            <SubLabel>Landscape</SubLabel>
          </Column>
          <Column>
            <SubLabel>Portrait</SubLabel>
          </Column>
        </Row>
        {photos?.map((photo, index) => (
          <MediaRow key={index}>
            <Column>
              <MediaDownload {...photo.landscape} video={video} />
            </Column>
            <Column>
              <MediaDownload portrait {...photo.portrait} video={video} />
            </Column>
          </MediaRow>
        ))}
      </Content>
    </SectionContainer>
  );
};

const Column = styled(GridColumn)`
  align-items: flex-start;
  width: 60%;
  & + & {
    width: 40%;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    width: 55%;
    & + & {
      width: 35%;
    }
  }
`;

const ButtonLandscape = styled.button`
  background-color: ${(props) =>
    props.active ? props.theme.palette.purple.primary : "#ffffff"};
  border: 1px solid ${(props) => props.theme.palette.purple.primary};
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  svg {
    width: 20px;
    width: 20px;
    path {
      fill: ${(props) =>
        props.active ? "#ffffff" : props.theme.palette.black};
    }
  }
`;

const Row = styled(GridRow)`
  justify-content: space-between;
  ${H4} {
    font-size: 22px;
    flex-grow: 1;
    font-weight: 600;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${H4} {
      font-size: 17px;
    }
  }
`;

const MediaRow = styled(Row)`
  margin-top: 8px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-top: 16px;
  }
`;

const SectionContainer = styled(GridColumn)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 12px;
  position: relative;
`;

const Content = styled(GridColumn)`
  padding: 0 40px 24px 40px;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 0 24px 0;
  }
`;

const Subtitle = styled(Small)`
  margin-bottom: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-bottom: 24px;
  }
`;

const SubLabel = styled(Subheader)``;
