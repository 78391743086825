import React, { useState } from "react";
import styled from "styled-components";
import {
  GridColumn,
  Checkbox,
  Label,
  ButtonPrimary,
  Link,
  P,
  Footnote,
  LoaderInline,
  ErrorMessage,
  withDefaultMedia,
  CountryPhone,
} from "notes";

export const TextUpdateForm = withDefaultMedia(
  ({
    matches,
    label,
    defaultValue = "",
    error,
    onSubmit,
    loading,
    buttonLabel,
    flagOnly,
    ...props
  }) => {
    const [phone, setPhone] = useState(defaultValue);
    const [agree, setAgree] = useState(false);

    const handlePhone = (number) => {
      !!onSubmit && onSubmit(number);
    };

    return (
      <Form {...props}>
        <GridColumn style={{ maxWidth: "392px" }}>
          <Label>{label}</Label>
          <CountryPhone
            removeSelectedCountryLabel={flagOnly}
            value={phone}
            onChange={setPhone}
            error={error}
          />
          {error && <ErrorMessage content={error} />}
          <Footnote>Standard data and messaging rates may apply.</Footnote>
        </GridColumn>
        <Checkbox checked={agree} onChange={setAgree}>
          <Light>
            I consent to receive recurring automated notifications about
            Set.Live events by text message through an automatic telephone
            dialing system. <StyledLink>Privacy Policy</StyledLink>
          </Light>
        </Checkbox>

        {loading ? (
          <LoaderInline />
        ) : (
          <ButtonPrimary
            disabled={!phone || !agree || error}
            onClick={() => handlePhone(phone)}
            style={{ maxWidth: "392px" }}
          >
            {buttonLabel ?? "Get Text Alerts"}
          </ButtonPrimary>
        )}
      </Form>
    );
  }
);

const Form = styled(GridColumn)`
  ${Label} {
    margin-bottom: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    margin: 8px 0;
  }
  input[type="checkbox"] + div {
    top: 11px;
  }
`;

const Light = styled(P)`
  font-size: 15px;
  line-height: 19px;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 15px;
  line-height: 19px;
  text-decoration: underline;
`;
