import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Subheader } from "notes";
import { Time, CallControl, MediaActions, CallDetails } from "./Components";
import { ChatContext } from "components";
import { ChatProvider } from "components/ChatProvider";
import { ChatPanel } from "Routes/Manager/Components";

export const CallControls = ({
  room,
  artist,
  handleNext,
  handleEnd,
  managerRoom,
  chatStyle,
  isMobile,
  inCall,
  ...props
}) => {
  const { user, teamChat } = useContext(ChatContext);

  const { userBio, userLocation, status: roomStatus } =
    (room && room?.data()) || {};

  const hideMute = roomStatus === "live";

  const status = {
    chat: teamChat,
    artist,
  };

  return (
    <Container {...props}>
      {(room?.id || managerRoom) && (
        <CallInformation>
          <CallDetails
            userInfo={{ userBio, userLocation }}
            roomId={room?.id}
            artist={artist}
          />
          {!managerRoom && (
            <Time room={room} handleNext={handleNext} artist={artist} />
          )}
          <CallControl
            room={room}
            handleNext={handleNext}
            handleEnd={handleEnd}
            manager={!artist}
            spectator={true}
            managerRoom={managerRoom}
          />
        </CallInformation>
      )}
      {!room?.id && !managerRoom && (
        <MediaActions {...status} hideMute={hideMute} />
      )}

      {!isMobile &&
        inCall &&
        (teamChat || (!!room?.id && !user) || (managerRoom && !user)) && (
          <ChatContainer stretch>
            {!!managerRoom && (
              <MediaActions {...status} hideMute={hideMute} artist />
            )}
            <ChatProvider userId={`eventmods`} team>
              <ChatPanel team style={chatStyle} />
            </ChatProvider>
          </ChatContainer>
        )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  flex: 0 1 auto;
  width: 100%;
  position: relative;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
  }
`;

const ChatContainer = styled(GridColumn)`
  align-items: stretch;
  justify-content: flex-start;
  display: flex;
  height: 100%;
`;

const CallInformation = styled(GridColumn)`
  flex: 0 0 auto;
  width: 100%;
  padding: 24px 24px 0 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 0 0 auto;
    max-width: 456px;
    padding: 0 24px;
  }
`;
