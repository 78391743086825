import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, H3, H4 } from "notes";
import { EventContext } from "components";

export const EventBanner = ({ children }) => {
  const { event } = useContext(EventContext);
  const image = event?.assets?.preshowBanner?.path;
  return (
    <Container>
      <img src={image} style={{ width: "100%", height: "auto" }} />
      <ContentContainer>
        {children}
        <Overlay>
          <Title titleLength={event?.artist?.length}>{event?.artist}</Title>
          <Subtitle>Meet &amp; Greet</Subtitle>
        </Overlay>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(GridColumn)`
  position: relative;
  width: 100%;
  min-height: 100px;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
`;

const Overlay = styled(GridColumn)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding: 16px 24px;
  height: 76px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media only screen and ${(props) => props.theme.media.large} {
    height: 112px;
  }
`;

const Title = styled(H3)`
  color: #ffffff;
  ${(props) => props.theme.fonts.header};
  text-transform: uppercase;
  font-size: ${(props) =>
    props.titleLength > 26 ? "17px" : props.titleLength > 15 ? "20px" : "25px"};
`;

const Subtitle = styled(H4)`
  color: #ffffff;
  ${(props) => props.theme.fonts.header};
  font-weight: 300;
  text-transform: uppercase;
  line-height: 20px;
`;
