import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn } from "notes";
import { EventContext } from "components";

export const EventAvatar = ({ ...props }) => {
  const { event } = useContext(EventContext);
  const imageUrl = event?.assets?.squareBanner?.path;
  return <Container background={imageUrl} {...props}></Container>;
};

const Container = styled(GridColumn)`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: 1px solid #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
`;
