import { useEffect, useState } from "react";

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    if (window.innerHeight !== windowHeight) {
      setWindowHeight(window.innerHeight);
    }
  }, [window.innerHeight]);
  return windowHeight;
};
