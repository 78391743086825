import { Room } from "twilio-video";
import { useContext, useEffect } from "react";
import { VideoContext, EventContext, firebase } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const ArtistTrackPublished = ({ artist }) => {
  const { room } = useContext(VideoContext);
  const { event } = useContext(EventContext);
  const [eventSettings] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const handlePublished = (pub) => {
    if (pub.kind === "video") {
      firebase
        .firestore()
        .doc(
          `meet_greet/${event.id}/rooms/${eventSettings.liveRoom}/sessions/${room.sid}`
        )
        .update({ startTime: firebase.firestore.FieldValue.serverTimestamp() });
    }
  };
  useEffect(() => {
    if (room && artist) {
      room.localParticipant.on("trackPublished", handlePublished);
      return () => {
        room.localParticipant.off("trackPublished", handlePublished);
      };
    }
  }, [room, artist]);
  return null;
};
