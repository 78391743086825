import React, { useContext } from "react";
import { EventAvatar, EventContext, useTimeRemaining } from "components";
import { GridColumn, GridRow, H2, P, H3, H4, withDefaultMedia } from "notes";
import styled from "styled-components";

export const InCall = withDefaultMedia(
  ({ matches, children, position, isReady, room }) => {
    const { event } = useContext(EventContext);
    const { timeRemaining } = useTimeRemaining({ room });
    return (
      <Container>
        {isReady && (
          <Header yCenter>
            <StyledEventAvatar />
            <H3>{event?.artist}</H3>
            <H3>|</H3>
            <GridRow stretch>
              {matches.large ? (
                <H3 style={{ overflow: "hidden" }}>
                  <Ellipsis>
                    {event?.subtitle} ({event?.start?.toFormat("MMMM d, yyyy")})
                  </Ellipsis>
                </H3>
              ) : (
                <H3>{event?.start?.toFormat("MMM d, yyyy")}</H3>
              )}
            </GridRow>
            {!!timeRemaining && !timeRemaining.includes("undefined") && (
              <TimeContainer xCenter>
                <TimeIndicator>{timeRemaining}</TimeIndicator>
              </TimeContainer>
            )}
          </Header>
        )}
        <Content isNext={position === 0}>
          {position === 0 && (
            <LeftSide xCenter>
              <UpNext xCenter>
                <EventAvatar />
                <H2>You’re Up Next!</H2>
                <P style={{ fontWeight: 700, color: "#fff" }}>
                  Please remain on this page as we will pull you into your call
                  with {event?.artist} momentarily...
                </P>
                {!!event?.assets?.brandLogo?.path && (
                  <>
                    <BrandImage src={event?.assets?.brandLogo?.path} />
                  </>
                )}
              </UpNext>
            </LeftSide>
          )}

          <VideoWrapper>{children}</VideoWrapper>
        </Content>
      </Container>
    );
  }
);

const VideoWrapper = styled(GridColumn)`
  width: 100%;
`;

const BrandImage = styled.img`
  display: block;
  width: 100%;
  max-width: 200px;
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 250px;
  }
`;

const TimeContainer = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.black};
  border-radius: 2px;
  padding: 4px;
  min-width: 48px;
`;

const TimeIndicator = styled(H2)`
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 32px;
    line-height: 38px;
  }
`;

const Ellipsis = styled.div`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const Content = styled(GridRow)`
  flex-grow: 1;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
  }
`;

const LeftSide = styled(GridRow)`
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  height: 50%;
  color: #ffffff;
  ${H2} {
    color: #ffffff;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  ${P} {
    text-align: center;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    align-items: flex-start;
    padding-top: 5vw;
    flex-grow: 0;
    width: 50%;
    height: 100vh;
    ${H2} {
      margin-top: 56px;
    }
  }
`;

const UpNext = styled(GridColumn)`
  flex: 0 1 392px;
  padding: 0 16px;
`;

const Container = styled(GridColumn)`
  background: #000000;
  height: 100vh;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
  }
`;

const Header = styled(GridRow)`
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  height: 42px;
  flex-shrink: 0;
  padding: 0 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 501;
  ${H3} {
    color: #ffffff;
    font-size: 15px;
    line-height: 18px;
    margin-left: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    background: ${(props) => props.theme.palette.black};
    height: 76px;
    padding: 0 24px;
    position: relative;
    ${H3} {
      font-size: 25px;
      line-height: 32px;
      margin-left: 24px;
    }
  }
`;

const StyledEventAvatar = styled(EventAvatar)`
  width: 16px;
  height: 16px;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 40px;
    height: 40px;
  }
`;
