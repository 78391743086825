import React, { useContext, useRef, useCallback } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Icon, P, withDefaultMedia, Toggle } from "notes";
import { ReactComponent as Video } from "Images/Video.svg";
import { ReactComponent as MicrophoneMuted } from "Images/MicrophoneMuted.svg";
import { ReactComponent as Microphone } from "Images/MicrophoneTertiary.svg";
import { ReactComponent as InstantMessage } from "Images/InstantMessage.svg";
import { ReactComponent as InstantMessageTertiary } from "Images/InstantMessageTertiary.svg";
import useLocalVideoToggle from "hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "hooks/useLocalAudioToggle/useLocalAudioToggle";

import {
  ChatContext,
  // useParticipants,
  useLocalAudioLevel,
  // filterParticipants,
} from "components";

export const MediaActions = withDefaultMedia(
  ({ artist, matches, hideMute, ...props }) => {
    const { hasUnreadTeamChats, teamChat, setTeamChat } = useContext(
      ChatContext
    );
    const audioLevel = useLocalAudioLevel();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

    const toggleVideo = useCallback(() => {
      if (Date.now() - lastClickTimeRef.current > 500) {
        lastClickTimeRef.current = Date.now();
        toggleVideoEnabled();
      }
    }, [toggleVideoEnabled]);

    return (
      <Container xCenter yCenter {...props}>
        {matches.large ? (
          <ActionGroup>
            {!hideMute && (
              <>
                <Action active={isVideoEnabled} onClick={toggleVideo}>
                  {isVideoEnabled ? <Video /> : <Icon form name="VideoOff" />}
                  <P>Video</P>
                </Action>
                <Action
                  active={isAudioEnabled}
                  onClick={() => toggleAudioEnabled()}
                >
                  {isAudioEnabled ? (
                    <>
                      <DynamicMic level={Math.abs(audioLevel - 10)} />
                      <Microphone />
                    </>
                  ) : (
                    <MicrophoneMuted />
                  )}
                  <P>{isAudioEnabled ? "Mute" : "Unmute"}</P>
                </Action>
              </>
            )}
            <Action active={teamChat} onClick={() => setTeamChat(!teamChat)}>
              {hasUnreadTeamChats && <ChatIndicator />}
              {teamChat ? <InstantMessageTertiary /> : <InstantMessage />}
              <P>{teamChat ? "Hide Chat" : "Team Chat"}</P>
            </Action>
          </ActionGroup>
        ) : (
          !hideMute && (
            <>
              <MobileButton
                xCenter
                yCenter
                active={isVideoEnabled}
                onClick={toggleVideo}
              >
                {isVideoEnabled ? <Video /> : <Icon form name="VideoOff" />}
              </MobileButton>
              <MobileButton
                xCenter
                yCenter
                active={isAudioEnabled}
                onClick={() => toggleAudioEnabled()}
              >
                {isAudioEnabled ? (
                  <>
                    <DynamicMic level={Math.abs(audioLevel - 10)} />
                    <Microphone />
                  </>
                ) : (
                  <MicrophoneMuted />
                )}
              </MobileButton>
            </>
          )
        )}
      </Container>
    );
  }
);

const ActionGroup = styled(GridRow)`
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 4px;
  padding: 5px 16px;
  height: 40px;
`;

const VideoNavContainer = styled(GridRow)`
  ${(props) =>
    !props.isChatOpen &&
    `
  position: absolute;
  right: 24px;
  `};
`;

const NavButton = styled(GridRow)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 4px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
`;

const VideoNav = styled(Icon)`
  path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lighter};
  }
`;

const DynamicMic = styled(Microphone)`
  && {
    clip-path: inset(${(props) => props.level}0% 0 0 0);
    position: absolute;
    path {
      fill: ${(props) => props.theme.palette.green.primary};
    }
  }
`;

const Container = styled(GridRow)`
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
`;

const ChatIndicator = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.red.primary};
  border-radius: 3px;
  flex: 0 0 6px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: -2px;
  right: 10px;
`;

const Action = styled(GridColumn)`
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 30px;
  width: 40px;
  & + & {
    margin-left: 24px;
  }

  & > svg {
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.palette.gray.lighter
          : props.theme.palette.gray.primary};
    }
  }
  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.palette.gray.lighter
        : props.theme.palette.gray.primary};
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
  }
`;

const MobileButton = styled(GridColumn)`
  background-color: ${(props) =>
    props.active ? "#ffffff" : props.theme.palette.red.primary};
  border-radius: 20px;
  box-shadow: 0 6px 22px 0 #000000;
  width: 40px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }

  svg {
    path {
      fill: ${(props) =>
        props.active ? props.theme.palette.black : "#ffffff"};
    }
  }
`;
