import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";
import { useLocalAudioLevel } from "components";

export const AudioIndicator = ({ ...props }) => {
  const level = useLocalAudioLevel();
  const roundedLevel = ((level - 2) * 10) / 6.67;
  let squaresArray = Array.from(Array(15), (v, i) =>
    i <= roundedLevel ? 2 : 1
  );
  return (
    <Container {...props}>
      {squaresArray.map((i) => (
        <Square active={i} />
      ))}
    </Container>
  );
};

const Container = styled(GridRow)`
  justify-content: space-between;
  width: 324px;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 342px;
  }
`;

const Square = styled.div`
  background-color: ${(props) =>
    props.active === 2
      ? props.theme.palette.green.primary
      : props.theme.palette.gray.lightest};
  border-radius: 2px;
  height: 16px;
  width: 16px;
`;
