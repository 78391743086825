import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { firebase, EventContext, ManagerModalContext } from "components";
import {
  Input,
  Label,
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  H4,
  GridColumn,
} from "notes";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

export const UserBio = ({ onComplete }) => {
  const { event } = useContext(EventContext);
  const {
    managerModal: {
      userBio: { id, name },
    },
  } = useContext(ManagerModalContext);

  const [userInfo] = useDocumentDataOnce(
    firebase.firestore().doc(`meet_greet/${event.id}/rooms/${id}`)
  );
  const userBio = userInfo?.userBio ?? "";
  const userLocation = userInfo?.userLocation ?? "";
  const [internalValue, setInternalValue] = useState({ location: "", bio: "" });
  const handleConfirm = async () => {
    const res = await firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${id}`)
      .update({
        userBio: internalValue.bio,
        userLocation: internalValue.location,
      });
    onComplete();
  };
  const handleChange = (key, v) => {
    setInternalValue({ ...internalValue, [key]: v });
  };
  useEffect(() => {
    setInternalValue({ location: userLocation, bio: userBio });
  }, [userLocation, userBio]);

  return (
    <>
      <DisabledLabel>Fan</DisabledLabel>
      <H4>{name}</H4>
      <Label>Location (optional)</Label>
      <Input
        iconLeft={<Icon form name="Marker" />}
        placeholder="Add the city and state where fan is located..."
        value={internalValue.location}
        onChange={(v) => handleChange("location", v)}
      />
      <Label>Notes (optional)</Label>
      <Input
        placeholder="What would you like to note about this fan..."
        value={internalValue.bio}
        onChange={(v) => handleChange("bio", v)}
      />
      <Actions>
        <CancelButton onClick={onComplete}>Cancel</CancelButton>
        <SubmitButton onClick={handleConfirm}>Save</SubmitButton>
      </Actions>
    </>
  );
};

export const DisabledLabel = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
`;

export const Actions = styled(GridColumn)`
  margin-top: 56px;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const SubmitButton = styled(ButtonPrimary)`
  margin-bottom: 8px;
  order: 1;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-bottom: 0;
    margin-left: 24px;
    order: 2;
  }
`;

export const CancelButton = styled(ButtonSecondary)`
  order: 2;
  @media only screen and ${(props) => props.theme.media.large} {
    order: 1;
  }
`;
