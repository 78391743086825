import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, firebase } from "components";
import { GridColumn, GridRow, H1, H4 } from "notes";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";

export const Stats = () => {
  const { event } = useContext(EventContext);
  const [eventSettings, er] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}`)
  );
  const [userRooms, load, err] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms`)
      .where("status", "in", ["ready", "complete", "canceled"])
      .orderBy("priority")
  );
  const [pendingUserRooms, pload, perr] = useCollection(
    firebase
      .firestore()
      .collection(`meet_greet/${event.id}/rooms`)
      .where("status", "==", "pending")
  );
  const artistIn = eventSettings?.managerStatus?.artist;
  const teamIn =
    Object.values(eventSettings?.managerStatus || {}).filter((m) => m === true)
      .length - (artistIn ? 1 : 0);
  const fansIn = userRooms?.size;
  const fansOut = pendingUserRooms?.size;

  return (
    <Container>
      <Item>
        <H1>{artistIn ? "Yes" : "No"}</H1>
        <H4>Artist Checked In</H4>
      </Item>
      <Divider light />
      <Item>
        <H1>{teamIn}</H1>
        <H4>Teammates Checked In</H4>
      </Item>
      <Divider light />
      <Item>
        <H1>{fansIn}</H1>
        <H4>Fans Checked In</H4>
      </Item>
      <Divider light />
      <Item>
        <H1>{fansOut}</H1>
        <H4>
          Fans <span>Not</span> Checked In
        </H4>
      </Item>
    </Container>
  );
};

const Item = styled(GridColumn)`
  width: 70px;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
  }
`;

const Divider = styled.div`
  background-color: ${(props) =>
    props.light
      ? props.theme.palette.gray.lightest
      : props.theme.palette.black};
  display: block;
  margin: ${(props) => (props.light ? "16px 0" : "16px 0 24px 0")};
  width: 100%;
  height: 2px;
`;

const Container = styled(GridRow)`
  background-color: #f5f5f5;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  justify-content: space-between;
  padding: 16px 24px;
  ${H1}, ${H4} {
    text-align: center;
  }
  ${H1} {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  ${H4} {
    font-size: 11px;
    line-height: 14px;
    span {
      font-weight: 700;
    }
  }
  ${Divider} {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    padding: 40px 24px;
    ${H1} {
      font-size: 40px;
      line-height: 44px;
    }
    ${H4} {
      font-size: 17px;
      line-height: 22px;
    }
    ${Divider} {
      display: block;
    }
  }
`;
