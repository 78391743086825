import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import {
  GridRow,
  H4,
  Logo,
  Icon,
  TertiaryMenu,
  withDefaultMedia,
  Toggle,
  Label,
} from "notes";
import { UserContext, LocalAvatar, firebase, EventContext } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const menuItems = [
  {
    key: "signout",
    icon: <StyledIcon tertiary name="Exit" />,
    label: "Sign Out",
  },
];

export const SiteLogo = withDefaultMedia(({ matches }) => {
  const theme = useContext(ThemeContext);
  return (
    <SiteLogoStyle
      name={matches.large && !theme.dark ? "SetLiveDefault" : "SetLiveReversed"}
    />
  );
});

export const Header = ({ artist = false, ...props }) => {
  const history = useHistory();
  const { name } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const handleLogout = async () => {
    firebase.auth().signOut();
    history.push(`/${event.id}/login`);
  };

  const handleMenu = (key) => {
    if (key === "signout") {
      handleLogout();
    } else {
      history.push(`/${key}`);
    }
  };

  return (
    <Container yCenter {...props}>
      <SiteLogo />
      <TertiaryMenu
        offset="0, 10"
        items={menuItems}
        onSelect={(key) => handleMenu(key)}
        trigger={(props) => (
          <UserButton yCenter {...props}>
            <UserAvatar isArtist={artist} />
            <UserName>{name}</UserName>
            <Caret indicator name="Dropdown" />
          </UserButton>
        )}
      />
    </Container>
  );
};

const Hold = styled(GridRow)`
  ${Label} {
    color: #ffffff;
    font-weight: 600;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${Label} {
      color: #222222;
    }
  }
`;

const SiteLogoStyle = styled(Logo)`
  width: 76px;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 114px;
  }
`;

const UserButton = styled(GridRow).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  height: 40px;
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 0 12px;
  }
`;

const UserAvatar = styled(LocalAvatar)`
  margin-right: 7px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-right: 16px;
  }
`;

const Caret = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const UserName = styled(H4)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  display: none;
  font-weight: 600;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.large} {
    display: flex;
  }
`;

const Container = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.purple.darker};
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
  transition: background-color 2.5s ease;
  @media only screen and ${(props) => props.theme.media.large} {
    background-color: ${(props) => (props.theme.dark ? "#000000" : "#ffffff")};
    ${Caret} {
      path {
        fill: ${(props) =>
          props.theme.dark ? "#ffffff" : props.theme.palette.black};
      }
    }
  }
`;
