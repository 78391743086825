import React from "react";
import { GridRow, P } from "notes";
import styled from "styled-components";
import { ConnectionIndicator } from "components";

export const Connection = ({ strength = 100, ...props }) => {
  let signal =
    strength < 35
      ? { color: "red", text: "Poor" }
      : strength < 65
      ? { color: "yellow", text: "Weak" }
      : { color: "green", text: "Strong" };
  return (
    <Container xCenter yCenter color={signal.color} {...props}>
      <P>Connection:</P>
      <Wrapper>
        <ConnectionIndicator strength={strength} />
      </Wrapper>
      <Bold>{signal.text}</Bold>
    </Container>
  );
};

const Container = styled(GridRow)`
  background-color: ${(props) => props.theme.palette[props.color].primary};
  border-radius: 2px;
  color: #ffffff;
  margin-bottom: 24px;
  width: 100%;
  flex: 0 0 40px;
  ${P} {
    color: #ffffff;
  }
`;

const Bold = styled(P)`
  font-weight: 700;
`;

const Wrapper = styled(GridRow)`
  background-color: #ffffff;
  border-radius: 2px;
  margin: 0 8px;
  width: 24px;
  height: 24px;
`;
