import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  ButtonPrimary,
  H3,
  GridColumn,
  GridRow,
  P,
  H4,
  Icon,
  withDefaultMedia,
} from "notes";
import {
  EventBanner,
  Header,
  Preview,
  ButtonWhite,
  NavBarWrapper,
  UserContext,
  firebase,
  Troubleshoot,
} from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Connection } from "../../../Fan/Components/Connection";
import { Stats } from "./Stats";

export const ManagerPreview = withDefaultMedia(
  ({ matches, setReady, artist, eventId }) => {
    const [view, setView] = useState("video");
    const { user, name } = useContext(UserContext);
    const [troubleshootPosition, setTroubleshootPosition] = useState(0);
    const isMobile = matches.medium || matches.small;

    const scrollToTroubleshoot = () =>
      troubleshootPosition?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

    const signalStrength = 80;

    const [roleRecord, l, r] = useDocumentData(
      firebase.firestore().doc(`meet_greet/${eventId}/roles/${user?.uid}`)
    );

    const role = roleRecord?.role;

    return (
      <>
        {!isMobile && <Header artist={artist} />}
        <ScrollContainer>
          {(!isMobile || view === "video") && (
            <>
              <LeftContainer>
                <EventBanner manager>
                  {isMobile && <StyledHeader />}
                </EventBanner>
                {isMobile && <Stats />}
                <LeftContent>
                  {!isMobile && (
                    <>
                      <H3>Let’s Get You Ready For The Meet &amp; Greet!</H3>
                      <Divider />
                    </>
                  )}
                  <StyledP>
                    Please ensure that you are happy with the selected
                    video/microphone settings as you will not be able to change
                    them later.
                  </StyledP>
                  <H4>{name}</H4>
                  <ListItem style={{ textTransform: "capitalize" }}>
                    <Icon form name="Book" /> {role}
                  </ListItem>
                  <ListItem>
                    <Icon form name="Email" /> {user?.email}
                  </ListItem>
                  {!isMobile && (
                    <Button onClick={() => setReady(true)}>
                      Proceed to Meet &amp; Greet Team View
                    </Button>
                  )}
                </LeftContent>
              </LeftContainer>

              <Wrapper>
                <CenterContainer>
                  {!isMobile && <Connection strength={signalStrength} />}
                  <Preview
                    onTroubleshoot={
                      !isMobile
                        ? () => scrollToTroubleshoot()
                        : () => setView("troubleshoot")
                    }
                  />
                  {isMobile && (
                    <GridColumn style={{ marginTop: "16px" }}>
                      <ButtonWhite onClick={() => setView("troubleshoot")}>
                        <Icon
                          style={{ marginRight: "8px" }}
                          tertiary
                          name="Gear"
                        />
                        TroubleShoot
                      </ButtonWhite>
                      <Button onClick={() => setReady(true)}>
                        Proceed to Meet &amp; Greet Team View
                      </Button>
                    </GridColumn>
                  )}
                </CenterContainer>

                {!isMobile && (
                  <RightContainer>
                    <Stats />
                  </RightContainer>
                )}
              </Wrapper>
            </>
          )}
          {(view === "troubleshoot" || !isMobile) &&
            (isMobile ? (
              <NavBarWrapper
                title="Troubleshoot"
                onBack={() => setView("video")}
                content={
                  <>
                    <Connection
                      strength={user.signalStrength}
                      style={{ height: "48px" }}
                    />
                    <Troubleshoot />
                  </>
                }
              />
            ) : (
              <Troubleshoot
                style={{ width: "100%" }}
                onRef={setTroubleshootPosition}
              />
            ))}
        </ScrollContainer>
      </>
    );
  }
);

const StyledP = styled(P)`
  font-size: 11px;
  font-style: italic;
  line-height: 14px;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
    font-style: normal;
    line-height: 22px;
  }
`;

const LeftContainer = styled(GridColumn)`
  flex: 1 0 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    height: calc(100vh - 56px);
    width: 392px;
    position: fixed;
    top: 56px;
    left: 0;
  }
`;

const LeftContent = styled(GridColumn)`
  padding: 16px 24px;
  ${H4} {
    font-size: 15px;
    font-weight: 700;
    margin-top: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 24px;
    ${H4} {
      font-size: 17px;
      margin-top: 24px;
    }
  }
`;

const ListItem = styled(GridRow)`
  align-items: center;
  font-size: 15px;
  margin-top: 8px;
  svg {
    margin-right: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
  }
`;

const CenterContainer = styled(GridColumn)`
  margin: 0 auto;
  padding: 0 24px 40px 24px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    flex: 1 1 392px;
    min-width: 0;
    max-width: 65vh;
    padding: 0;
    margin: 0 auto;
    height: auto;
  }
`;

const Wrapper = styled(GridRow)`
  width: 100%;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 44px;
    padding-right: 44px;
  }
`;

const ScrollContainer = styled(GridColumn)`
  background-color: #ffffff;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100vh - 56px);
    overflow-y: scroll;
    margin-left: 392px;
  }
`;

const Divider = styled.div`
  background-color: ${(props) =>
    props.light
      ? props.theme.palette.gray.lightest
      : props.theme.palette.black};
  display: block;
  margin: ${(props) => (props.light ? "16px 0" : "16px 0 24px 0")};
  width: 100%;
  height: 2px;
`;

const Button = styled(ButtonPrimary)`
  margin-top: 8px;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-top: 24px;
  }
`;

const RightContainer = styled(GridColumn)`
  flex: 0 1 392px;
  margin-left: 44px;
`;

const StyledHeader = styled(Header)`
  background-color: transparent;
`;
