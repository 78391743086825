import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  H3,
  Icon,
  P,
  withDefaultMedia,
  BrowserInfo,
} from "notes";
import { Lightbox } from "components";

import { android, ios, macos, windows } from "./os";

export const Troubleshoot = withDefaultMedia(({ matches, onRef, ...props }) => {
  const defaultOs = BrowserInfo?.getOS()?.name;
  const defaultBrowser = BrowserInfo?.getBrowser()?.name?.toLowerCase();
  const [os, setOS] = useState(defaultOs);
  const [browser, setBrowser] = useState(defaultBrowser);
  const [menuOpen, setMenuOpen] = useState(false);

  const containerRef = useRef(null);

  const handleClickOutside = (evt) => {
    if (!containerRef.current.contains(evt.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleClick = (view) => {
    setBrowser(view);
    setMenuOpen(false);
  };

  const browserList =
    os === "Windows"
      ? windows
      : os === "Mac OS"
      ? macos
      : os === "iOS"
      ? ios
      : os === "Android"
      ? android
      : windows;

  const selectedBrowser = browserList.find(({ key }) => browser.includes(key));

  if (!selectedBrowser || !browserList) {
    return <P>Your browser is not supported.</P>;
  }

  const wrapperRef = useRef();

  useEffect(() => {
    !!onRef && onRef(wrapperRef);
  }, [wrapperRef]);

  const returnItem = (item, index) => {
    switch (item.type) {
      case "image":
        // return <Lightbox image={item.content} />;
        return <PreviewImage key={index} src={item.content} />;
      default:
        return <Li key={index}>{item.content}</Li>;
    }
  };

  return (
    <Container ref={wrapperRef} {...props}>
      <Header>
        <H3>Camera or Microphone Not Working?</H3>
        <Selector ref={containerRef}>
          <Button onClick={() => setMenuOpen(true)}>
            {selectedBrowser.name}
            <Caret indicator name="Dropdown" />
          </Button>
          <Menu open={menuOpen}>
            {browserList.map(({ key, name, logo }, index) => (
              <Item
                key={`name-${index}`}
                active={key === browser}
                onClick={() => handleClick(key)}
              >
                {logo}
                {name}
              </Item>
            ))}
          </Menu>
        </Selector>
      </Header>
      <Content>
        <Ol>
          {selectedBrowser?.data?.map((item, index) => returnItem(item, index))}
        </Ol>
      </Content>
    </Container>
  );
});

const PreviewImage = styled.img`
  display: block;
  margin: 16px 0;
  max-width: 100%;
`;

const Ol = styled.ol`
  list-style-type: decimal;
  padding-left: 20px;
`;

const Li = styled(P).attrs({ as: "li" })`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  & + & {
    margin-top: 16px;
  }
`;

const Container = styled(GridColumn).attrs({ as: "div" })`
  display: flex;
  box-sizing: border-box;
  padding: 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    border-top: 2px solid ${(props) => props.theme.palette.gray.lighter};
    margin: 40px 44px 0 44px;
    padding: 24px 0;
    flex: 1 0 auto;
  }
`;

const Header = styled(GridRow)`
  flex-direction: column;
  & > ${H3} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    & > ${H3} {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 0;
      width: auto;
    }
  }
`;

const Content = styled(GridColumn)`
  width: 100%;
  margin-top: 16px;
`;

const Caret = styled(Icon)`
  margin-left: 6px;
  path {
    fill: ${(props) => props.theme.palette.purple.primary};
  }
`;

const Selector = styled(H3)`
  position: relative;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-left: 16px;
  }
`;

const Button = styled(H3)`
  color: ${(props) => props.theme.palette.purple.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 17px;
  line-height: 22px;
  &:hover {
    color: ${(props) => props.theme.palette.purple.darker};
    ${Caret} {
      path {
        fill: ${(props) => props.theme.palette.purple.darker};
      }
    }
  }

  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 25px;
    line-height: 32px;
  }
`;

const Menu = styled(GridColumn).attrs({ as: "div" })`
  display: flex;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  width: 184px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
`;

const Item = styled(GridRow)`
  background-color: ${(props) => (props.active ? "#f5f5f5" : "transparent")};
  align-items: center;
  color: ${(props) => props.theme.palette.black};
  cursor: pointer;
  padding: 16px 12px;
  font-weight: 600;
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  }
  &:hover {
    background-color: #f5f5f5;
  }
  svg {
    margin-right: 12px;
  }
`;
