import React, { useState } from "react";
import styled from "styled-components";
import { GridColumn, Icon, P, Footnote } from "notes";
import { ButtonWhite } from "components";

export const PhotoCarousel = ({ photos, ...props }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Container {...props}>
      <Frame xCenter yCenter>
        {photos.length > 0 && (
          <Previous
            disabled={photoIndex === 0}
            onClick={() =>
              photoIndex !== 0
                ? setPhotoIndex(
                    (photoIndex + photos.length - 1) % photos.length
                  )
                : undefined
            }
            xCenter
            yCenter
          >
            <Icon name="Left" />
          </Previous>
        )}

        <>
          {photos.length > 0 && (
            <DownloadButton
              onClick={() => openLink(photos[photoIndex].download)}
            >
              <Icon tertiary name="Download" /> <span>Download</span>
            </DownloadButton>
          )}
          {photos.length > 0 ? (
            <Image src={photos[photoIndex].source} />
          ) : (
            <NoImageContainer>
              <FloatingContainer xCenter yCenter>
                <PictureIcon form name="Picture" />
                <IconLabel>Your photos will be ready soon!</IconLabel>
              </FloatingContainer>
            </NoImageContainer>
          )}
        </>

        {photos.length > 0 && (
          <Next
            disabled={photoIndex === photos.length - 1}
            onClick={() =>
              photoIndex !== photos.length - 1
                ? setPhotoIndex((photoIndex + 1) % photos.length)
                : undefined
            }
            xCenter
            yCenter
          >
            <Icon name="Right" />
          </Next>
        )}

        {photos.length > 1 && (
          <Counter>
            {photoIndex + 1} of {photos.length}
          </Counter>
        )}
      </Frame>
    </Container>
  );
};

const Counter = styled(Footnote)`
  color: ${(props) => props.theme.palette.black};
  font-style: normal;
  position: absolute;
  bottom: 2px;
  @media only screen and ${(props) => props.theme.media.large} {
    bottom: 6px;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const Container = styled(GridColumn)`
  position: relative;
`;

const Previous = styled(GridColumn)`
  background-color: ${(props) =>
    props.disabled ? props.theme.palette.gray.lightest : "#ffffff"};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  position: absolute;
  left: -20px;
  width: 40px;
  height: 40px;
  user-select: none;
  z-index: 5;
  svg {
    path {
      fill: ${(props) =>
        props.disabled
          ? props.theme.palette.gray.lighter
          : props.theme.palette.gray.primary};
    }
  }
  &:hover {
    ${(props) =>
      !props.disabled &&
      `box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32)`};
    svg {
      path {
        fill: ${(props) =>
          props.disabled
            ? props.theme.palette.gray.lighter
            : props.theme.palette.black};
      }
    }
  }
`;

const Next = styled(Previous)`
  left: inherit;
  right: -20px;
`;

const Frame = styled(GridColumn)`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
  padding: 16px;
  position: relative;
  @media only screen and ${(props) => props.theme.media.large} {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.32);
    padding: 24px;
  }
`;

const NoImageContainer = styled(GridColumn)`
  padding-top: 56.04%;
  width: 100%;
`;

const PictureIcon = styled(Icon)`
  margin-bottom: 24px;
  width: 70px;
  height: 70px;
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;

const IconLabel = styled(P)`
  color: ${(props) => props.theme.palette.gray.lighter};
`;

const FloatingContainer = styled(GridColumn)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DownloadButton = styled(ButtonWhite)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  min-width: 40px;
  svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
  span {
    display: none;
  }

  @media only screen and ${(props) => props.theme.media.large} {
    top: 40px;
    right: 40px;
    width: 184px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    span {
      display: block;
    }
  }
`;
