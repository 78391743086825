import { useState } from "react";
import useSound2 from "use-sound";
import connect from "sounds/video-call-connect.mp3";
import disconnect from "sounds/video-call-disconnect.mp3";
import ring from "sounds/video-call-ring.mp3";

export * from "./useMessageSounds";

export const useSounds = () => {
  const [hasPlayedConnected, setHasPlayedConnected] = useState(false);
  const [hasPlayedDisconnected, setHasPlayedDisconnected] = useState(false);

  const soundRinging = ring;
  const soundConnected = connect;
  const soundDisconnect = disconnect;

  const [
    playRinging,
    { stop: stopRinging, isPlaying: isPlayingRinging },
  ] = useSound2(soundRinging, { interrupt: false });

  const [playC] = useSound2(soundConnected);
  const [playD] = useSound2(soundDisconnect);

  const playConnected = () => {
    if (!hasPlayedConnected) {
      playC();
      setHasPlayedConnected(true);
    }
  };

  const playDisconnected = () => {
    if (!hasPlayedDisconnected) {
      playD();
      setHasPlayedDisconnected(true);
    }
  };

  return {
    playDisconnected,
    playRinging,
    stopRinging,
    isPlayingRinging,
    hasPlayedConnected,
    playConnected,
  };
};
