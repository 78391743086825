import { LocalVideoTrack } from "twilio-video";
import { useCallback, useRef } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";

export default function useLocalVideoToggle() {
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find((track) =>
    track.name.includes("camera")
  ) as LocalVideoTrack;
  const publishing = useRef(false);

  const toggleVideoEnabled = useCallback(
    (enable?) => {
      if (!publishing.current) {
        if (enable !== undefined ? !enable : videoTrack) {
          const localTrackPublication = localParticipant?.unpublishTrack(
            videoTrack
          );
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit("trackUnpublished", localTrackPublication);
          removeLocalVideoTrack();
        } else {
          publishing.current = true;
          if (videoTrack) {
            localParticipant?.publishTrack(videoTrack, { priority: "high" });
            publishing.current = false;
            return;
          }
          getLocalVideoTrack()
            .then((track: LocalVideoTrack) =>
              localParticipant?.publishTrack(track, { priority: "high" })
            )
            .catch(onError)
            .finally(() => {
              publishing.current = false;
            });
        }
      }
    },
    [
      videoTrack,
      localParticipant,
      getLocalVideoTrack,
      onError,
      removeLocalVideoTrack,
    ]
  );

  return [!!videoTrack, toggleVideoEnabled] as const;
}
