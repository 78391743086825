import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, Spinner } from "components";
import { GridColumn, GridRow, H2, H3, P } from "notes";
import { TextUpdates } from "./TextUpdates";

export const WaitingTimeMobile = ({ data, onTextAlert, onEdit, ...props }) => {
  const { isDark, time, artistName, userName, isPaused } = data;
  const { event } = useContext(EventContext);

  return (
    <Container {...props}>
      <GridRow>
        {isPaused ? (
          <LoadingLabel>
            <StyledSpinner light />
            The event will {event?.started ? "resume" : "start"} soon...
          </LoadingLabel>
        ) : (
          <>
            <Time xCenter yCenter>
              <H2>{time}</H2> <P>min</P>
            </Time>
            <GridColumn style={{ marginLeft: "16px" }}>
              <LoadingLabel>
                <StyledSpinner light /> Until your turn...
              </LoadingLabel>
              <H3>{userName}</H3>
            </GridColumn>
          </>
        )}
      </GridRow>
      <Divider />
      {isDark ? (
        <ReadyMessage>
          Get ready! It’s almost your turn to join the Meet &amp; Greet with{" "}
          {artistName}...
          <br />
          <br />
          Make sure your microphone and camera are turned on and you’re ready
          for the call. If your camera or microphone are off when we pull you
          into the call, we won’t be able to connect and you will get slotted
          lower in the queue.
        </ReadyMessage>
      ) : (
        <TextUpdates onTextAlert={onTextAlert} onMobileEdit={onEdit} />
      )}
    </Container>
  );
};

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  margin: 0 8px -2px 0;
`;

const Container = styled(GridColumn)`
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  overflow: hidden;
  margin-top: 12px;
  margin-bottom: -38px;
  padding: 12px;
  transition: background-color 2.5s ease;
  ${H3} {
    color: ${(props) => props.theme.palette.black};
    font-size: 17px;
    line-height: 22px;
  }
`;

const LoadingLabel = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 15px;
  font-weight: 600;
  transition: background-color 2.5s ease;
`;

const Time = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.gray.primary};
  border-radius: 4px;
  height: 48px;
  padding: 4px;
  transition: background-color 2.5s ease;
  width: 132px;
  ${H2} {
    color: #ffffff;
    font-size: 25px;
    line-height: 25px;
    margin-right: 6px;
  }
  ${P} {
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
  }
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  display: block;
  margin: 12px 0;
  width: 100%;
  height: 2px;
  transition: background-color fade 2.5s;
`;

const ReadyMessage = styled(P)`
  color: ${(props) => props.theme.palette.black};
  font-size: 15px;
  line-height: 19px;
`;
