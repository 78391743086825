import React, { useContext, useEffect, useRef, useState } from "react";
import {
  EventContext,
  LiveRoom,
  firebase,
  VideoContext,
  usePhotoRequest,
  ChatProvider,
} from "components";
import {
  GridColumn,
  GridRow,
  ButtonPrimary,
  H3,
  Icon,
  withDefaultMedia,
  Subheader,
  P,
} from "notes";
import styled from "styled-components";
import { Time } from "components/CallControls/Components/Time";
import { CallDetails } from "components/CallControls/Components/CallDetails";
import { ChatPanel } from "Routes/Manager/Components";
import { InCallMobile } from "./InCallMobile";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";

export const InCall = withDefaultMedia(
  ({ matches, handleNext, handleEnd, liveRoom, room }) => {
    const { room: userRoom } = useContext(VideoContext);
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const [chat, setChat] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(3);
    const {
      handleRequest,
      pendingPhotoRequests,
      pendingCapture,
    } = usePhotoRequest({ roomId: liveRoom });
    const liveRef = useRef();

    const isMobile = matches.small || matches.medium;

    const tracks = userRoom?.localParticipant?.videoTracks;
    let live = false;
    if (tracks) {
      tracks.forEach((t) => {
        if (t?.track?.isEnabled) {
          live = true;
        }
      });
    }
    liveRef.current = live;

    const handleUnmute = () => {
      firebase
        .firestore()
        .doc(`meet_greet/${event.id}/rooms/${liveRoom}`)
        .update({
          startTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      toggleVideoEnabled(true);
      toggleAudioEnabled(true);
    };

    useEffect(() => {
      if (!live) {
        setSecondsLeft(5);
        const co = setInterval(() => {
          setSecondsLeft((s) => {
            if (s === null) {
              clearInterval(co);
              return null;
            }
            if (s < 1) {
              clearInterval(co);
              return 0;
            }
            return s - 1;
          });
        }, 1000);
        return () => {
          clearInterval(co);
        };
      }
    }, [live]);

    useEffect(() => {
      if (!live && secondsLeft === 0) {
        setSecondsLeft(null);
        handleUnmute();
        handleSlowConnect();
      }
    }, [secondsLeft, handleUnmute]);

    const handleSlowConnect = () => {
      console.log("maxlog - InCall slow1");
      setTimeout(() => {
        console.log("maxlog - InCall slow2", liveRef.current);
        if (!liveRef.current) {
          console.log("maxlog - InCall slow3");
          handleUnmute();
          handleSlowConnect();
        }
      }, 5000);
    };

    const { event } = useContext(EventContext);

    const callProps = {
      handleNext,
      handleEnd,
      handleUnmute,
      liveRoom,
      live,
      room,
    };
    if (isMobile) {
      return <InCallMobile {...callProps} />;
    }

    const location = room?.data()?.userLocation;
    const bio = room?.data()?.userBio;

    return (
      <Container>
        <Header yCenter>
          <StyledDetails roomId={room?.id} artist />
          <GridRow stretch xEnd>
            <StyledTime room={room} handleNext={handleNext} artist />
          </GridRow>
        </Header>
        <Content>
          <ChatContainer active={chat}>
            <ChatProvider userId={`eventmods`}>
              <StyledChatPanel team />
            </ChatProvider>
          </ChatContainer>
          <FloatingControls active={chat}>
            <ChatButton onClick={() => setChat(!chat)}>
              {chat ? (
                <Icon tertiary name="Previous" />
              ) : (
                <Icon form name="InstantMessage" />
              )}
            </ChatButton>

            <RequestButton
              onClick={handleRequest}
              disabled={!live || pendingCapture || !pendingPhotoRequests?.empty}
            >
              <Icon tertiary name="Camera" />
            </RequestButton>
          </FloatingControls>
          <StyledLiveRoom live={live} roomId={liveRoom} artist />
          <ControlsContainer active={chat}>
            <LeftSide>
              <Subheader>About Fan</Subheader>
              <P>
                {location}
                {!!location && !!bio && " • "}
                {bio}
              </P>
            </LeftSide>
            <RightSide yCenter>
              <Actions>
                <NextCall
                  onClick={live ? handleNext : handleUnmute}
                  live={live}
                >
                  <Icon form name="NextCall" />
                  {live
                    ? "Next Call"
                    : secondsLeft === null
                    ? `Joining`
                    : `Joining in ${secondsLeft}`}
                </NextCall>
                <EndCall onClick={handleEnd}>
                  <Icon form name="EndCall" />
                  End Call
                </EndCall>
              </Actions>
            </RightSide>
          </ControlsContainer>
        </Content>
      </Container>
    );
  }
);

const RequestButton = styled(ButtonPrimary)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  svg {
    path {
      fill: #ffffff;
    }
  }
  ${(props) =>
    props.theme.largeControls &&
    `min-width: 150px;
    height: 150px;
    border-radius: 50%;
    svg {
      width: 40px;
      height: 40px;
    }
  `};
  &:disabled {
    svg {
      path {
        fill: ${(props) => props.theme.palette.gray.lighter};
      }
    }
  }
`;

const StyledChatPanel = styled(ChatPanel)`
  width: 328px;
`;

const ChatButton = styled(GridRow)`
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  min-width: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 23px 0 #000000;
  user-select: none;
  svg {
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
`;

const ChatContainer = styled(GridColumn)`
  align-items: stretch;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 100%;
  width: ${(props) => (props.active ? "328px" : 0)};
  transition: width 0.2s ease;
  overflow: hidden;
`;

const FloatingControls = styled(GridColumn)`
  position: fixed;
  left: ${(props) => (props.active ? "352px" : "24px")};
  top: 100px;
  z-index: 500;
  transition: left 0.2s ease;
`;

const StyledLiveRoom = styled(LiveRoom)`
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: ${(props) => (props.live ? "row" : "row-reverse")};
  }
`;

const StyledDetails = styled(CallDetails)``;

const StyledTime = styled(Time)`
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const LeftSide = styled(GridColumn)`
  width: 50%;
  ${P} {
    color: #ffffff;
  }
`;

const RightSide = styled(GridRow)`
  width: 50%;
`;

const Actions = styled(GridRow)`
  width: 100%;
  margin: 0 auto;
  max-width: 392px;
  ${(props) =>
    props.theme.largeControls &&
    `max-width: 100%; justify-content: space-around;`};
`;

const ControlsContainer = styled(GridRow)`
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 8px;
  padding: 16px 14px 16px;
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: ${(props) => (props.active ? "344px" : "16px")};
  min-height: 102px;
  ${(props) => props.theme.largeControls && `width: 50%;`};
  transition: left 0.2s ease;
`;

const Content = styled(GridRow)`
  flex-grow: 1;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
  }
`;

const Container = styled(GridColumn)`
  background: #000000;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 100%;
    height: 100vh;
  }
`;

const Header = styled(GridRow)`
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  height: 42px;
  flex-shrink: 0;
  padding: 0 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 501;
  & > ${H3} {
    color: #ffffff;
    font-size: 15px;
    line-height: 18px;
    margin-left: 12px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    background: ${(props) => props.theme.palette.black};
    height: initial;
    padding: 11px 28px 11px 40px;
    position: relative;
    & > ${H3} {
      font-size: 25px;
      line-height: 32px;
      margin-left: 24px;
    }
  }
`;

const largeControlStyling = `
  min-width: 150px;
  height: 150px;
  border-radius: 150px;
  font-size: 24px;
  flex-direction: column;
  position: fixed;
  top: 100px;
  right: 20px;
  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 0;
  }
`;

const NextCall = styled(ButtonPrimary)`
  background-color: ${(props) =>
    props.live ? "#ffffff" : props.theme.palette.green.primary};
  color: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
  svg {
    margin-right: 8px;
    path {
      fill: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => (props.live ? "#ffffff" : "#48a545")};
    color: ${(props) => (props.live ? props.theme.palette.black : "#ffffff")};
    svg {
      path {
        fill: ${(props) =>
          props.live ? props.theme.palette.black : "#ffffff"};
      }
    }
  }
  ${(props) => props.theme.largeControls && largeControlStyling};
`;

const EndCall = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.red.primary};
  color: #ffffff;
  margin-left: 24px;
  svg {
    margin-right: 8px;
    path {
      fill: #ffffff;
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.red.darker};
    color: #ffffff;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  ${(props) => props.theme.largeControls && largeControlStyling};
  ${(props) => props.theme.largeControls && `top: initial; bottom: 130px;`};
`;
