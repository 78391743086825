import React from "react";
import styled from "styled-components";
import { ButtonPrimary, GridColumn, GridRow, Icon, Link, H4, H2 } from "notes";
import { usePhotoRequest } from "components";

export const PhotoRequestDialogs = ({
  roomId,
  fan = false,
  manager = false,
}) => {
  const {
    handleApprove,
    handleCancel,
    pendingPhotoRequests,
    pendingCapture,
    pendingApproval,
    recentPhoto,
    localCapture,
    countdown,
  } = usePhotoRequest({ roomId, fan, manager });

  return (
    <Container manager={manager} expand={pendingCapture}>
      {!pendingPhotoRequests?.empty && !pendingCapture && !pendingApproval && (
        <OverlayPill>
          Photo request sent...{" "}
          <WhiteLink onClick={handleCancel}>Cancel</WhiteLink>
        </OverlayPill>
      )}
      {pendingApproval && (
        <OverlayDialog>
          <H4>You've received a photo request...</H4>
          <Actions>
            <ButtonPrimary onClick={handleApprove}>Accept</ButtonPrimary>
            <DeclineLink onClick={handleCancel}>Decline</DeclineLink>
          </Actions>
        </OverlayDialog>
      )}
      {pendingCapture && (
        <CountdownDialog>
          <H2>{countdown.seconds}</H2>
        </CountdownDialog>
      )}
      {recentPhoto && <DisplayRecentPhoto photo={localCapture} />}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  align-items: ${(props) => (props.expand ? "center" : "flex-end")};
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 0;
    left: initial;
    right: 0;
    bottom: ${(props) =>
      props.theme.isArtist || (props.expand && !props.manager)
        ? "40vh"
        : "40px"};
    top: initial;
    width: ${(props) =>
      props.theme.isArtist || props.manager || props.expand ? 100 : 50}%;
    height: initial;
  }
`;

const DisplayRecentPhoto = ({ photo }) => {
  const keys = Object.keys(photo);
  return !!keys.length ? (
    <RecentPhoto>
      {keys.map((k, i) => (
        <img key={i} width="142" alt="" src={photo[k]} />
      ))}
    </RecentPhoto>
  ) : (
    ""
  );
};

const RecentPhoto = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  padding: 4px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  z-index: 30;
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    align-items: flex-start;
    top: 100px;
    right: 100px;
    transform: none;
  }
`;

const WhiteLink = styled(Link)`
  color: #ffffff;
  &:hover,
  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;

const Actions = styled(GridRow)`
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

const CountdownDialog = styled(GridColumn)`
  align-items: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 24px;
  color: #ffffff;
  justify-content: center;
  width: 100%;
  max-width: 344px;
  flex: 0 1 244px;
  margin: 0 auto;
  padding: 24px;
  ${H2} {
    color: #ffffff;
    font-size: 120px;
    font-weight: 700;
    line-height: 132px;
  }
  ${WhiteLink} {
    margin-top: 16px;
  }
`;

const OverlayDialog = styled(GridColumn)`
  align-items: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 8px;
  color: #ffffff;
  justify-content: space-between;
  width: 100%;
  max-width: 392px;
  margin: 0 auto;
  padding: 16px 24px 24px 24px;
  ${H4} {
    color: #ffffff;
  }
`;

const OverlayPill = styled(GridRow)`
  align-items: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 32px;
  color: #ffffff;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  max-width: 392px;
  margin: 0 auto;
  padding: 0 24px;
`;

const DeclineLink = styled(WhiteLink)`
  width: 100%;
  justify-content: center;
  margin-left: 20px;
`;
