import React, { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "components";
import { GridRow } from "notes";

export const LocalAvatar = ({ isArtist = false, ...props }) => {
  const { name } = useContext(UserContext);
  const initials = name
    ?.split(" ")
    .map((word) => word.charAt(0))
    .join("");
  return (
    <LocalAvatarContainer isArtist={isArtist} {...props}>
      {initials}
    </LocalAvatarContainer>
  );
};

export const LocalAvatarContainer = styled(GridRow)`
  align-items: center;
  justify-content: center;
  ${(props) =>
    !props.image &&
    `background: ${
      props.theme.palette[props.isArtist ? "teal" : "orange"].primary
    }`};
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${(props) =>
    props.image &&
    `background-image: url(${props.image});
  background-size: cover;
  background-position: center center;`};
`;
