import React from "react";
import styled from "styled-components";
import { P, Icon, GridColumn, Small } from "notes";
import { ProgressRing } from "./ProgressRing";

export const StepCards = ({ steps, ...props }) => {
  return (
    <Container {...props}>
      {steps.map(({ status, disabled, label, content, progress }, index) => {
        const active = status === "active";
        const complete = status === "complete";
        return (
          <>
            <Step
              key={index}
              active={active}
              complete={complete}
              disabled={disabled}
            >
              <AnimationContainer>
                {complete ? (
                  <Green badge name="FacebookVerified" />
                ) : active ? (
                  !!progress ? (
                    <ProgressRing progress={progress} />
                  ) : (
                    <Green indicator name="ProgressActive" />
                  )
                ) : (
                  <Disabled indicator name="ProgressInactive" />
                )}
                {active && <Pulse />}
              </AnimationContainer>

              <P>{label}</P>
              <Small>{content}</Small>
            </Step>
            {index !== steps.length - 1 && <HLine active={complete} />}
          </>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

const HLine = styled.div`
  align-self: center;
  background: ${(props) =>
    props.active
      ? props.theme.palette.purple.primary
      : props.theme.palette.gray.lightest};
  border-radius: 2px;
  display: block;
  margin: 0 16px;
  flex: 0 3 120px;
  height: 4px;
`;

const Step = styled(GridColumn)`
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  box-shadow: ${(props) =>
    props.active
      ? "0 0 24px 0 rgba(0, 0, 0, 0.15)"
      : "0 0 24px 0 rgba(0, 0, 0, 0.08)"};
  flex: 1 1 190px;
  padding: 40px 24px;
  & > ${P} {
    color: ${(props) =>
      props.disabled
        ? props.theme.palette.gray.lighter
        : props.theme.palette.black};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 24px;
    white-space: nowrap;
  }
  & > ${Small} {
    color: ${(props) =>
      props.disabled
        ? props.theme.palette.gray.lighter
        : props.theme.palette.black};
    margin-top: 12px;
    text-align: center;
  }
`;

const Green = styled(Icon)`
  width: 32px;
  height: 32px;
  path {
    fill: ${(props) => props.theme.palette.purple.primary};
  }
`;

const Disabled = styled(Green)`
  path {
    fill: ${(props) => props.theme.colors.disabledIcon};
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 40px;
  width: 40px;
  animation: pulse-purple 4s infinite;
  z-index: 1;

  @keyframes pulse-purple {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 1);
    }
    20% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(124, 96, 217, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(124, 96, 217, 0);
    }
  }
`;
