import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const ProgressRing = ({ progress = 0 }) => {
  const stroke = 2;
  const radius = 20;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Wrapper>
      <Container>
        <Percentage>
          {Math.floor(progress)}
          <Sign>%</Sign>
        </Percentage>
        <svg height={radius * 2} width={radius * 2}>
          <SolidCircle
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <Circle
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            circumference={circumference}
            offset={strokeDashoffset}
          />
        </svg>
      </Container>
    </Wrapper>
  );
};

const Percentage = styled(GridRow)`
  color: ${(props) => props.theme.palette.purple.primary};
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 2;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.workSans};
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
`;

const Sign = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: 8px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: block;
  width: 32px;
  height: 32px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: -4px;
  position: relative;
`;

const Circle = styled.circle`
  stroke-dasharray: ${(props) =>
    `${props.circumference} ${props.circumference}`};
  stroke-dashoffset: ${(props) => props.offset};
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: ${(props) => props.theme.palette.purple.primary};
`;

const SolidCircle = styled.circle`
  stroke: ${(props) => props.theme.palette.purple.lightest};
  transform-origin: center;
`;
