import React from "react";
import styled from "styled-components";
import { GridColumn, Icon } from "notes";
import { ButtonWhite } from "components";
import iOS from "is-ios";

export const MediaDownload = ({
  label,
  source,
  download,
  portrait,
  video,
  thumbnail,
  ...props
}) => {
  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Container {...props}>
      <Frame>
        <DownloadButton onClick={() => openLink(download)}>
          <Icon tertiary name="Download" />
        </DownloadButton>
        {video ? (
          <Video
            portrait={portrait}
            src={source}
            preload="metadata"
            type="video/mp4"
            controls
            poster={iOS ? thumbnail : undefined}
          />
        ) : (
          <Image portrait={portrait} src={source} />
        )}
      </Frame>
    </Container>
  );
};

const Container = styled(GridColumn)``;

const DownloadButton = styled(ButtonWhite)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding: 0;
  z-index: 2;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
`;

const Frame = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lightest};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  &:hover {
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: ${(props) => (props.portrait ? "150px" : "280px")};
`;

const Video = styled.video`
  display: block;
  width: 100%;
  max-width: ${(props) => (props.portrait ? "150px" : "280px")};
`;
