import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { GridColumn, Icon, GridRow } from "notes";

export const Lightbox = ({ image }) => {
  const [open, setOpen] = useState(false);
  const portal = useRef();

  const [id] = useState(
    Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  );

  useEffect(() => {
    const target = document.createElement("div");
    target.id = `portal${id}`;
    document.body.appendChild(target);
    portal.current = document.getElementById(`portal${id}`);
    return () => {
      document.getElementById(`portal${id}`).remove();
    };
  }, []);

  return (
    <>
      {open && portal.current
        ? ReactDOM.createPortal(
            <Container>
              <CloseButton
                platform
                name="Close"
                onClick={() => setOpen(false)}
              />
              <Image src={image} />
            </Container>,
            portal.current
          )
        : ""}
      <PreviewContainer onClick={() => setOpen(true)}>
        <PreviewImage src={image} />
        <ExpandIconContainer>
          <ExpandIcon name="Expand" />
        </ExpandIconContainer>
      </PreviewContainer>
    </>
  );
};

const Image = styled.img`
  display: block;
  max-width: 560px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
`;

const Container = styled.div`
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
`;

const CloseButton = styled(Icon)`
  cursor: pointer;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 601;
  path {
    fill: #ffffff;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    right: 70px;
  }
`;

const ExpandIconContainer = styled(GridColumn)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 2px;
  padding: 2px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  z-index: 2;
  transition: box-shadow 0.2s ease;
`;

const ExpandIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  path {
    fill: #ffffff;
  }
`;

const PreviewContainer = styled(GridRow)`
  cursor: pointer;
  width: 100%;
  margin: 16px 0;
  max-width: 200px;
  position: relative;
  &:hover {
    ${ExpandIconContainer} {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
    }
  }
`;

const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
