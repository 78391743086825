import React, { useContext, useEffect } from "react";
import { firebase, VideoContext, EventContext, UserContext } from "components";

export const NetworkQualityUploader = () => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);
  const { room } = useContext(VideoContext);
  const localParticipant = room?.localParticipant;

  const updateNetworkLevel = (level) => {
    firebase
      .firestore()
      .doc(`meet_greet/${event.id}/rooms/${user?.uid}`)
      .update({ connectionQuality: level * 20 }); //stored in 100, 1-5 reported from twilio
  };

  useEffect(() => {
    if (localParticipant) {
      localParticipant.on("networkQualityLevelChanged", updateNetworkLevel);
      return () => {
        localParticipant.off("networkQualityLevelChanged", updateNetworkLevel);
      };
    }
  }, [localParticipant]);

  return null;
};
