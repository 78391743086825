import React from "react";
import styled from "styled-components";
import {
  Modal,
  H3,
  P,
  Label,
  GridColumn,
  Subheader,
  withDefaultMedia,
} from "notes";
import { NavBarWrapper } from "components";

export const ManagerModal = withDefaultMedia(
  ({
    matches,
    children,
    title,
    submitLabel,
    description,
    open,
    mobileFull,
    onBack,
    maxMobile,
    ...props
  }) => {
    const altStyle = !matches.large && mobileFull;
    return (
      <StyledModal
        open={open}
        size={altStyle ? "full" : "medium"}
        full={altStyle}
        maxMobile={maxMobile}
        {...props}
      >
        {!matches.large && mobileFull ? (
          <NavBarWrapper
            title={title}
            content={<Content>{children}</Content>}
            onBack={onBack}
          />
        ) : (
          <>
            {!!title && <H3>{title}</H3>}
            {!!description && matches.large && <P>{description}</P>}
            {children}
          </>
        )}
      </StyledModal>
    );
  }
);

const StyledModal = styled(Modal)`
  min-height: ${(props) => (props.full ? "100%" : 0)};
  & > svg {
    display: none;
  }
  & > ${H3} {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 8px;
    text-align: left;
  }
  & > ${Label}, & > * > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  & > ${Subheader} {
    margin-bottom: 8px;
    text-align: left;
  }
  padding: ${(props) => (props.full ? "0 24px" : "24px")};
  @media only screen and ${(props) => props.theme.media.large} {
    & > ${H3} {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 16px;
      text-align: left;
    }
    & > ${Subheader} {
      margin-bottom: 24px;
      text-align: center;
    }
    padding: 32px 40px 40px 40px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) => props.maxMobile && "max-width: 296px;"};
  }
`;

const Content = styled(GridColumn)`
  & > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;
