import React from "react";
import { firebase } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { LocalAvatarContainer } from "components";

export const ParticipantAvatar = ({ userId, image, ...props }) => {
  const [displayname] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${userId}`)
  );
  const initials = displayname?.value
    ?.split(" ")
    .map((word) => word.charAt(0))
    .join("");
  return (
    <LocalAvatarContainer image={image} {...props}>
      {initials}
    </LocalAvatarContainer>
  );
};
