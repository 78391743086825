import React, { useContext, useState } from "react";
import { ChatContext, EventContext, UserContext, firebase } from "components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { P, Input, Icon, Subheader } from "notes";
import setLiveChatLogo from "Images/ManagerChatLogo.png";

export const ChatBox = ({ ...props }) => {
  const { chats, unreadFanChats } = useContext(ChatContext);
  if (!!unreadFanChats?.docs?.length) {
    unreadFanChats.forEach((message) => {
      message.ref.update({ isFanRead: true });
    });
  }
  return (
    <BoxContainer {...props}>
      <ChatsContainer>
        {chats?.map((chat) => (
          <ChatMessage chat={chat} />
        ))}
      </ChatsContainer>
      <SendMessage />
    </BoxContainer>
  );
};

const ChatsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 40px;
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
`;

const BoxContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const ChatMessage = ({ chat }) => {
  const { message, sender } = chat.data();
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);

  const [displayname, l, e] = useDocumentData(
    firebase.firestore().doc(`meet_greet_names/${sender}`)
  );
  const [roleRecord] = useDocumentData(
    firebase.firestore().doc(`meet_greet/${event.id}/roles/${sender}`)
  );
  const role = roleRecord?.role;
  const name =
    sender === user.id ? (
      "You"
    ) : role !== "manager" ? (
      displayname?.value
    ) : (
      <>
        <ManagerLogo src={setLiveChatLogo} />
        Set.Live Team
      </>
    );
  return (
    <ChatContainer>
      <NameContainer>{name}</NameContainer>
      <Message>{message}</Message>
    </ChatContainer>
  );
};

const ManagerLogo = styled.img`
  flex-shrink: 0;
  margin-right: 6px;
  margin-bottom: -3px;
  width: 16px;
  height: 16px;
`;

const NameContainer = styled(Subheader)`
  margin-bottom: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const ChatContainer = styled.div`
  padding: 0 8px 12px 8px;
`;

const Message = styled(P)`
  color: ${(props) =>
    props.team || props.theme.dark ? "#ffffff" : props.theme.palette.black};
  font-size: 15px;
  line-height: 19px;
`;

const SendMessage = () => {
  const { sendMessage } = useContext(ChatContext);
  const [value, setValue] = useState("");

  const handleSend = () => {
    sendMessage(value);
    setValue("");
  };

  return (
    <SendContainer>
      <StyledInput
        value={value}
        onChange={setValue}
        onKeyDown={(e) => {
          e.which === 13 && handleSend();
        }}
        placeholder="Comment..."
        rightIcon={
          <StyledIcon
            onClick={handleSend}
            selector
            name="Send"
            style={{ cursor: "pointer" }}
          />
        }
        style={{ borderRadius: 0 }}
      />
    </SendContainer>
  );
};

const StyledInput = styled(Input)`
  border-radius: 0;
  border: none;
  border-top: 1px solid #e6e9eb;
`;

const StyledIcon = styled(Icon)`
  width: 33px;
  height: 33px;
  margin-top: -9px;
`;

const SendContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
